"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(fieldName, schema) {
    var validationSchema = Object(schema).hasOwnProperty('_resolve')
        ? schema._resolve()
        : schema;
    var describedValidationSchema = validationSchema.describe();
    if (describedValidationSchema.fields[fieldName] === undefined) {
        return false;
    }
    var filterSchemaTests = describedValidationSchema.fields[fieldName].tests.find(function (element) {
        return 'required'.localeCompare(element.name) === 0 ? true : false;
    });
    return filterSchemaTests !== undefined
        ? filterSchemaTests.name === 'required'
            ? true
            : false
        : false;
}
exports.default = default_1;
