"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var LoadingContainer_1 = require("../../../../../../../common/LoadingContainer");
var InformationTip_1 = require("../../../../../../../common/InformationTip");
var DebtDeclineModalForm_1 = require("./DebtDeclineModalForm");
var DebtDeclineModalContent = function (props) {
    return (React.createElement("section", null,
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "modal-header flex-row" },
                React.createElement("h3", null, "Decline Debt"),
                React.createElement("div", { className: "flex-row" },
                    React.createElement("div", { className: "isPointer", onClick: function () {
                            props.handleDebtDeclineModal();
                        } },
                        React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'times'] }))))),
        React.createElement("div", { className: "container" },
            React.createElement("p", null, "If you believe that the customer is unable to proceed with this debt or it conflicts with interests internal to rradar you can decline this debt"),
            React.createElement(InformationTip_1.default, null,
                React.createElement("div", null, "If this debt is declined it will not be able to be reopened. If there are only errors with this debt, revert it instead.")),
            React.createElement(LoadingContainer_1.default, { render: function (config) {
                    return React.createElement(DebtDeclineModalForm_1.default, __assign({}, config, props));
                } }))));
};
exports.default = DebtDeclineModalContent;
