"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var TextField_1 = require("../../../../../common/TextField");
var DatePicker_1 = require("../../DatePicker/DatePicker");
var FileInput_1 = require("../../../../../common/FileInput");
var AddDebtWizardPage8Invoice = function (props) {
    return (React.createElement("div", { className: "form-panel flex-column" },
        React.createElement(TextField_1.default, { type: "text", name: "invoice_reference", validator: props.validator, label: 'Invoice refererence', value: props.values.invoice_reference, placeholder: 'Invoice Ref', disabled: false }),
        React.createElement(formik_1.ErrorMessage, { name: "invoice_reference" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
        React.createElement(DatePicker_1.default, { name: "invoice_sent_date", validator: props.validator, setFieldValue: props.setFieldValue, value: props.values.invoice_sent_date, label: 'Invoice sent' }),
        React.createElement(formik_1.ErrorMessage, { name: "invoice_sent_date" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
        React.createElement(DatePicker_1.default, { name: "invoice_due_date", validator: props.validator, setFieldValue: props.setFieldValue, value: props.values.invoice_due_date, label: 'Invoice due date' }),
        React.createElement(formik_1.ErrorMessage, { name: "invoice_due_date" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
        React.createElement(DatePicker_1.default, { name: "reminder_sent_date", validator: props.validator, setFieldValue: props.setFieldValue, value: props.values.reminder_sent_date, label: 'Reminder sent date' }),
        React.createElement(FileInput_1.default, { setFileUpload: function (file) { return props.setFileUpload(props.setFieldValue, file); }, value: props.values.supporting_documents !== null ? props.values.supporting_documents.name : null })));
};
exports.default = AddDebtWizardPage8Invoice;
