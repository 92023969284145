"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classnames_1 = require("classnames");
var LoadingContainer = /** @class */ (function (_super) {
    __extends(LoadingContainer, _super);
    function LoadingContainer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            loading: false,
        };
        _this.setLoading = function (state) {
            _this.setState({
                loading: state,
            });
        };
        _this.render = function () {
            var _a;
            return (React.createElement("div", { className: (0, classnames_1.default)((_a = {
                        'is-loading': _this.state.loading
                    },
                    _a["".concat(_this.props.theme, "-loading")] = _this.props.theme,
                    _a)) }, _this.props.render({
                loading: _this.state.loading,
                setLoading: _this.setLoading,
            })));
        };
        return _this;
    }
    return LoadingContainer;
}(React.Component));
exports.default = LoadingContainer;
