"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var GenericCheckbox = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { "aria-checked": props.checked, className: "checkbox", id: props.id, type: "checkbox", checked: props.checked, onChange: props.onChange, disabled: props.disabled }),
        React.createElement("label", { htmlFor: props.id, className: "label" }),
        props.descriptor && React.createElement("span", null, props.descriptor)));
};
exports.default = GenericCheckbox;
