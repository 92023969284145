"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.history = void 0;
var React = require("react");
var history_1 = require("history");
var index_1 = require("./home/index");
var index_2 = require("./debtWizard/index");
var react_router_1 = require("react-router");
var index_3 = require("./viewDebt/index");
var MyCompany_1 = require("./myCompany/MyCompany");
var index_4 = require("./guidance/index");
var index_5 = require("./legalActionOutlook/index");
var index_6 = require("./debtors/index");
var index_7 = require("./viewDebtor/index");
var DebtorNewDebt_1 = require("./debtorNewDebt/DebtorNewDebt");
exports.history = (0, history_1.createBrowserHistory)();
var Dashboard = function (props) {
    return (React.createElement(react_router_1.Routes, null,
        React.createElement(react_router_1.Route, { path: "/debtwizard/*", element: React.createElement(index_2.default, { setTCModal: props.setTCModal }) }),
        React.createElement(react_router_1.Route, { path: "/debts/:reference/action-outlook", element: React.createElement(index_5.default, null) }),
        React.createElement(react_router_1.Route
        // exact={true}
        , { 
            // exact={true}
            path: "/debts/:reference/*", element: React.createElement(index_3.default, null) }),
        React.createElement(react_router_1.Route, { path: "/debtors/:id/new-debt", element: React.createElement(DebtorNewDebt_1.default, null) }),
        React.createElement(react_router_1.Route, { path: "/debtors/:id", element: React.createElement(index_7.default, null) }),
        React.createElement(react_router_1.Route, { path: "/debtors", element: React.createElement(index_6.default, null) }),
        React.createElement(react_router_1.Route, { path: "/my_company", element: React.createElement(MyCompany_1.default, null) }),
        React.createElement(react_router_1.Route, { path: "/guidance", element: React.createElement(index_4.default, null) }),
        React.createElement(react_router_1.Route, { path: "/", element: React.createElement(index_1.default, null) })));
};
exports.default = Dashboard;
