"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_1 = require("react-router");
var react_error_boundary_1 = require("react-error-boundary");
var ViewDebt_1 = require("./ViewDebt/ViewDebt");
var EditDebt_1 = require("./EditDebt");
var recoverInterface_1 = require("../../../utils/recoverInterface");
var debtTemplate_1 = require("../../../utils/debtTemplate");
var core_1 = require("@rradar/core");
var getState_1 = require("../../../utils/getState");
var ViewDebtRouter = function (props) {
    var _a = __read(React.useState(__assign({}, (0, debtTemplate_1.default)())), 2), debt = _a[0], setDebt = _a[1];
    var _b = __read(React.useState([{ filename: '' }]), 2), docList = _b[0], setDocList = _b[1];
    var _c = __read(React.useState(false), 2), noDebt = _c[0], setNoDebt = _c[1];
    React.useEffect(function () {
        getDebt();
    }, [props.reference]);
    React.useEffect(function () {
        getSupportingDocs();
    }, [debt.id]);
    var changeDates = function (changedDebt) {
        var newDebt = debt;
        newDebt.invoice_due_date = new Date(changedDebt.invoice_due_date).toLocaleDateString('en-GB', { timeZone: 'UTC' });
        newDebt.invoice_sent_date = new Date(changedDebt.invoice_sent_date).toLocaleDateString('en-GB', {
            timeZone: 'UTC',
        });
        newDebt.reminder_sent_date = new Date(changedDebt.reminder_sent_date).toLocaleDateString('en-GB', {
            timeZone: 'UTC',
        });
        if (changedDebt.purchase_order_date !== null && changedDebt.purchase_order_date !== '') {
            newDebt.purchase_order_date = new Date(changedDebt.purchase_order_date).toLocaleDateString('en-GB', {
                timeZone: 'UTC',
            });
        }
        return changedDebt;
    };
    var getDebt = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, recoverInterface_1.default)()
                        .debts()
                        .getByRef(props.reference)
                        .then(function (res) {
                        res.debt = changeDates(res.debt);
                        setDebt(res.debt);
                    })
                        .catch(function () { return setNoDebt(true); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var getSupportingDocs = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, recoverInterface_1.default)()
                        .debts()
                        .access(debt.id)
                        .supporting_documents()
                        .index()
                        .then(function (res) {
                        // @ts-ignore
                        if (res.supporting_documents.length !== 0) {
                            // @ts-ignore
                            setDocList(res.supporting_documents);
                        }
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var reloadData = function () {
        getDebt();
        getSupportingDocs();
    };
    var seperateDebt = function () {
        var newdebt = __assign({}, debt);
        var newDebtor = newdebt.debtor;
        delete newdebt.debtor;
        if (newDebtor !== null && newDebtor !== undefined) {
            delete newDebtor.jurisdiction;
            delete newDebtor.id;
        }
        return __assign(__assign({}, newdebt), newDebtor);
    };
    return (React.createElement(react_error_boundary_1.ErrorBoundary, { fallbackRender: function (fallbackProps) {
            return (React.createElement(core_1.ErrorPage, { error_status: '404', error_message: "Ooops, Mandela effect?  The debt you are looking for doesn't seem to exist.", href: (0, getState_1.default)().user.role === 'customer' ? '/dashboard/debts' : '/admin/debts' }));
        } },
        React.createElement(react_router_1.Routes, null,
            React.createElement(react_router_1.Route, { path: "/edit", element: React.createElement(EditDebt_1.default, { debt: seperateDebt(), file: docList[0] }) }),
            React.createElement(react_router_1.Route, { path: '/', element: React.createElement(ViewDebt_1.default, { debt: debt, debtNotFound: noDebt, reloadData: reloadData }) }))));
};
exports.default = ViewDebtRouter;
