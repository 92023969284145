"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var TextField_1 = require("../../../../../common/TextField");
var OriginalAmountFields = function (props) {
    var _a = __read(React.useState(''), 2), previousPounds = _a[0], setPreviousPounds = _a[1];
    var convertPounds = function (num) {
        var newPounds = num.replace('.', '');
        if (newPounds === '') {
            return previousPounds;
        }
        setPreviousPounds(newPounds);
        return newPounds;
    };
    var convertPence = function (num, places) {
        var number = String(num);
        var newPence = Number.parseInt(number, 10);
        if (number.length === 3) {
            return number.substring(1);
        }
        if (number.length < 2) {
            return number.padStart(places, '0');
        }
        if (newPence >= 100) {
            return '00';
        }
        return number;
    };
    return (React.createElement("div", { className: "original-amount-input flex-row" },
        React.createElement("span", null, "\u00A3"),
        React.createElement(TextField_1.default, { type: "number", name: "original_amount", validator: props.validator, value: props.pounds, min: 25, max: 20000, onChange: function (value) {
                props.setPounds(convertPounds(value));
            }, className: 'original-amount-input-pounds', disabled: false }),
        React.createElement("span", null, "."),
        React.createElement(TextField_1.default, { type: "number", max: 100, name: "original_amount", validator: props.validator, value: convertPence(props.pence, 2), onChange: function (value) {
                var newPence = Number.parseInt(value, 10);
                if (newPence >= 100) {
                    props.setPence(convertPence(newPence, 2));
                }
                else if (newPence <= -1) {
                    props.setPence(convertPence(99, 2));
                }
                else
                    props.setPence(convertPence(newPence, 2));
            }, className: 'original-amount-input-pence', disabled: false })));
};
exports.default = OriginalAmountFields;
