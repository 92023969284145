"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var EditDebtJuridiction_1 = require("./EditDebtJuridiction");
var EditDebtHeader = function (props) {
    return (React.createElement("div", { className: "flex-row-wrap edit-debt-header" },
        React.createElement("div", { className: "edit-debt-header-contents flex-row" },
            React.createElement("div", { className: "flex-column" },
                React.createElement("h1", { className: "flex-row" },
                    props.reference === '' ? 'New' : '',
                    " Debt ",
                    props.reference !== '' ? props.reference : ''),
                React.createElement("h3", null, props.protocol))),
        React.createElement(EditDebtJuridiction_1.default, { values: props.values, setFieldValue: props.setFieldValue, disabled: props.disabled })));
};
exports.default = EditDebtHeader;
