"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var JurisdictionButton = function (props) {
    return (React.createElement("div", { onClick: function (e) { return props.setFieldValue('jurisdiction', props.jurisdiction); }, className: "form-panel-coloured jurisdiction-button ".concat(props.value === props.jurisdiction ? 'jurisdiction-button-active' : 'jurisdiction-button-inactive') },
        React.createElement("div", { className: "jurisdiction-button-image" },
            React.createElement("img", { src: props.imagePath })),
        React.createElement("div", { className: "jurisdiction-button-text" },
            React.createElement("p", null, props.text))));
};
exports.default = JurisdictionButton;
