"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_responsive_1 = require("react-responsive");
var QuickActions_1 = require("../buttonGroups/QuickActions");
var Logo_1 = require("./Logo");
var SiteHeaderHamburger_1 = require("./SiteHeaderHamburger");
var SiteHeader = function (props) {
    return (React.createElement("div", { className: "site-header-contents" },
        React.createElement("div", { className: "site-header-container" },
            React.createElement("div", { className: "site-header-contents-left" },
                React.createElement(Logo_1.default, null)),
            React.createElement("div", { className: "site-header-contents-right" },
                React.createElement(react_responsive_1.default, { maxWidth: 1025 },
                    React.createElement(SiteHeaderHamburger_1.default, { toggleMenu: props.toggleMenu })),
                React.createElement(react_responsive_1.default, { minWidth: 1026 },
                    React.createElement(QuickActions_1.default, { toggleMobileMenu: props.toggleMenu }))))));
};
exports.default = SiteHeader;
