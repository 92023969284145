"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (status) {
    switch (status) {
        case 'unsubmitted':
            return 'Requires Submission';
        case 'reverted':
            return 'Edit Required';
        case 'submitted':
            return 'Submitted';
        case 'stage_1':
            return '1st Demand';
        case 'stage_1_ended':
            return 'Awaiting Reconfirmation';
        case 'stage_2_authorised':
            return 'Submitted For 2nd Demand';
        case 'stage_2':
            return '2nd Demand';
        case 'overdue':
            return 'Overdue';
        case 'declined':
            return 'Declined';
        case 'recovered':
            return 'Recovered';
        case 'closed':
            return 'Closed';
        case 'Declined':
            return 'Declined';
        case 'litigation_requested':
            return 'In consultation';
        case 'in_litigation':
            return 'In Litigation';
    }
});
