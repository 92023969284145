"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@rradar/core");
var React = require("react");
var react_error_boundary_1 = require("react-error-boundary");
var ViewDebtor_1 = require("../../../components/Pages/ViewDebtor/ViewDebtor");
var getState_1 = require("../../../utils/getState");
var withRouter_1 = require("../../hooks/withRouter");
var ViewDebtorRoute = function (props) {
    return (React.createElement(react_error_boundary_1.ErrorBoundary, { fallbackRender: function (props) {
            return (React.createElement(core_1.ErrorPage, { error_status: '404', error_message: "Ooops, Mandela effect?  The debtor you are looking for doesn't seem to exist.", href: (0, getState_1.default)().user.role === 'customer' ? '/dashboard/debtors' : '/admin/debtors' }));
        } },
        React.createElement(ViewDebtor_1.default, { id: props.router.params.id })));
};
exports.default = (0, withRouter_1.default)(ViewDebtorRoute);
