"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var getState_1 = require("../../../../utils/getState");
var Login = function () {
    return (React.createElement("div", { className: "login-container flex-column" },
        React.createElement("div", { className: "sign-up-container" },
            React.createElement("div", { className: "sign-up-area" },
                React.createElement("header", null,
                    React.createElement("h1", null, "New to the rradar platform"),
                    React.createElement("h3", null, "Create an account today")),
                React.createElement("p", null, "Create a digital account and purchase the recover application to get started."),
                React.createElement("form", { className: "sign-up-form" },
                    React.createElement("button", { type: "button", className: "button login-grey-button", onClick: function () {
                            window.location.href = "".concat((0, getState_1.default)().api.signup);
                        } }, "Sign Up Now"),
                    React.createElement("button", { type: "button", className: "button red-button login-button", onClick: function () {
                            window.location.href = "".concat((0, getState_1.default)().api.authentication_url);
                        } }, "Log In"))))));
};
exports.default = Login;
