"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_1 = require("react-router");
var AuthenticatedRoute_1 = require("../AuthenticatedRoute");
var ListDebts_1 = require("./Debts/ListDebts");
var AdminDash_1 = require("./AdminDashboard/AdminDash");
var ListCompanies_1 = require("./Companies/ListCompanies");
var SingleCompany_1 = require("./Companies/SingleCompany");
var AdminDemandsPage_1 = require("./DemandsPage/AdminDemandsPage");
var SingleDebt_1 = require("./Debts/SingleDebt");
var Admin = function () {
    return (React.createElement(react_router_1.Routes, null,
        React.createElement(react_router_1.Route, { path: "/debts/:reference/upcoming/:stage", element: React.createElement(AuthenticatedRoute_1.default, { accessTypes: [AuthenticatedRoute_1.AccessTypes.Admin, AuthenticatedRoute_1.AccessTypes.Legal, AuthenticatedRoute_1.AccessTypes.Triage] },
                React.createElement(AdminDemandsPage_1.default, null)) }),
        React.createElement(react_router_1.Route, { path: "/companies/:id", element: React.createElement(AuthenticatedRoute_1.default, { accessTypes: [AuthenticatedRoute_1.AccessTypes.Admin, AuthenticatedRoute_1.AccessTypes.Legal, AuthenticatedRoute_1.AccessTypes.Triage] },
                React.createElement(SingleCompany_1.default, null)) }),
        React.createElement(react_router_1.Route, { path: "/debts/:reference", element: React.createElement(AuthenticatedRoute_1.default, { accessTypes: [AuthenticatedRoute_1.AccessTypes.Admin, AuthenticatedRoute_1.AccessTypes.Legal, AuthenticatedRoute_1.AccessTypes.Triage] },
                React.createElement(SingleDebt_1.default, null)) }),
        React.createElement(react_router_1.Route, { path: "/debts", element: React.createElement(AuthenticatedRoute_1.default, { accessTypes: [AuthenticatedRoute_1.AccessTypes.Admin, AuthenticatedRoute_1.AccessTypes.Legal, AuthenticatedRoute_1.AccessTypes.Triage] },
                React.createElement(ListDebts_1.default, null)) }),
        React.createElement(react_router_1.Route, { path: "/companies", element: React.createElement(AuthenticatedRoute_1.default, { accessTypes: [AuthenticatedRoute_1.AccessTypes.Admin, AuthenticatedRoute_1.AccessTypes.Legal, AuthenticatedRoute_1.AccessTypes.Triage] },
                React.createElement(ListCompanies_1.default, null)) }),
        React.createElement(react_router_1.Route, { path: "/", element: React.createElement(AuthenticatedRoute_1.default, { accessTypes: [AuthenticatedRoute_1.AccessTypes.Admin, AuthenticatedRoute_1.AccessTypes.Legal, AuthenticatedRoute_1.AccessTypes.Triage] },
                React.createElement(AdminDash_1.default, null)) })));
};
exports.default = Admin;
