"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Cookie = require("js-cookie");
var lister_1 = require("@rradar/lister");
var core_1 = require("@rradar/core");
var getState_1 = require("../../../utils/getState");
var ListingsTable_1 = require("../ListingsTable");
var returnTableByKeyType_1 = require("../../../utils/returnTableByKeyType");
var getStatusFilters_1 = require("../../../utils/getStatusFilters");
var recoverInterface_1 = require("../../../utils/recoverInterface");
var Collapsible_1 = require("../../common/Collapsible/Collapsible");
var DebtsTable = /** @class */ (function (_super) {
    __extends(DebtsTable, _super);
    function DebtsTable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            debtFilters: [],
            activeOpen: true,
            closedOpen: false,
        };
        _this.listerRef = React.createRef();
        _this.closedlisterRef = React.createRef();
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                (0, getStatusFilters_1.default)().then(function (debtFilters) {
                    _this.setState({
                        debtFilters: debtFilters,
                    });
                });
                return [2 /*return*/];
            });
        }); };
        _this.getReference = function (input) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, (0, recoverInterface_1.default)()
                            .debts()
                            .getByRef(input)
                            .then(function (response) {
                            window.location.href =
                                (0, getState_1.default)().user.role === 'customer'
                                    ? "/dashboard/debts/".concat(response.debt.reference)
                                    : "/admin/debts/".concat(response.debt.reference);
                        })
                            .catch(function (error) {
                            (0, core_1.ToastNotification)({ type: 'error', message: error.message });
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.setCollapsedActive = function () {
            var activeOpen = _this.state.activeOpen;
            _this.setState({ activeOpen: !activeOpen });
        };
        _this.setCollapsedClosed = function () {
            var closedOpen = _this.state.closedOpen;
            _this.setState({ closedOpen: !closedOpen });
        };
        _this.adminTable = function () {
            return (React.createElement("div", { className: "" },
                React.createElement(lister_1.default, { ref: _this.listerRef, api: {
                        url: (0, getState_1.default)().api.endpoint,
                        headers: new Headers({
                            Authorization: Cookie.get('sessionId'),
                        }),
                        resource: 'debt',
                        endpoint: "/debts",
                    }, urlParams: ['page'], singleSort: true, fixedParams: [['where', 'status:submitted|unsubmitted']], render: function (lister) {
                        return (React.createElement(ListingsTable_1.default, { lister: lister, selecting: false, keys: (0, returnTableByKeyType_1.default)('debts', _this.state.debtFilters) }));
                    } })));
        };
        _this.activeTable = function () {
            var activeFilters = [];
            _this.state.debtFilters.forEach(function (debtFilter) {
                if (debtFilter.value !== 'closed' &&
                    debtFilter.value !== 'recovered' &&
                    debtFilter.value !== 'declined' &&
                    debtFilter.value !== 'in_litigation') {
                    activeFilters.push(debtFilter);
                }
            });
            return (React.createElement("div", { className: "" },
                React.createElement(lister_1.default, { ref: _this.listerRef, api: {
                        url: (0, getState_1.default)().api.endpoint,
                        headers: new Headers({
                            Authorization: Cookie.get('sessionId'),
                        }),
                        resource: 'debt',
                        endpoint: "/debts",
                    }, singleSort: true, fixedParams: [['where', 'status:submitted|unsubmitted']], render: function (lister) {
                        return (React.createElement(ListingsTable_1.default, { lister: lister, selecting: false, keys: (0, returnTableByKeyType_1.default)('debts', activeFilters) }));
                    } })));
        };
        _this.closedTable = function () {
            var closedFilters = [];
            _this.state.debtFilters.forEach(function (debtFilter) {
                if (debtFilter.value === 'closed' ||
                    debtFilter.value === 'recovered' ||
                    debtFilter.value === 'declined' ||
                    debtFilter.value === 'in_litigation') {
                    closedFilters.push(debtFilter);
                }
            });
            return (React.createElement("div", { className: "" },
                React.createElement(lister_1.default, { ref: _this.closedlisterRef, api: {
                        url: (0, getState_1.default)().api.endpoint,
                        headers: new Headers({
                            Authorization: Cookie.get('sessionId'),
                        }),
                        resource: 'debt',
                        endpoint: "/debts",
                    }, singleSort: true, fixedParams: [['where', '!status:submitted|unsubmitted']], render: function (lister) {
                        return (React.createElement(ListingsTable_1.default, { lister: lister, selecting: false, keys: (0, returnTableByKeyType_1.default)('debts', closedFilters) }));
                    } })));
        };
        _this.render = function () {
            return (React.createElement("div", { className: "debt-table site-body" }, (0, getState_1.default)().user.role === 'customer' ? (React.createElement("div", null,
                React.createElement(Collapsible_1.default, { open: _this.state.activeOpen, header: 'Active Debts', clickFunction: _this.setCollapsedActive, class: 'active-debts' }, _this.activeTable()),
                React.createElement(Collapsible_1.default, { open: _this.state.closedOpen, header: 'Closed Debts', clickFunction: _this.setCollapsedClosed, class: 'closed-debts' }, _this.closedTable()))) : (React.createElement("div", null,
                _this.adminTable(),
                " "))));
        };
        return _this;
    }
    return DebtsTable;
}(React.Component));
exports.default = DebtsTable;
