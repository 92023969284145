"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var classnames_1 = require("classnames");
var Hint_1 = require("./Hint");
var isFieldRequired_1 = require("../../utils/isFieldRequired");
var TextField = function (props) {
    return (React.createElement("div", { className: props.className },
        props.label !== '' && props.label !== undefined && props.label !== null ? (React.createElement("label", { htmlFor: props.name, className: (0, classnames_1.default)({
                required: (0, isFieldRequired_1.default)(props.name, props.validator),
            }) },
            ' ',
            props.label,
            ' ',
            props.hint !== null && props.hint !== undefined && props.hint !== '' ? (React.createElement(Hint_1.default, { id: props.hint }, props.hint)) : null)) : null,
        !props.onChange ? (React.createElement(formik_1.Field, { type: props.type ? props.type : 'text', name: props.name, placeholder: props.placeholder, value: props.value, required: (0, isFieldRequired_1.default)(props.name, props.validator), autoComplete: "off", step: 1, min: props.min, disabled: props.disabled, max: props.max })) : (React.createElement(formik_1.Field, { type: props.type ? props.type : 'text', name: props.name, placeholder: props.placeholder, onChange: function (e) {
                props.onChange(e.target.value);
            }, value: props.value, required: (0, isFieldRequired_1.default)(props.name, props.validator), autoComplete: "off", step: 1, min: props.min, disabled: props.disabled, max: props.max }))));
};
exports.default = TextField;
