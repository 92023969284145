"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var RadioButton = /** @class */ (function (_super) {
    __extends(RadioButton, _super);
    function RadioButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.selectedButton = function (isSelected) {
            return isSelected === true ? { fill: '#c3c3c3' } : null;
        };
        _this.radioButton = function () {
            return (React.createElement("svg", { viewBox: "0 0 200 200", className: "radio-button", preserveAspectRatio: "none", xmlns: "http://www.w3.org/2000/svg", onClick: function () {
                    _this.props.handleRadioChange(_this.props.value, _this.props.name);
                } },
                React.createElement("circle", { cx: "100", cy: "100", r: "70", stroke: "#c3c3c3", style: _this.selectedButton(_this.props.checked), strokeWidth: 22, className: "radio-button-circle" })));
        };
        _this.render = function () {
            return _this.radioButton();
        };
        return _this;
    }
    return RadioButton;
}(React.Component));
exports.default = RadioButton;
