"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var CommonButtons_1 = require("../../../buttonGroups/CommonButtons");
var CompanyBankDetailsValidator_1 = require("../../../../validators/CompanyBankDetailsValidator");
var CompanyBankDetails = function (props) {
    var inheritedProps = props;
    return (React.createElement("div", { className: "site-contents-container" },
        React.createElement(formik_1.Formik, { initialValues: {
                bank_name: props.data.bank_name,
                bank_sort_code: props.data.bank_sort_code,
                bank_account_number: props.data.bank_account_number,
                bank_account_holder: props.data.bank_account_holder,
            }, validationSchema: CompanyBankDetailsValidator_1.default, validateOnBlur: true, validateOnChange: false, onSubmit: function (values) { return props.onSubmit(values); }, render: function (formikProps) { return (React.createElement(formik_1.Form, null,
                React.createElement("div", { className: "form-panel" },
                    React.createElement("div", { className: "form-input" },
                        React.createElement("label", { htmlFor: "bank_name" }, "Bank Name"),
                        React.createElement(formik_1.Field, { type: "text", name: "bank_name", handleChange: formikProps.handleChange, value: formikProps.values.bank_name })),
                    React.createElement(formik_1.ErrorMessage, { name: "bank_name" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
                    React.createElement("div", { className: "form-input" },
                        React.createElement("label", { htmlFor: "bank_account_holder" }, "Account Holder"),
                        React.createElement(formik_1.Field, { type: "text", name: "bank_account_holder", handleChange: formikProps.handleChange, value: formikProps.values.bank_account_holder })),
                    React.createElement(formik_1.ErrorMessage, { name: "bank_account_holder" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
                    React.createElement("div", { className: "form-input" },
                        React.createElement("label", { htmlFor: "bank_account_number" }, "Account Number"),
                        React.createElement(formik_1.Field, { type: "text", name: "bank_account_number", handleChange: formikProps.handleChange, value: formikProps.values.bank_account_number })),
                    React.createElement(formik_1.ErrorMessage, { name: "bank_account_number" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
                    React.createElement("div", { className: "form-input" },
                        React.createElement("label", { htmlFor: "bank_sort_code" }, "Sort Code"),
                        React.createElement(formik_1.Field, { type: "text", name: "bank_sort_code", handleChange: formikProps.handleChange, value: formikProps.values.bank_sort_code })),
                    React.createElement(formik_1.ErrorMessage, { name: "bank_sort_code" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); })),
                React.createElement(CommonButtons_1.default, { saveMethod: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, inheritedProps.setRedirect(false, formikProps.submitForm())];
                                case 1:
                                    _a.sent();
                                    formikProps.setSubmitting(false);
                                    return [2 /*return*/];
                            }
                        });
                    }); }, saveAndExitMethod: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, inheritedProps.setRedirect(true, formikProps.submitForm())];
                                case 1:
                                    _a.sent();
                                    formikProps.setSubmitting(false);
                                    return [2 /*return*/];
                            }
                        });
                    }); }, cancelMethod: function () {
                        window.location.href = '/dashboard';
                    }, disableMethodButtons: !(formikProps.isValid && formikProps.dirty && !formikProps.isSubmitting) }))); } })));
};
exports.default = CompanyBankDetails;
