"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var AdminActionBanner = function (props) {
    return (React.createElement("div", { key: props.debtID, style: { cursor: 'pointer' }, className: "banner-container banner-orange", onClick: function () {
            window.location.href = props.location;
        } },
        React.createElement("div", { className: "banner-content flex-row" },
            React.createElement("div", { className: "banner-left-icon" }, props.icon),
            React.createElement("div", { className: "banner-text flex-column" },
                React.createElement("p", null,
                    React.createElement("strong", null, props.titleText),
                    props.bodyText)))));
};
exports.default = AdminActionBanner;
