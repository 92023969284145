"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var GuidanceGuides = function () {
    return (React.createElement("div", null,
        React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("th", null, "File"),
                React.createElement("th", null, "Jurisdiction"),
                React.createElement("th", null, "View"),
                React.createElement("th", null, "Download")),
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", null, "rradarrecover User Guide"),
                    React.createElement("td", null, "England and Wales/Scotland"),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/rradarrecover User Guide.pdf", target: "_blank", rel: "noreferrer" }, "View")),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/rradarrecover User Guide.pdf", download: true }, "Download"))),
                React.createElement("tr", null,
                    React.createElement("td", null, "2a) Debt Proceedings Action Guide"),
                    React.createElement("td", null, "England and Wales"),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/Debt Proceedings Action Guide E&W.pdf", rel: "noreferrer", target: "_blank" }, "View")),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/Debt Proceedings Action Guide E&W.pdf", download: true }, "Download"))),
                React.createElement("tr", null,
                    React.createElement("td", null, "2a) Debt Proceedings Action Guide"),
                    React.createElement("td", null, "Scotland"),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/scotland/2a) Debt Proceedings Action Guide (Scotland).pdf", rel: "noreferrer", target: "_blank" }, "View")),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/scotland/2a) Debt Proceedings Action Guide (Scotland).pdf", download: true }, "Download"))),
                React.createElement("tr", null,
                    React.createElement("td", null, "2b) Good Credit Management Guide"),
                    React.createElement("td", null, "England and Wales/Scotland"),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/2b) Good Credit Management Guide.pdf", rel: "noreferrer", target: "_blank" }, "View")),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/2b) Good Credit Management Guide.pdf", download: true }, "Download"))),
                React.createElement("tr", null,
                    React.createElement("td", null, "3a) Stage 3 Alternative Dispute Resolution"),
                    React.createElement("td", null, "England and Wales"),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/Alternative_Dispute_Resolution.pdf", rel: "noreferrer", target: "_blank" }, "View")),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/Alternative_Dispute_Resolution.pdf", download: true }, "Download"))),
                React.createElement("tr", null,
                    React.createElement("td", null, "3a) Stage 3 Alternative Dispute Resolution"),
                    React.createElement("td", null, "Scotland"),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/scotland/3a) Stage 3 Alternative Dispute Resolution (Scotland)", rel: "noreferrer", target: "_blank" }, "View")),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/scotland/3a) Stage 3 Alternative Dispute Resolution (Scotland)", download: true }, "Download"))),
                React.createElement("tr", null,
                    React.createElement("td", null, "Step by Step Credit Control Chart"),
                    React.createElement("td", null, "England and Wales/Scotland"),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/V.2 Step by Step Credit Control Chart-01.jpg", rel: "noreferrer", target: "_blank" }, "View")),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/V.2 Step by Step Credit Control Chart-01.jpg", download: true }, "Download")))))));
};
exports.default = GuidanceGuides;
