"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var HeadedTextareaInput = function (props) {
    return (React.createElement("div", { className: "headed-textarea-input flex-column" },
        React.createElement("div", { className: "headed-textarea-input-icon" },
            React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: props.icon })),
        React.createElement("textarea", { onChange: function (e) { return props.setFieldValue(props.name, e.target.value); }, placeholder: props.placeholder, value: props.value })));
};
exports.default = HeadedTextareaInput;
