"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var lodash_1 = require("lodash");
var classnames_1 = require("classnames");
var Login_1 = require("./components/Login");
var getState_1 = require("../../../utils/getState");
var redirectOnRole_1 = require("../../../utils/redirectOnRole");
var LandingPage = function () {
    React.useEffect(function () {
        (0, redirectOnRole_1.default)();
    }, []);
    return (React.createElement("div", { className: "landing-page-contents flex-row-wrap" },
        React.createElement("div", { className: (0, classnames_1.default)({
                'landing-page-contents-left': true,
                'landing-page-contents-authorized': !(0, lodash_1.isEmpty)((0, getState_1.default)().user),
                'landing-page-contents-unauthorized': (0, lodash_1.isEmpty)((0, getState_1.default)().user),
            }) },
            React.createElement("div", { className: "landing-page-left-text" },
                React.createElement("h1", null, "The Smarter Debt Recovery Application"),
                React.createElement("p", null, "rradarrecover is an online debt recovery platform from rradar, a specialist litigation and commercial law firm who are experts at helping organisations and empowering them to take the best course of action."),
                React.createElement("p", null, "rradarrecoverwill help support your commercial organisation with your credit management and assist with the recovery of bad debts from your commercial and individual contacts."),
                React.createElement("p", null, "This affordable debt recovery solution is easy to use and provides a powerful and effective online debt recovery tool that can be accessed 24/7."),
                React.createElement("p", null, "Sign up now and start to recover your debts or contact our team to discuss more."),
                React.createElement("div", null,
                    React.createElement("p", { className: "information-block" },
                        React.createElement("i", { className: "fas fa-phone" }),
                        " 0300 014 996"),
                    React.createElement("p", { className: "information-block" },
                        React.createElement("i", { className: "fas fa-envelope" }),
                        " ",
                        React.createElement("a", { href: "mailto:recover@rradar.com" }, "recover@rradar.com")),
                    React.createElement("p", { className: "information-block" },
                        React.createElement("i", { className: "fas fa-globe" }),
                        " ",
                        React.createElement("a", { href: "https://www.rradar.com" }, "www.rradar.com"))))),
        (0, lodash_1.isEmpty)((0, getState_1.default)().user) ? (React.createElement("div", { className: "landing-page-contents-right" },
            React.createElement(Login_1.default, null))) : null));
};
exports.default = LandingPage;
