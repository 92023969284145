"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var core_1 = require("@rradar/core");
var recoverInterface_1 = require("../../../../../utils/recoverInterface");
var AdminActionBanner_1 = require("./AdminActionBanner");
var Collapsible_1 = require("../../../../common/Collapsible/Collapsible");
var AdminDashboardActions = /** @class */ (function (_super) {
    __extends(AdminDashboardActions, _super);
    function AdminDashboardActions() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            debts: null,
            hasFiltered: false,
            actions: [[], [], []],
            firstDemandActive: false,
            secondDemandActive: false,
            requestActive: false,
        };
        _this.setFirstDemandCollapse = function () {
            var currentValue = _this.state.firstDemandActive;
            _this.setState({ firstDemandActive: !currentValue });
        };
        _this.setSecondDemandCollapse = function () {
            var currentValue = _this.state.secondDemandActive;
            _this.setState({ secondDemandActive: !currentValue });
        };
        _this.setRequestCollapse = function () {
            var currentValue = _this.state.requestActive;
            _this.setState({ requestActive: !currentValue });
        };
        _this.getData = function () {
            (0, recoverInterface_1.default)()
                .dashboard()
                .get()
                .then(function (response) {
                _this.setState({ debts: response });
            })
                .catch(function (error) { return (0, core_1.ToastNotification)({ type: 'error', message: error.message }); });
        };
        _this.componentDidMount = function () {
            _this.getData();
        };
        _this.componentDidUpdate = function () {
            _this.handleUpdate();
            window.onfocus = function () {
                _this.setState({ hasFiltered: false, actions: [[], [], []] });
                _this.getData();
            };
        };
        _this.handleUpdate = function () {
            if (_this.state.debts !== null && _this.state.hasFiltered === false) {
                _this.handleActions();
                _this.setState({ hasFiltered: true });
            }
        };
        _this.handleActions = function () {
            var actionArray = [[], [], []];
            if (_this.state.debts !== null) {
                if (Object.prototype.hasOwnProperty.call(_this.state.debts, 'submitted')) {
                    _this.state.debts.submitted.forEach(function (debt) {
                        actionArray[0].push(React.createElement(AdminActionBanner_1.default, { key: debt.reference, debtID: debt.id, location: "/admin/debts/".concat(debt.reference, "/upcoming/").concat(1), icon: React.createElement("i", { className: "fa fa-eye" }), titleText: "Debt ".concat(debt.reference, " needs its first demand letter "), bodyText: 'Please click here to check and generate this letter' }));
                    });
                }
                if (Object.prototype.hasOwnProperty.call(_this.state.debts, 'stage_2_authorised')) {
                    _this.state.debts.stage_2_authorised.forEach(function (debt) {
                        actionArray[1].push(React.createElement(AdminActionBanner_1.default, { key: debt.reference, debtID: debt.id, location: "/admin/debts/".concat(debt.reference, "/upcoming/").concat(2), icon: React.createElement("i", { className: "fa fa-eye" }), titleText: "Debt ".concat(debt.reference, " needs its second demand letter "), bodyText: 'Please click here to check and generate this letter' }));
                    });
                }
                if (Object.prototype.hasOwnProperty.call(_this.state.debts, 'litigation_requested')) {
                    _this.state.debts.litigation_requested.forEach(function (debt) {
                        actionArray[2].push(React.createElement(AdminActionBanner_1.default, { key: debt.reference, debtID: debt.id, location: "/admin/debts/".concat(debt.reference), icon: React.createElement("i", { className: "fas fa-gavel" }), titleText: "Debt ".concat(debt.reference, " has a request to go to litigation "), bodyText: 'Please click here to check this debt and then accept or advise against litigation.' }));
                    });
                }
            }
            _this.setState({ actions: actionArray });
        };
        _this.renderActions = function () {
            if (_this.state.actions[0].length !== 0 ||
                _this.state.actions[1].length !== 0 ||
                _this.state.actions[2].length !== 0) {
                return (React.createElement("div", null,
                    _this.state.actions[0].length !== 0 ? (React.createElement(Collapsible_1.default, { open: _this.state.firstDemandActive, header: "1st Demand Required - ".concat(_this.state.actions[0].length), clickFunction: _this.setFirstDemandCollapse, class: 'firstDemand-debts' }, _this.state.actions[0])) : null,
                    _this.state.actions[1].length !== 0 ? (React.createElement(Collapsible_1.default, { open: _this.state.secondDemandActive, header: "2nd Demand Required - ".concat(_this.state.actions[1].length), clickFunction: _this.setSecondDemandCollapse, class: 'secondDemand-debts' }, _this.state.actions[1])) : null,
                    _this.state.actions[2].length !== 0 ? (React.createElement(Collapsible_1.default, { open: _this.state.requestActive, header: "Litigation Requested - ".concat(_this.state.actions[2].length), clickFunction: _this.setRequestCollapse, class: 'requested-debts' }, _this.state.actions[2])) : null));
            }
            if (_this.state.hasFiltered === true &&
                _this.state.actions[0].length === 0 &&
                _this.state.actions[1].length === 0 &&
                _this.state.actions[2].length === 0) {
                return React.createElement("p", null, "There are no pending actions for you to take");
            }
            return null;
        };
        _this.render = function () {
            return React.createElement("div", { className: "legal_dashboard_actions" }, _this.renderActions());
        };
        return _this;
    }
    return AdminDashboardActions;
}(React.Component));
exports.default = AdminDashboardActions;
