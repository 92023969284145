"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var recover_module_1 = require("@rradar/recover-module");
// import Recover from "../../../../Documents/npm/recover";
var getState_1 = require("./getState");
var Cookie = require("js-cookie");
exports.default = (function () {
    var recover = new recover_module_1.default({ endpoint: (0, getState_1.default)().api.endpoint });
    recover.session_token = Cookie.get("sessionId");
    return recover;
});
