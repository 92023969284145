"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var LoadingContainer_1 = require("../../../../../../../common/LoadingContainer");
var DebtCloseModalForm_1 = require("./DebtCloseModalForm");
var getState_1 = require("../../../../../../../../utils/getState");
var InformationTip_1 = require("../../../../../../../common/InformationTip");
var DebtAbandonModalContent = function (props) {
    var contentByRole = function () {
        if ((0, getState_1.default)().user.role === 'customer') {
            return (React.createElement(React.Fragment, null,
                React.createElement("p", null, "If you believe you can no longer pursue this debt you can mark it as\n            closed. This will close the debt recovery process."),
                React.createElement(InformationTip_1.default, null,
                    React.createElement("div", null, "You can give a reason why this debt wasn't recovered in the box\n              below. This could help you determine a course of action in future\n              recoveries."))));
        }
        React.createElement("p", null, "If there has been no communication from the customer or information\n        leads you to believe that this debt can no longer be recovered, you can\n        mark it as closed. This will close the debt.");
    };
    return (React.createElement("section", null,
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "modal-header flex-row" },
                React.createElement("h3", null, "Close Debt"),
                React.createElement("div", { className: "flex-row" },
                    React.createElement("div", { className: "isPointer", onClick: function () {
                            props.handleCloseModal();
                        } },
                        React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'times'] }))))),
        React.createElement("div", { className: "container" },
            contentByRole(),
            React.createElement(LoadingContainer_1.default, { render: function (config) {
                    return React.createElement(DebtCloseModalForm_1.default, __assign({}, config, props));
                } }))));
};
exports.default = DebtAbandonModalContent;
