"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var core_1 = require("@rradar/core");
var DebtorValidator_1 = require("../../../validators/DebtorValidator");
var recoverInterface_1 = require("../../../utils/recoverInterface");
var EditDebtJuridiction_1 = require("../ViewDebt/EditDebt/EditDebtComponents/EditDebtJuridiction");
var EditDebtDebtorName_1 = require("../ViewDebt/EditDebt/EditDebtComponents/EditDebtDebtorName/EditDebtDebtorName");
var DebtorEditWarning_1 = require("./components/DebtorEditWarning/DebtorEditWarning");
var ViewDebtor = function (props) {
    var _a = __read(React.useState('Individual'), 2), protocol = _a[0], setProtocol = _a[1];
    var _b = __read(React.useState({
        id: null,
        company_id: null,
        company_name: '',
        email: '',
        address_1: '',
        address_2: '',
        city: '',
        post_code: '',
        country: '',
        protocol_id: 1,
        created_at: '',
        updated_at: '',
        merge_variables: { representative_name: '' },
        representative_name: '',
        first_name: null,
        middle_name: null,
        last_name: null,
        trading_name: '',
        companies_house_number: '',
        jurisdiction: '',
        name: '',
        debts_count: null,
        active_debts_count: null,
    }), 2), debtor = _b[0], setDebtor = _b[1];
    React.useEffect(function () {
        getData();
    }, [props.id]);
    React.useEffect(function () {
        setProtocol(debtor.protocol_id === 1 ? 'Individual' : 'Business');
    }, [debtor]);
    var getData = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, recoverInterface_1.default)()
                        .debtors()
                        .get(props.id)
                        // @ts-ignore
                        .then(function (response) { return setDebtor(response.debtor); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var saveData = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var name, debtorData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    name = {};
                    if (values.protocol_id === 1) {
                        name = {
                            first_name: values.first_name,
                            middle_name: values.middle_name,
                            last_name: values.last_name,
                        };
                    }
                    debtorData = __assign(__assign({}, name), { company_name: values.company_name, trading_name: values.trading_name, companies_house_number: values.companies_house_number, email: values.email, address_1: values.address_1, address_2: values.address_2, city: values.city, country: values.country, post_code: values.post_code, protocol_id: values.protocol_id, merge_variables: values.merge_variables, jurisdiction: values.jurisdiction });
                    return [4 /*yield*/, (0, recoverInterface_1.default)()
                            .debtors()
                            .edit(props.id, debtorData)
                            .then(function () { return (0, core_1.ToastNotification)({ type: 'success', message: 'Debtor updated' }); })
                            .catch(function (error) { return (0, core_1.ToastNotification)({ type: 'error', message: error.message }); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "container" },
        React.createElement("div", { className: "edit-debt-header" }),
        React.createElement("div", { className: "flex-column" },
            React.createElement(formik_1.Formik, { onSubmit: function () { return console.log('Submit'); }, enableReinitialize: true, initialValues: debtor, validationSchema: DebtorValidator_1.default, render: function (formikProps) { return (React.createElement(formik_1.Form, null,
                    React.createElement("div", { className: "edit-debtor-header flex-row-wrap" },
                        React.createElement("div", { className: "edit-debtor-header-contents flex-row" },
                            React.createElement("div", { className: "flex-column" },
                                React.createElement("h1", { className: "flex-row" }, formikProps.values.name),
                                React.createElement("h3", null, protocol))),
                        React.createElement(EditDebtJuridiction_1.default, { values: formikProps.values, setFieldValue: formikProps.setFieldValue })),
                    React.createElement(DebtorEditWarning_1.default, { active_debts_count: debtor.active_debts_count, debts_count: debtor.debts_count }),
                    React.createElement("div", { className: "flex-row-wrap" },
                        React.createElement("div", { className: "flex-column form-panel" },
                            React.createElement(EditDebtDebtorName_1.default, { values: formikProps.values, setFieldValue: formikProps.setFieldValue }),
                            React.createElement("div", { className: "form-input" },
                                React.createElement("label", null, "Debtor Email"),
                                React.createElement(formik_1.Field, { type: "text", name: "email", value: formikProps.values.email })),
                            React.createElement(formik_1.ErrorMessage, { name: "email" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
                            React.createElement("div", { className: "form-input" },
                                React.createElement("label", null, "Trading Name"),
                                React.createElement(formik_1.Field, { type: "text", name: "trading_name", value: formikProps.values.trading_name })),
                            React.createElement(formik_1.ErrorMessage, { name: "trading_name" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
                            React.createElement("div", { className: "form-input" },
                                React.createElement("label", null, "Company Number"),
                                React.createElement(formik_1.Field, { type: "text", name: "companies_house_number", value: formikProps.values.companies_house_number })),
                            React.createElement(formik_1.ErrorMessage, { name: "companies_house_number" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); })),
                        React.createElement("div", { className: "flex-column form-panel" },
                            React.createElement("div", { className: "form-input" },
                                React.createElement("label", null, "Address Line 1"),
                                React.createElement(formik_1.Field, { type: "text", name: "address_1", placeholder: "Address Line 1", value: formikProps.values.address_1 })),
                            React.createElement("div", { className: "form-input" },
                                React.createElement("label", null, "Address Line 2"),
                                React.createElement(formik_1.Field, { type: "text", name: "address_2", placeholder: "Address Line 2", value: formikProps.values.address_2 })),
                            React.createElement("div", { className: "form-input" },
                                React.createElement("label", null, "City"),
                                React.createElement(formik_1.Field, { type: "text", name: "city", placeholder: "City", value: formikProps.values.city })),
                            React.createElement("div", { className: "form-input" },
                                React.createElement("label", null, "Postcode"),
                                React.createElement(formik_1.Field, { type: "text", name: "post_code", placeholder: "Postcode", value: formikProps.values.post_code })),
                            React.createElement(formik_1.ErrorMessage, { name: "address_1" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
                            React.createElement(formik_1.ErrorMessage, { name: "address_2" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
                            React.createElement(formik_1.ErrorMessage, { name: "city" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
                            React.createElement(formik_1.ErrorMessage, { name: "post_code" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); })),
                        React.createElement("div", { className: "flex-column form-panel" },
                            React.createElement("div", { className: "flex-column form-panel form-panel-coloured" },
                                React.createElement("button", { type: "button", className: "red-button button", disabled: !(formikProps.isValid && formikProps.dirty && !formikProps.isSubmitting), onClick: function () {
                                        saveData(formikProps.values);
                                    } }, "Save changes"),
                                React.createElement("button", { type: "button", className: "secondary-button button", onClick: function () {
                                        window.location.href = "/dashboard/debtors/".concat(debtor.id, "/new-debt");
                                    } }, "Make new debt"),
                                React.createElement("button", { type: "button", className: "cancel-button button", onClick: function () {
                                        window.location.href = "/dashboard/debtors";
                                    } }, "Cancel")))))); } }))));
};
exports.default = ViewDebtor;
