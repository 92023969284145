"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var lodash_1 = require("lodash");
var core_1 = require("@rradar/core");
var EditDebtDebtor_1 = require("./EditDebtComponents/EditDebtDebtor");
var EditDebtDebtDetails_1 = require("./EditDebtComponents/EditDebtDebtDetails");
var EditDebtInvoice_1 = require("./EditDebtComponents/EditDebtInvoice");
var EditDebtValidator_1 = require("../../../../validators/EditDebtValidator");
var FileInput_1 = require("../../../common/FileInput");
var recoverInterface_1 = require("../../../../utils/recoverInterface");
var handleFileSubmit_1 = require("../../../../utils/handleFileSubmit");
var EditDebtHeader_1 = require("./EditDebtComponents/EditDebtHeader");
var debtTemplate_1 = require("../../../../utils/debtTemplate");
var getState_1 = require("../../../../utils/getState");
var EditDebtButtonArea_1 = require("./EditDebtComponents/EditDebtButtonArea");
var debtUtils_1 = require("../../../../utils/debtUtils");
var EditDebt = function (props) {
    var _a = __read(React.useState(''), 2), reference = _a[0], setReference = _a[1];
    var _b = __read(React.useState(''), 2), status = _b[0], setStatus = _b[1];
    var _c = __read(React.useState(false), 2), disabled = _c[0], setDisabled = _c[1];
    var _d = __read(React.useState('Individual'), 2), protocol = _d[0], setProtocol = _d[1];
    var _e = __read(React.useState({ filename: '' }), 2), file = _e[0], setFile = _e[1];
    var _f = __read(React.useState(false), 2), fileUploaded = _f[0], setFileUploaded = _f[1];
    var _g = __read(React.useState(__assign({}, (0, debtTemplate_1.default)())), 2), debt = _g[0], setDebt = _g[1];
    var _h = __read(React.useState({
        protocol_id: 1,
        id: null,
    }), 2), debtor = _h[0], setDebtor = _h[1];
    React.useEffect(function () {
        if (props.debt) {
            if (props.debt.reference !== '') {
                setDebt(props.debt);
                setReference(props.debt.reference);
                setStatus(props.debt.status);
                if (props.debt.debtor_id) {
                    //@ts-ignore - Debt is flattened, needs looking at
                    setProtocol(props.debt.protocol_id === 1 ? 'Individual' : 'Business');
                }
            }
        }
    }, [props.debt]);
    React.useEffect(function () {
        if (props.debtor_id) {
            if (props.debtor_id !== '') {
                var getDebtorAsync = function () { return __awaiter(void 0, void 0, void 0, function () {
                    var newDebtor;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, getDebtor()];
                            case 1:
                                newDebtor = _a.sent();
                                setDebtor(newDebtor);
                                return [2 /*return*/];
                        }
                    });
                }); };
                getDebtorAsync();
            }
        }
    }, [props.debtor_id]);
    React.useEffect(function () {
        if (props.debtor_id) {
            var newDebt = debt;
            delete newDebt.debtor;
            newDebt.debtor_id = parseInt(props.debtor_id.toString(), 10);
            newDebt.interest_rate = debtor.protocol_id === 1 ? 8 : 8 + (0, getState_1.default)().interest_rate_boe;
            setDebt(__assign(__assign({}, newDebt), debtor));
            setProtocol(debtor.protocol_id === 1 ? 'Individual' : 'Business');
        }
    }, [debtor]);
    React.useEffect(function () {
        if (props.file !== { filename: '' }) {
            setFile(props.file);
        }
    }, [props.file]);
    React.useEffect(function () {
        if (status !== 'reverted' && status !== 'unsubmitted' && status !== '') {
            setDisabled(true);
        }
        else
            setDisabled(false);
    }, [status]);
    var getDebtor = function () { return __awaiter(void 0, void 0, void 0, function () {
        var debtorResource;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, recoverInterface_1.default)().debtors().get(parseInt(props.debtor_id.toString(), 10))];
                case 1: return [4 /*yield*/, (_a.sent()).debtor];
                case 2:
                    debtorResource = _a.sent();
                    return [2 /*return*/, debtorResource];
            }
        });
    }); };
    var submitData = function (values, isSubmitting) { return __awaiter(void 0, void 0, void 0, function () {
        var name, debtorData, debtData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    name = {};
                    if (values.protocol_id === 1) {
                        name = {
                            first_name: values.first_name,
                            middle_name: values.middle_name,
                            last_name: values.last_name,
                        };
                    }
                    debtorData = __assign(__assign({}, name), { company_name: values.company_name, trading_name: values.trading_name, companies_house_number: values.companies_house_number, email: values.email, address_1: values.address_1, address_2: values.address_2, city: values.city, country: values.country, post_code: values.post_code, protocol_id: values.protocol_id, merge_variables: values.merge_variables });
                    debtData = {
                        original_amount: values.original_amount,
                        interest_rate: values.interest_rate,
                        invoice_due_date: (0, debtUtils_1.setDebtDate)(values.invoice_sent_date),
                        invoice_sent_date: (0, debtUtils_1.setDebtDate)(values.invoice_sent_date),
                        invoice_reference: values.invoice_reference,
                        purchase_order_number: values.purchase_order_number,
                        reminder_sent_date: (0, debtUtils_1.setDebtDate)(values.reminder_sent_date),
                        purchase_order_date: (0, debtUtils_1.setDebtDate)(values.purchase_order_date),
                        goods_description: values.goods_description,
                        services_description: values.services_description,
                        status: values.status,
                        debtor: __assign({}, debtorData),
                        jurisdiction: values.jurisdiction,
                        debtor_id: values.debtor_id,
                    };
                    return [4 /*yield*/, (0, recoverInterface_1.default)()
                            .debts()
                            .edit(values.id, debtData)
                            .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        (0, core_1.ToastNotification)({ type: 'success', message: response.message });
                                        if (!fileUploaded) return [3 /*break*/, 2];
                                        return [4 /*yield*/, (0, handleFileSubmit_1.default)({ supporting_documents: file }, props.debt.id, 'POST')];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); })
                            .then(function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!isSubmitting) return [3 /*break*/, 2];
                                        return [4 /*yield*/, (0, recoverInterface_1.default)()
                                                .debts()
                                                .access(props.debt.id)
                                                .actions()
                                                .customer('submit')
                                                .then(function () { return (0, core_1.ToastNotification)({ type: 'success', message: 'Resubmitted Debt' }); })];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); })
                            .catch(function (error) { return (0, core_1.ToastNotification)({ type: 'error', message: error.message }); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var submitNewDebt = function (values, isSubmitting) { return __awaiter(void 0, void 0, void 0, function () {
        var debtData, body, debtId, debtReference;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    debtData = {
                        original_amount: values.original_amount,
                        interest_rate: values.interest_rate,
                        invoice_due_date: (0, debtUtils_1.setDebtDate)(values.invoice_due_date),
                        invoice_sent_date: (0, debtUtils_1.setDebtDate)(values.invoice_sent_date),
                        invoice_reference: values.invoice_reference,
                        purchase_order_number: values.purchase_order_number,
                        reminder_sent_date: (0, debtUtils_1.setDebtDate)(values.reminder_sent_date),
                        purchase_order_date: (0, debtUtils_1.setDebtDate)(values.purchase_order_date),
                        goods_description: values.goods_description,
                        services_description: values.services_description,
                        jurisdiction: values.jurisdiction,
                    };
                    body = {
                        debt: debtData,
                        debtor_id: parseInt(values.debtor_id, 10),
                    };
                    return [4 /*yield*/, (0, recoverInterface_1.default)()
                            .debts()
                            .create(body)
                            .then(function (response) {
                            debtId = response.debt.debt.id;
                            debtReference = response.debt.debt.reference;
                            (0, core_1.ToastNotification)({ type: 'success', message: response.message });
                        })
                            .catch(function (error) { return (0, core_1.ToastNotification)({ type: 'error', message: error.message }); })];
                case 1:
                    _a.sent();
                    if (!fileUploaded) return [3 /*break*/, 3];
                    return [4 /*yield*/, (0, handleFileSubmit_1.default)({ supporting_documents: file }, debtId, 'POST')
                            .then(function () { return (0, core_1.ToastNotification)({ type: 'success', message: 'Submitted Documents' }); })
                            .catch(function () { return (0, core_1.ToastNotification)({ type: 'error', message: 'Documents not submitted' }); })];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    if (!isSubmitting) return [3 /*break*/, 5];
                    return [4 /*yield*/, (0, recoverInterface_1.default)()
                            .debts()
                            .access(debtId)
                            .actions()
                            .customer('submit')
                            .then(function (response) { return (0, core_1.ToastNotification)({ type: 'success', message: response.message }); })
                            .catch(function (error) { return (0, core_1.ToastNotification)({ type: 'error', message: error.message }); })];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5:
                    if (debtId !== undefined) {
                        window.location.href = "/dashboard/debts/".concat(debtReference);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "container" },
        React.createElement("div", { className: "flex-column" },
            React.createElement("div", { className: "edit-debt-header" }),
            React.createElement(formik_1.Formik, { onSubmit: function () { return console.log('Submit'); }, enableReinitialize: true, initialValues: __assign({}, debt), validationSchema: EditDebtValidator_1.default, render: function (formikProps) { return (React.createElement(formik_1.Form, null,
                    React.createElement("div", { className: "flex-column" },
                        React.createElement(EditDebtHeader_1.default, { reference: reference, protocol: protocol, values: formikProps.values, setFieldValue: formikProps.setFieldValue, disabled: disabled }),
                        React.createElement("div", { className: "flex-row", style: { flexWrap: 'wrap' } },
                            React.createElement("div", { className: "form-panel" },
                                React.createElement(EditDebtDebtor_1.default, { values: formikProps.values, setFieldValue: formikProps.setFieldValue, disabled: disabled || !(0, lodash_1.isEmpty)(debtor) })),
                            React.createElement(EditDebtDebtDetails_1.default, { values: formikProps.values, setFieldValue: formikProps.setFieldValue, disabled: disabled }),
                            React.createElement("div", { className: "form-panel flex-column" },
                                React.createElement(EditDebtInvoice_1.default, { values: formikProps.values, setFieldValue: formikProps.setFieldValue, disabled: disabled }),
                                status !== 'reverted' && status !== 'unsubmitted' && file.filename === '' ? null : (React.createElement("div", { className: "flex-column", style: { alignItems: 'center', marginBottom: '5em' } },
                                    React.createElement(FileInput_1.default, { value: file.filename, setFileUpload: function (newFile) {
                                            setFile(newFile);
                                            setFileUploaded(true);
                                        } }))),
                                React.createElement(EditDebtButtonArea_1.default, { isNew: !(0, lodash_1.isEmpty)(debtor), submitData: submitData, isValid: formikProps.isValid, dirty: formikProps.dirty, isSubmitting: formikProps.isSubmitting, submitNewDebt: submitNewDebt, values: formikProps.values, setFieldValue: formikProps.setFieldValue, debtor_id: debtor.id, reference: debt.reference })))))); } }))));
};
exports.default = EditDebt;
