"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var redirectOnRole_1 = require("../../utils/redirectOnRole");
// const logosvg = require('/assets/img/recover-white.svg');
var Logo = function () {
    return (React.createElement("img", { className: "recover-logo", src: '/assets/img/recover-white.svg', onClick: function () {
            (0, redirectOnRole_1.default)();
        } }));
};
exports.default = Logo;
