"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var ProtocolTile_1 = require("./ProtocolTile");
var TextField_1 = require("../../../../../common/TextField");
var ProtocolSelector = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "protocol-tile-container ".concat(props.values.debtor_id !== null ? 'protocol-tile-container-disabled' : '', " flex-row-wrap") },
            React.createElement(ProtocolTile_1.default, { fontAwesomeClassName: 'fas fa-user', text: 'Individual/Sole Trader', id: 1, setFieldValue: props.setFieldValue, value: props.values.protocol_id, debtor_id: props.values.debtor_id }),
            React.createElement(ProtocolTile_1.default, { fontAwesomeClassName: 'fas fa-building', text: 'Business (Or other organisation)', id: 2, setFieldValue: props.setFieldValue, value: props.values.protocol_id, debtor_id: props.values.debtor_id })),
        React.createElement(TextField_1.default, { name: "representative_name", value: props.values.representative_name, validator: props.validator, className: "".concat(props.values.protocol_id !== 2 ? 'hidden_field' : '', " flex-column"), label: 'What is the name of your contact within this business?', onChange: function (value) {
                props.setFieldValue('representative_name', value);
            }, placeholder: 'Representative name', disabled: false })));
};
exports.default = ProtocolSelector;
