"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var getState_1 = require("../../../../../../utils/getState");
var DebtInformation = function (_a) {
    var debt = _a.debt;
    return (React.createElement("div", null,
        (0, getState_1.default)().user.role !== 'customer' ? (React.createElement("div", { className: "panel" },
            React.createElement("div", { className: "split_panel_description flex-column" },
                React.createElement("h3", null, "Debt Owed To"),
                React.createElement("span", null,
                    React.createElement("span", { className: "title-icon" },
                        React.createElement("h4", { className: "company_name_click", onClick: function () { return (window.location.href = "/admin/companies/".concat(debt.debtor.company_id)); } }, debt.company !== undefined ? debt.company.name : null)))))) : null,
        React.createElement("div", { className: "panel" },
            React.createElement("div", { className: "split_panel_description flex-column" },
                React.createElement("div", { className: "left flex-column" },
                    React.createElement("h3", null, "Invoice Information"),
                    React.createElement("span", null,
                        React.createElement("span", { className: "title-icon flex-row" },
                            React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'align-left'] }),
                            React.createElement("h4", null, "Description")),
                        React.createElement("div", { className: "debt-description" }, debt.goods_description !== null && debt.goods_description !== '' ? debt.goods_description : ''),
                        React.createElement("div", { className: "debt-description" }, debt.services_description !== null && debt.services_description !== ''
                            ? debt.services_description
                            : '')),
                    React.createElement("span", null,
                        React.createElement("span", { className: "title-icon flex-row" },
                            React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'align-left'] }),
                            React.createElement("h4", null, "Reference")),
                        React.createElement("div", { className: "debt-description" }, debt.invoice_reference)),
                    React.createElement("span", null,
                        React.createElement("span", { className: "title-icon flex-row" },
                            React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'calendar'] }),
                            React.createElement("h4", null, "Sent")),
                        React.createElement("div", { className: "debt-description" }, debt.invoice_sent_date)),
                    React.createElement("span", null,
                        React.createElement("span", { className: "title-icon flex-row" },
                            React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'calendar'] }),
                            React.createElement("h4", null, "Due")),
                        React.createElement("div", { className: "debt-description" }, debt.invoice_due_date))))),
        React.createElement("div", { className: "panel" },
            React.createElement("div", { className: "split_panel_description flex-column" },
                React.createElement("div", { className: "right" },
                    React.createElement("h3", null,
                        debt.jurisdiction === 'england_wales' ? 'England' : 'Scotland',
                        " -",
                        ' ',
                        debt.debtor.protocol_id === 1 ? 'Individual' : 'Commercial'),
                    React.createElement("span", { className: "debtor-name" },
                        React.createElement("span", { className: "title-icon flex-row" },
                            React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'id-card'] }),
                            debt.debtor.name)),
                    React.createElement("span", { className: "debtor-email" },
                        React.createElement("span", { className: "title-icon flex-row" },
                            React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'envelope-open-text'] }),
                            debt.debtor.email)),
                    React.createElement("span", { className: "title-icon flex-row" },
                        React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'map-marker-alt'] }),
                        React.createElement("div", { className: "flex-column" },
                            React.createElement("span", null, debt.debtor.address_1),
                            React.createElement("span", null, debt.debtor.address_2),
                            React.createElement("span", null, debt.debtor.city),
                            React.createElement("span", null, debt.debtor.country),
                            React.createElement("span", null, debt.debtor.post_code))))))));
};
exports.default = DebtInformation;
