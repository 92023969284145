"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var classnames_1 = require("classnames");
var isFieldRequired_1 = require("../../../../../utils/isFieldRequired");
var Hint_1 = require("../../../../common/Hint");
function Checkbox(_a) {
    var id = _a.id, name = _a.name, className = _a.className, validator = _a.validator, label = _a.label, hint = _a.hint;
    return (React.createElement(React.Fragment, null,
        React.createElement("label", { htmlFor: name, className: (0, classnames_1.default)({
                required: (0, isFieldRequired_1.default)(name, validator),
            }) },
            ' ',
            label,
            hint ? React.createElement(Hint_1.default, { id: '' }, hint) : null),
        React.createElement(formik_1.Field, { name: name, render: function (_a) {
                var field = _a.field;
                return (React.createElement("input", __assign({ type: "checkbox", id: id, required: (0, isFieldRequired_1.default)(name, validator), className: className, checked: field.value }, field)));
            } })));
}
exports.default = Checkbox;
