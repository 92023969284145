"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var LoadingContainer_1 = require("../../../../../../../common/LoadingContainer");
var DebtAdviseAgainstModalForm_1 = require("./DebtAdviseAgainstModalForm");
var DebtAdviseAgainstModalContent = function (props) {
    return (React.createElement("section", null,
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "modal-header flex-row" },
                React.createElement("h3", null, "Advise Against Litigation"),
                React.createElement("div", { className: "flex-row" },
                    React.createElement("div", { className: "isPointer", onClick: function () {
                            props.handleAdviseAgainstModal();
                        } },
                        React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'times'] }))))),
        React.createElement("div", { className: "container" },
            'Leave a comment for the customer on why you believe this debt should not go through to litigation.',
            React.createElement(LoadingContainer_1.default, { render: function (config) {
                    return React.createElement(DebtAdviseAgainstModalForm_1.default, __assign({}, config, props));
                } }))));
};
exports.default = DebtAdviseAgainstModalContent;
