"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var RadioButton_1 = require("../../../../common/RadioButton");
var TextField_1 = require("../../../../common/TextField");
var getState_1 = require("../../../../../utils/getState");
var toPrecision6_1 = require("../../../../../utils/toPrecision6");
var InterestRate = function (props) {
    var _a = __read(React.useState('stat'), 2), selected = _a[0], setSelected = _a[1];
    React.useEffect(function () {
        if (props.value !== 0 &&
            props.value !== 8 &&
            props.value !== 8.0 &&
            props.value !== 8 + (0, getState_1.default)().interest_rate_boe) {
            setSelected('custom');
        }
    }, []);
    var handleRadioChange = function (value, option) {
        props.setFieldValue('interest_rate', parseFloat(value));
        setSelected(option);
    };
    return (React.createElement("div", null,
        React.createElement("label", { className: 'flex-row' },
            React.createElement(RadioButton_1.default, { checked: props.value === 0.0, value: 0.0, name: 'none', handleRadioChange: handleRadioChange }),
            "None"),
        props.protocol === 1 ? (React.createElement("label", { className: 'flex-row' },
            React.createElement(RadioButton_1.default, { checked: props.value === 8.0, value: 8.0, name: 'stat', handleRadioChange: handleRadioChange }),
            "Statutory (8%)")) : (React.createElement("label", { className: 'flex-row' },
            React.createElement(RadioButton_1.default, { checked: props.value === 8 + (0, getState_1.default)().interest_rate_boe, value: 8.0 + (0, getState_1.default)().interest_rate_boe, name: 'stat+boe', handleRadioChange: handleRadioChange }),
            "Statutory + BOE (",
            (8 + (0, getState_1.default)().interest_rate_boe).toString(),
            ")%")),
        React.createElement("label", { className: 'flex-row' },
            React.createElement(RadioButton_1.default, { checked: selected === 'custom', value: 0.1, name: 'custom', handleRadioChange: handleRadioChange }),
            "Contractual"),
        React.createElement("div", { className: "interest-rate-input flex-row ".concat(selected === 'custom' ? '' : 'hidden_field'), style: { transitionDuration: '0.4s' } },
            React.createElement(TextField_1.default, { type: "number", name: "interest_rate", value: props.value, validator: props.validator, onChange: function (value) {
                    props.setFieldValue('interest_rate', parseFloat((0, toPrecision6_1.default)(value)));
                }, disabled: false }),
            React.createElement("span", null, "%"))));
};
exports.default = InterestRate;
