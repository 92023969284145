"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var toPrecision6 = function (value) {
    //first check length
    var interestLength = value.length;
    var doesInterestHaveDecimal = value.includes('.');
    //Does interest have a decimal and be length 6
    if (interestLength >= 6 && doesInterestHaveDecimal) {
        //Make sub string
        var interestSubStringArray = value.split('.');
        //If interest substring 1 is larger
        if (interestSubStringArray[0].length >= 5 && value.length >= 7) {
            var lastWholeNumber = interestSubStringArray[0].charAt(interestSubStringArray[0].length - 1);
            var wholeNumber = interestSubStringArray[0].slice(0, 4);
            var decimalNumber = lastWholeNumber + interestSubStringArray[1].slice(0, 1);
            return wholeNumber + '.' + decimalNumber;
        }
        //If interest substring 2 is larger
        else if (interestSubStringArray[0].length <= 4 && interestSubStringArray[1].length >= 3) {
            // If length is 0
            if (interestSubStringArray[0].length === 0) {
                var wholeNumber = '0';
                var decimalNumber = interestSubStringArray[1].slice(0, 5);
                return wholeNumber + '.' + decimalNumber;
            }
            // If length is 1
            else if (interestSubStringArray[0].length === 1) {
                var decimalNumber = interestSubStringArray[1].slice(0, 5);
                return interestSubStringArray[0] + '.' + decimalNumber;
            }
            // If length is 2
            else if (interestSubStringArray[0].length === 2) {
                var decimalNumber = interestSubStringArray[1].slice(0, 4);
                return interestSubStringArray[0] + '.' + decimalNumber;
            }
            // If length is 3
            else if (interestSubStringArray[0].length === 3) {
                var decimalNumber = interestSubStringArray[1].slice(0, 3);
                return interestSubStringArray[0] + '.' + decimalNumber;
            }
            else {
                var decimalNumber = interestSubStringArray[1].slice(0, 2);
                return interestSubStringArray[0] + '.' + decimalNumber;
            }
        }
        else
            return interestSubStringArray[0] + '.' + interestSubStringArray[1];
    }
    else if (interestLength >= 6 && !doesInterestHaveDecimal) {
        return value.slice(0, 5);
    }
    else
        return value;
};
exports.default = toPrecision6;
