"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var EditDebtOriginalAmount = function (props) {
    var _a = __read(React.useState(''), 2), previousPounds = _a[0], setPreviousPounds = _a[1];
    var convertPounds = function (num) {
        var newPounds = num.replace('.', '');
        if (newPounds === '') {
            return previousPounds;
        }
        setPreviousPounds(newPounds);
        return newPounds;
    };
    var convertPence = function (num, places) {
        var number = String(num);
        var newPence = Number.parseInt(number, 10);
        if (number.length === 3) {
            return number.substring(1);
        }
        if (number.length < 2) {
            return number.padStart(places, '0');
        }
        if (newPence >= 100) {
            return '00';
        }
        return number;
    };
    return (React.createElement("div", { className: "form-input form-input--with-tag" },
        React.createElement("label", { className: "" }, "Amount"),
        React.createElement("div", { className: "flex-row form-input--with-tag-contents" },
            React.createElement("div", { className: "form-input-unit" }, "\u00A3"),
            React.createElement(formik_1.Field, { type: "number", value: props.pounds, min: 25, max: 20000, name: "original_amount", step: 1, onChange: function (e) {
                    props.setPounds(convertPounds(e.target.value));
                }, disabled: props.disabled }),
            React.createElement("div", { className: "form-input-unit" }, "."),
            React.createElement(formik_1.Field, { type: "number", max: 100, value: convertPence(props.pence, 2), name: "original_amount", step: 1, onChange: function (e) {
                    var newPence = Number.parseInt(e.target.value, 10);
                    if (newPence >= 100) {
                        props.setPence(convertPence(newPence, 2));
                    }
                    else if (newPence <= -1) {
                        props.setPence(convertPence(99, 2));
                    }
                    else
                        props.setPence(convertPence(newPence, 2));
                }, disabled: props.disabled }))));
};
exports.default = EditDebtOriginalAmount;
