"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var DebtorSelected = function (props) {
    return (React.createElement("div", { onClick: function () {
            props.setSelectedDebtor({ id: null });
            props.setDebtor(props.setFieldValue, props.debtor, props.values);
        }, className: "debtor-selected-container ".concat(props.debtor.id === null ? 'debtor-selected-container-hidden' : 'debtor-selected-container-visible', " flex-column") },
        React.createElement("div", { className: "debtor-selected-contents" }, "Debtor Selected"),
        React.createElement("div", { className: "debtor-selected-button" }, "Remove")));
};
exports.default = DebtorSelected;
