"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var DebtWhatsNext = function (props) {
    var setClassName = function (array1, array2, className) {
        if (array1.indexOf(props.status) === -1 && array2.indexOf(props.status) === -1) {
            return className;
        }
    };
    var checkStatus = function (array1, array2) {
        if (array1.indexOf(props.status) !== -1) {
            return;
        }
        if (array2.indexOf(props.status) !== -1) {
            return React.createElement("span", { className: "status-identifier" }, " - In Progress ");
        }
        return React.createElement("span", { className: "status-identifier" }, " - Done ");
    };
    return (React.createElement("div", { className: "whats-next-stage-container ".concat(setClassName(props.notInProgress, props.isInProgress, 'done')) },
        React.createElement("span", null,
            React.createElement("strong", null, props.header),
            checkStatus(props.notInProgress, props.isInProgress)),
        React.createElement("div", { className: "content" }, props.children)));
};
exports.default = DebtWhatsNext;
