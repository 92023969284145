"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var DebtorEditWarning = function (props) {
    return (React.createElement("div", { className: "banner-container banner-orange" },
        React.createElement("div", { className: "banner-content flex-row" },
            React.createElement("div", { className: "banner-left-icon" },
                React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'exclamation-triangle'] })),
            React.createElement("div", { className: "banner-text flex-column" },
                props.debts_count > 0 && props.active_debts_count === 0
                    ? "This debtor has ".concat(props.debts_count, " debts.  Editing this debtor will have an effect on these debts.")
                    : null,
                props.active_debts_count > 0
                    ? "This debtor has ".concat(props.active_debts_count, " active debts.  Editing this debtor could cause issues with recovering these debts.")
                    : null))));
};
exports.default = DebtorEditWarning;
