"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var getState_1 = require("../../../../utils/getState");
var CompanyDetails = function (props) {
    return (React.createElement("div", { className: "company-details-container flex-row-wrap site-contents-container" },
        React.createElement("div", null,
            React.createElement("div", { className: "form-panel" },
                React.createElement("div", { className: "form-input" },
                    React.createElement("label", { htmlFor: "name" }, "Company name"),
                    React.createElement("input", { value: props.data.name, onChange: function (e) { return (props.data.name = e.target.value); }, disabled: true })),
                React.createElement("div", { className: "form-input" },
                    React.createElement("label", null, "Phone Number"),
                    React.createElement("input", { value: props.data.details.phone, disabled: true }),
                    props.data.details.companies_house_number !== null && (0, getState_1.default)().user.role !== 'customer' ? (React.createElement("span", null,
                        React.createElement("label", null, "Companies House Number"),
                        React.createElement("input", { value: props.data.details.companies_house_number }))) : null)),
            React.createElement("div", { className: "form-panel form-panel-coloured" },
                React.createElement("label", { className: "form-panel-label" }, "Address"),
                React.createElement("div", { className: "form-input" },
                    React.createElement("label", null, "Address Line 1"),
                    React.createElement("input", { value: props.data.details.address_line_1, disabled: true })),
                props.data.details.address_line_2 !== '' ? (React.createElement("div", { className: "form-input" },
                    React.createElement("label", null, "Address Line 2"),
                    React.createElement("input", { value: props.data.details.address_line_2, disabled: true }))) : null,
                React.createElement("div", { className: "form-input" },
                    React.createElement("label", null, "Town/City"),
                    React.createElement("input", { value: props.data.details.address_town, disabled: true })),
                React.createElement("div", { className: "form-input" },
                    React.createElement("label", null, "County"),
                    React.createElement("input", { value: props.data.details.address_county, disabled: true })),
                React.createElement("div", { className: "form-input" },
                    React.createElement("label", null, "Postcode"),
                    React.createElement("input", { value: props.data.details.address_postcode, disabled: true })))),
        React.createElement("div", { className: "form-panel form-panel-coloured" },
            React.createElement("label", { className: "form-panel-label", htmlFor: "debts_used_report" }, "Debts Overview"),
            React.createElement("div", { className: "form-input" },
                React.createElement("label", null, "Active Debts"),
                React.createElement("input", { value: props !== undefined
                        ? props.data.debts_overview !== undefined
                            ? props.data.debts_overview.active_debts_count
                            : 0
                        : 0, disabled: true })),
            React.createElement("div", { className: "form-input form-input--with-tag" },
                React.createElement("label", null, "Debts created"),
                React.createElement("div", { className: "flex-row form-input--with-tag-contents" },
                    React.createElement("input", { value: props !== undefined
                            ? props.data.debts_overview !== undefined
                                ? props.data.debts_overview.debts_created_this_period
                                : 0
                            : 0, disabled: true }),
                    React.createElement("div", { className: "form-input-tag" },
                        "This ",
                        props.data.debts_overview.debts_creatable_period))),
            React.createElement("div", { className: "form-input form-input--with-tag" },
                React.createElement("label", null, "Debts creatable"),
                React.createElement("div", { className: "flex-row form-input--with-tag-contents" },
                    React.createElement("input", { value: props !== undefined
                            ? props.data.debts_overview !== undefined
                                ? props.data.debts_overview.debts_currently_creatable
                                : 0
                            : 0, disabled: true }),
                    React.createElement("div", { className: "form-input-tag" },
                        "This ",
                        props.data.debts_overview.debts_creatable_period))),
            React.createElement("div", { className: "form-input form-input--with-tag" },
                React.createElement("label", null, "Max debts"),
                React.createElement("div", { className: "flex-row form-input--with-tag-contents" },
                    React.createElement("input", { value: props !== undefined
                            ? props.data.debts_overview !== undefined
                                ? props.data.debts_overview.debts_creatable_per_period
                                : 0
                            : 0, disabled: true }),
                    React.createElement("div", { className: "form-input-tag" },
                        "Per ",
                        props.data.debts_overview.debts_creatable_period))),
            React.createElement("div", { className: "form-input form-input--with-tag" },
                React.createElement("label", null, "Period end"),
                React.createElement("div", { className: "flex-row form-input--with-tag-contents" },
                    React.createElement("input", { value: props !== undefined
                            ? props.data.debts_overview !== undefined
                                ? new Date(props.data.debts_overview.end_of_period).toLocaleDateString('en-GB', {
                                    timeZone: 'UTC',
                                })
                                : 0
                            : 0, disabled: true }),
                    React.createElement("div", { className: "form-input-tag" }, props.data.debts_overview.debts_creatable_period))),
            React.createElement("div", { className: "form-row" }))));
};
exports.default = CompanyDetails;
