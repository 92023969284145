"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var core_1 = require("@rradar/core");
var SiteFooter = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(core_1.SiteFooter, { termsAndConditionsFunction: props.setModalOpen, footerColumns: [
                {
                    header: 'Services',
                    id: 'services',
                    contents: [
                        {
                            title: 'About rradar',
                            id: 'rradar',
                            href: 'https://www.rradar.com/about',
                        },
                        {
                            title: 'My rradar',
                            id: 'dashboard',
                            href: 'https://my.rradar.com',
                        },
                        {
                            title: 'rradarstation',
                            id: 'station',
                            href: 'https://www.rradar.com/rradarstation',
                        },
                        {
                            title: 'rradargrace',
                            id: 'grace',
                            href: 'https://www.rradar.com/rradargrace',
                        },
                        {
                            title: 'rradarwhistlebox',
                            id: 'whistlebox',
                            href: 'https://www.rradar.com/whistlebox',
                        },
                        {
                            title: 'rradarreport',
                            id: 'report',
                            href: "https://www.rradar.com/rradarreport'",
                        },
                    ],
                },
                {
                    header: 'Contact Information',
                    id: 'contact_info',
                    contents: [
                        {
                            strong: '03300 414 996',
                            title: 'General Inquiries:',
                            id: 'general',
                        },
                        {
                            strong: 'contactus@rradar.com',
                            title: 'Email:',
                            id: 'contact',
                        },
                    ],
                },
            ] })));
};
exports.default = SiteFooter;
