"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var DebtorTile = function (props) {
    return (React.createElement("div", { className: "debtor_tile flex-row ".concat(props.debtor.id === props.values.debtor_id ? 'active_debtor' : 'inactive_debtor'), onClick: function () { return props.onClick(props.debtor); }, key: props.debtor.id },
        props.debtor.protocol_id === 1 ? (React.createElement("div", { className: "protocol_identity flex-column" },
            React.createElement("i", { className: "fas fa-user icon" }),
            React.createElement("p", null, "Individual"))) : (React.createElement("div", { className: "protocol_identity flex-column" },
            React.createElement("i", { className: "fas fa-building icon" }),
            React.createElement("p", null, "Business"))),
        React.createElement("div", { className: "debtor_details flex-column" },
            React.createElement("span", { className: "flex-row" },
                React.createElement("strong", null, props.debtor.name)),
            props.debtor.protocol_id === 2 ? (React.createElement("span", { className: "flex-row" },
                React.createElement("p", null, props.debtor.merge_variables.representative_name))) : null,
            React.createElement("span", { className: "flex-row" },
                React.createElement("p", null, props.debtor.email)),
            React.createElement("span", { className: "flex-row" },
                React.createElement("div", { className: "address" },
                    React.createElement("p", null, props.debtor.address_1),
                    props.debtor.address_2 !== null && props.debtor.address_2 !== '' ? React.createElement("p", null, props.debtor.address_2) : null,
                    React.createElement("p", null, props.debtor.city),
                    React.createElement("p", null, props.debtor.post_code))))));
};
exports.default = DebtorTile;
