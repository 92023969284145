"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_joyride_1 = require("react-joyride");
var recoverInterface_1 = require("../../../utils/recoverInterface");
var RecoverJoyride = function () {
    var _a = __read(React.useState([
        {
            target: '.createDebt',
            title: 'Recover a new debt',
            content: 'This will start the debt recovery process and where you will input the relevant details of the debt you want to recover into the system.',
            disableBeacon: true,
        },
        {
            target: '.active-debts',
            title: 'Check Active Debts',
            content: 'This will display a table of all the inputted debts that are currently active and/or need of your attention to progress with the debt recovery process.',
        },
        {
            target: '.closed-debts',
            title: 'Check Closed Debts',
            content: 'This will display a table of all your debts that have been recovered, closed or declined.',
        },
        {
            target: '.guidance-link',
            title: 'Need Help?',
            content: 'The guidance section provides a range of resources for good credit management and debt recovery advice, templates and guides. These have been designed to help you managing your commercial debts more effectively and there is also useful FAQs for using the rradarrecover app',
        },
        {
            target: '.debtors-link',
            title: 'Debtors',
            content: 'Here you can view and edit any debtors you have in the recover system.',
        },
        {
            target: '.my-company-link',
            title: 'My Company',
            content: 'This is where information regarding your company is stored and where you can edit the general details of your rradarrecover account and set up bank details.',
        },
        {
            target: '.dashboard-link',
            title: 'The Dashboard',
            content: 'The Dashboard tab is where you will see an at-a-glance overview of the debts you are recovering and where you can navigate to the other features and pages within the app.',
        },
    ]), 1), steps = _a[0];
    var handleJoyrideCallback = function (data) {
        if (data.status === 'finished' || data.status === 'skipped') {
            (0, recoverInterface_1.default)().me().setJoyrideOff({});
        }
    };
    return (React.createElement(react_joyride_1.default, { steps: steps, continuous: true, run: true, showProgress: true, callback: handleJoyrideCallback, showSkipButton: true, disableScrolling: true, disableScrollParentFix: true, styles: {
            options: {
                arrowColor: '#f4f4f4',
                backgroundColor: '#f4f4f4',
                overlayColor: 'rgba(0, 0, 0, 0.7)',
                primaryColor: '#cc0000',
                textColor: '#333',
                zIndex: 1000,
            },
            tooltipTitle: {
                fontSize: 25,
            },
        } }));
};
exports.default = RecoverJoyride;
