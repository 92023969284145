"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var scrollToTop_1 = require("../../../../../utils/scrollToTop");
var AddDebtWizardPage1 = function (props) {
    (0, scrollToTop_1.default)();
    return (React.createElement("div", { className: "add-debt-wizard-page flex-column" },
        React.createElement("div", { className: "add-debt-wizard-form-contents" },
            React.createElement("div", { className: "add-debt-wizard-form-description" },
                React.createElement("h2", null, "Requirements"),
                React.createElement("strong", null, "We recommend gathering the following before you proceed:"),
                React.createElement("div", { className: "flex-column" },
                    React.createElement("ul", { className: "flex-column add-debt-wizard-form-description-list" },
                        React.createElement("li", null, "A digital copy of the original sales invoice."),
                        React.createElement("li", null, "The date on which you last requested payment."),
                        React.createElement("li", null, "The debtor's postal address."),
                        React.createElement("li", null, "The debtor's email address."),
                        React.createElement("li", null, "Original invoice amount.")),
                    React.createElement("p", null, "Please fill out all fields correctly to place the debt in our system. "),
                    React.createElement("p", null, "At the end of the process you have the option to submit the debt or save it for editing."),
                    React.createElement("p", null, "By clicking \"Next\", you are accepting our ",
                        React.createElement("strong", null,
                            React.createElement("a", { onClick: function () { return props.setTCModal(true); } }, "Terms and Conditions")))))),
        React.createElement("div", { className: "link_navigation flex-row" },
            React.createElement(react_router_dom_1.Link, { to: "/dashboard", className: "button nav-button-grey" }, "Previous"),
            React.createElement(react_router_dom_1.Link, { to: "/dashboard/debtwizard/page2", className: "button nav-button-grey", id: 'active' }, "Next"))));
};
exports.default = AddDebtWizardPage1;
