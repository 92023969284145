"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var Collapsible = function (props) {
    return (React.createElement("div", { className: "collapsible-container flex-column" },
        React.createElement("div", { className: "collapsible-header flex-row", onClick: function () {
                props.clickFunction();
            } },
            React.createElement("h2", { className: props.class }, props.header),
            React.createElement("div", { className: "line ".concat(!props.open ? 'expanded' : 'collapsed') }),
            React.createElement("div", { className: "collapsible-arrow" },
                React.createElement(react_fontawesome_1.FontAwesomeIcon, { className: "table-collapsible ".concat(props.open ? 'rotate' : 'rotatedown'), icon: ['fal', 'caret-up'], size: '2x' }))),
        React.createElement("div", { className: "collapsible-contents ".concat(props.open ? 'expanded' : 'collapsed') }, props.children)));
};
exports.default = Collapsible;
