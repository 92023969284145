"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessTypes = void 0;
var React = require("react");
var react_router_1 = require("react-router");
var getState_1 = require("../utils/getState");
var withRouter_1 = require("./hooks/withRouter");
var react_router_dom_1 = require("react-router-dom");
var Cookie = require("js-cookie");
var AccessTypes;
(function (AccessTypes) {
    AccessTypes[AccessTypes["Admin"] = 0] = "Admin";
    AccessTypes[AccessTypes["Triage"] = 1] = "Triage";
    AccessTypes[AccessTypes["Legal"] = 2] = "Legal";
})(AccessTypes = exports.AccessTypes || (exports.AccessTypes = {}));
var AuthenticatedRoute = function (_a) {
    var children = _a.children, accessTypes = _a.accessTypes, rest = __rest(_a, ["children", "accessTypes"]);
    var _b = __read(React.useState([]), 2), userAccessTypes = _b[0], setUserAccessTypes = _b[1];
    var _c = __read(React.useState(false), 2), loaded = _c[0], setLoaded = _c[1];
    var pathname = (0, react_router_dom_1.useLocation)().pathname;
    React.useEffect(function () {
        authenticate();
    }, []);
    var authenticate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var me, data, userAccessTypes_1, error_1, state;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch("".concat((0, getState_1.default)().api.endpoint, "/me"), {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: Cookie.get('sessionId'),
                            },
                        })];
                case 1:
                    me = _a.sent();
                    return [4 /*yield*/, me.json()];
                case 2:
                    data = _a.sent();
                    window.__PUBLIC_STATE__.user = data.me;
                    userAccessTypes_1 = [];
                    if (data.me.role === 'legal') {
                        userAccessTypes_1.push(AccessTypes.Legal);
                    }
                    if (data.me.role === 'triage') {
                        userAccessTypes_1.push(AccessTypes.Triage);
                    }
                    if (data.me.role === 'admin') {
                        userAccessTypes_1.push(AccessTypes.Admin);
                    }
                    setUserAccessTypes(userAccessTypes_1);
                    setLoaded(true);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    state = pathname;
                    window.location.href = "".concat((0, getState_1.default)().api.authentication_url, "&redirect_url_uid=").concat((0, getState_1.default)().api.redirect_uid, "&state=").concat(encodeURIComponent(state));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var hasMatchingAccessType = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, userAccessTypes.some(function (accessType) { return accessTypes.includes(accessType); })];
        });
    }); };
    var routeComponent = function () {
        var isAuthenticated = hasMatchingAccessType();
        return isAuthenticated ? children : React.createElement(react_router_1.Navigate, { replace: true, to: '/auth' });
    };
    return routeComponent();
};
exports.default = (0, withRouter_1.default)(AuthenticatedRoute);
