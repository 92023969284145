"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        var location = (0, react_router_dom_1.useLocation)();
        var navigate = (0, react_router_dom_1.useNavigate)();
        var params = (0, react_router_dom_1.useParams)();
        return React.createElement(Component, __assign({}, props, { router: { location: location, navigate: navigate, params: params } }));
    }
    return ComponentWithRouterProp;
}
exports.default = withRouter;
