"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var DebtsTableContainer_1 = require("../../../components/Tables/debts/DebtsTableContainer");
var permissions_1 = require("../../../../app/utils/permissions");
var getState_1 = require("../../../utils/getState");
var core_1 = require("@rradar/core");
var ListDebts = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "dashboard-body" },
                React.createElement("div", { className: "dashboard-header" }, (0, permissions_1.hasPermission)((0, getState_1.default)().user, ['admin']) ? (React.createElement(core_1.Heading1, { title: "Super Admin Dashboard" })) : (React.createElement(core_1.Heading1, { title: "Debts" }))),
                React.createElement(DebtsTableContainer_1.default, null)))));
};
exports.default = ListDebts;
