"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var permissions_1 = require("../../../../../app/utils/permissions");
var getState_1 = require("../../../../utils/getState");
var AdminDashboardActions_1 = require("./components/AdminDashboardActions");
var AdminDashboard = function () {
    React.useEffect(function () {
        if ((0, getState_1.default)().user.role === 'admin') {
            window.location.href = '/admin/debts';
        }
    }, []);
    return (React.createElement("div", { className: "container" },
        React.createElement("div", { className: "dashboard-body" },
            React.createElement("div", { className: "dashboard-header" },
                React.createElement("h1", null, "Dashboard")),
            (0, permissions_1.hasPermission)((0, getState_1.default)().user, ['legal']) ? React.createElement(AdminDashboardActions_1.default, null) : null)));
};
exports.default = AdminDashboard;
