"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var formatMoney_1 = require("./formatMoney");
var dateFormatter_1 = require("./dateFormatter");
var formatDebtStatus_1 = require("./formatDebtStatus");
var truncate_1 = require("lodash/truncate");
var getState_1 = require("./getState");
exports.default = (function (type, tablefilters) {
    switch (type) {
        case 'debts': {
            return [
                {
                    property: 'reference',
                    heading: 'Reference',
                    value: function (item) { return item.reference; },
                },
                {
                    property: 'debtor[`name`]',
                    heading: 'Name',
                    value: function (item) { return item.debtor.name; },
                },
                {
                    property: 'invoice_due_date',
                    heading: 'Invoice Due By',
                    value: function (item) { return (0, dateFormatter_1.default)(item.invoice_due_date); },
                    sortable: true,
                },
                {
                    property: 'invoice_reference',
                    heading: 'Your Reference',
                    value: function (item) { return item.invoice_reference; },
                },
                {
                    property: 'status_tracker',
                    heading: 'Status',
                    value: function (item) { return (0, formatDebtStatus_1.default)(item.status_tracker); },
                    filters: tablefilters,
                },
                {
                    property: 'original_amount',
                    heading: 'Amount',
                    value: function (item) { return (0, formatMoney_1.default)(item.original_amount); },
                    sortable: true,
                },
                {
                    property: 'id',
                    heading: '',
                    value: function (item) { return 'View'; },
                    onClick: {
                        type: 'button',
                        handler: function (item) {
                            window.location.href =
                                (0, getState_1.default)().user.role === 'customer'
                                    ? "/dashboard/debts/".concat(item.reference)
                                    : "/admin/debts/".concat(item.reference);
                        },
                    },
                },
            ];
        }
        case 'users': {
            return [
                {
                    property: 'id',
                    heading: 'Recover ID',
                    value: function (item) { return item.id; },
                    sortable: true,
                },
                {
                    property: 'accounts_id',
                    heading: 'Accounts ID',
                    value: function (item) { return item.accounts_id; },
                    sortable: true,
                },
                {
                    property: 'name',
                    heading: 'Name',
                    value: function (item) { return item.name; },
                    sortable: true,
                },
                {
                    property: 'email',
                    heading: 'Email',
                    value: function (item) { return item.email; },
                },
                {
                    property: 'role',
                    heading: 'Role',
                    value: function (item) { return item.role; },
                },
                {
                    property: 'id',
                    heading: '',
                    value: function (item) { return 'View'; },
                    onClick: {
                        type: 'button',
                        handler: function (item) {
                            window.location.href = "/admin/users/".concat(item.id);
                        },
                    },
                },
            ];
        }
        case 'companies': {
            return [
                {
                    property: 'id',
                    heading: 'Recover ID',
                    value: function (item) { return item.id; },
                    sortable: true,
                },
                {
                    property: 'accounts_id',
                    heading: 'Accounts ID',
                    value: function (item) { return item.accounts_id; },
                    sortable: true,
                },
                {
                    property: 'name',
                    heading: 'Name',
                    value: function (item) { return item.name; },
                    sortable: true,
                },
                {
                    property: 'id',
                    heading: '',
                    value: function (item) { return 'View'; },
                    onClick: {
                        type: 'button',
                        handler: function (item) {
                            window.location.href = "/admin/companies/".concat(item.id);
                        },
                    },
                },
            ];
        }
        case 'quick_texts': {
            return [
                {
                    property: 'id',
                    heading: 'ID',
                    value: function (item) { return item.id; },
                    sortable: true,
                },
                {
                    property: 'action',
                    heading: 'Action',
                    value: function (item) { return item.action; },
                    filters: [
                        { heading: 'Revert', value: 'revert' },
                        { heading: 'Pause', value: 'pause' },
                        { heading: 'Resume', value: 'resume' },
                        { heading: 'Decline', value: 'decline' },
                    ],
                },
                {
                    property: 'key',
                    heading: 'Key',
                    value: function (item) { return item.key; },
                },
                {
                    property: 'body',
                    heading: 'Body',
                    value: function (item) {
                        return (0, truncate_1.default)(item.body, {
                            length: 50,
                            omission: '...',
                        });
                    },
                },
                {
                    property: 'id',
                    heading: '',
                    value: function (item) { return 'View'; },
                    onClick: {
                        type: 'button',
                        handler: function (item) {
                            window.location.href = "/admin/quick_texts/".concat(item.id);
                        },
                    },
                },
            ];
        }
        case 'debtors': {
            return [
                {
                    property: 'name',
                    heading: 'Name',
                    value: function (item) { return item.name; },
                },
                {
                    property: 'protocol_id',
                    heading: 'Protocol',
                    value: function (item) {
                        return item.protocol_id === 1 ? 'Individual/Sole Trader' : 'Business (Or other organisation)';
                    },
                    sortable: true,
                },
                {
                    property: 'id',
                    heading: '',
                    value: function (item) { return 'View'; },
                    onClick: {
                        type: 'button',
                        handler: function (item) {
                            window.location.href = "/dashboard/debtors/".concat(item.id);
                        },
                    },
                },
            ];
        }
    }
});
