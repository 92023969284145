"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_html_parser_1 = require("react-html-parser");
var dateTimeAgo_1 = require("../../../../../../../utils/dateTimeAgo");
function NoteTile(props) {
    var _a = __read(React.useState(false), 2), showMore = _a[0], setShowMore = _a[1];
    var hasReason = props.note && props.note.note !== null && props.note.note.data !== null && props.note.note.data.reason;
    return (React.createElement("div", { className: 'demand-list-item', key: props.note.note.id },
        React.createElement("div", { className: "flex-row" },
            React.createElement("div", { className: 'demand-list-icon', key: props.note.note.id },
                React.createElement("h3", { className: "icon" }, props.icon)),
            React.createElement("div", { className: "demand-list-item-info" },
                React.createElement("h3", null, props.description),
                React.createElement("i", { className: "demand-list-item-timeago" },
                    new Date(props.note.note.created_at).toLocaleDateString('en-GB', {
                        timeZone: 'UTC',
                    }),
                    ' - ',
                    (0, dateTimeAgo_1.default)(props.note.note.created_at),
                    " ago")),
            hasReason && (React.createElement("div", { className: "demand-list-item--action" },
                React.createElement("div", { onClick: function () { return setShowMore(!showMore); } }, showMore ? 'Hide' : 'Show')))),
        hasReason && showMore && (React.createElement("div", { className: "demand-list-item--content" }, (0, react_html_parser_1.default)(props.note.note.data.reason)))));
}
exports.default = NoteTile;
