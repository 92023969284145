"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classnames_1 = require("classnames");
var formatMoney_1 = require("../../../../../../utils/formatMoney");
var getState_1 = require("../../../../../../utils/getState");
var DebtAmount = /** @class */ (function (_super) {
    __extends(DebtAmount, _super);
    function DebtAmount() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getBackgroundColor = function (status) {
            var colour = '';
            if ((0, getState_1.default)().user.role === 'customer') {
                if (status === 'unsubmitted' || status === 'reverted' || status === 'stage_1_ended' || status === 'overdue') {
                    colour = 'orange';
                }
                else
                    colour = 'grey';
            }
            else if ((0, getState_1.default)().user.role !== 'customer') {
                if (status === 'submitted' || status === 'stage_2_authorised' || status === 'litigation_requested') {
                    colour = 'orange';
                }
                else
                    colour = 'grey';
            }
            else
                colour = 'grey';
            return colour;
        };
        _this.render = function () {
            var _a;
            return (React.createElement("div", { className: (0, classnames_1.default)((_a = {
                        'debt-amount': true,
                        'flex-column': true
                    },
                    _a["status-background-".concat(_this.getBackgroundColor(_this.props.status))] = true,
                    _a)) },
                React.createElement("div", { className: "debt-amount-reference" },
                    "Reference - ",
                    _this.props.reference),
                React.createElement("div", { className: "debt-amount-value" }, (0, formatMoney_1.default)(parseFloat(_this.props.amount)))));
        };
        return _this;
    }
    return DebtAmount;
}(React.Component));
exports.default = DebtAmount;
