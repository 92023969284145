"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_datepicker_1 = require("react-datepicker");
var formik_1 = require("formik");
var classnames_1 = require("classnames");
var isFieldRequired_1 = require("../../../../../utils/isFieldRequired");
var Hint_1 = require("../../../../common/Hint");
var DatePicker = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("label", { htmlFor: props.name, className: (0, classnames_1.default)({
                required: (0, isFieldRequired_1.default)(props.name, props.validator),
            }) },
            ' ',
            props.label,
            props.hint ? React.createElement(Hint_1.default, { id: "" }, props.hint) : null),
        React.createElement(formik_1.Field, { className: "invoice-input", autoComplete: "off", render: function (_a) {
                var field = _a.field;
                return (React.createElement(react_datepicker_1.default, __assign({}, field, { selected: props.value, onChange: function (value) { return props.setFieldValue(props.name, value); }, required: (0, isFieldRequired_1.default)(props.name, props.validator), popperPlacement: "right", popperModifiers: {
                        offset: {
                            enabled: true,
                            offset: '5px, 10px',
                        },
                        preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport',
                        },
                    }, dateFormat: "dd/MM/yyyy", placeholderText: "Click to select a date", todayButton: 'Today', minDate: new Date(props.value), maxDate: new Date(), autoComplete: "off" })));
            }, name: props.name })));
};
exports.default = DatePicker;
