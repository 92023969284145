"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var CancelButton_1 = require("./components/CancelButton");
var SecondaryButton_1 = require("./components/SecondaryButton");
var PrimaryButton_1 = require("./components/PrimaryButton");
var ButtonContainer = function (props) {
    var secondaryButtonRender = function () {
        if (props.secondaryButtons) {
            if (props.secondaryButtons.length > 0) {
                return props.secondaryButtons.map(function (_a) {
                    var method = _a.method, text = _a.text, disabled = _a.disabled;
                    return (React.createElement(SecondaryButton_1.default, { key: text, secondaryFunction: method, secondaryDisabled: disabled, secondaryText: text }));
                });
            }
            return null;
        }
        return null;
    };
    return (React.createElement("div", { className: "flex-row-wrap-reverse button-area-buttons" },
        React.createElement(CancelButton_1.default, { cancelFunction: props.cancelFunction, cancelDisabled: props.cancelDisabled, cancelText: props.cancelText }),
        secondaryButtonRender(),
        React.createElement(PrimaryButton_1.default, { primaryFunction: props.primaryFunction, primaryDisabled: props.primaryDisabled, primaryText: props.primaryText })));
};
exports.default = ButtonContainer;
