"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
// Wants replacing
var LoadingSpinner = function (props) {
    return (React.createElement("div", { className: "loading-spinner-overlay" },
        React.createElement("div", { className: "spinner-container flex-column" },
            React.createElement("i", { className: "fas fa-cog fa-spin" }),
            'handleIsLoading' in props ? (React.createElement("button", { type: "button", className: "red-button button", onClick: function () {
                    props.controller.abort();
                    props.handleIsLoading(false);
                } }, "Cancel")) : null)));
};
exports.default = LoadingSpinner;
