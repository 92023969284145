"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var TextField_1 = require("../../../../../common/TextField");
var NameField_1 = require("../NameField/NameField");
var ProtocolRadio_1 = require("../../ProtocolRadio/ProtocolRadio");
var AddDebtWizardPage8Debtor = function (props) {
    return (React.createElement("div", { className: "form-panel flex-column" },
        React.createElement("label", { className: "required" }, "Jurisdiction"),
        React.createElement(ProtocolRadio_1.default, { value: props.values.jurisdiction, setFieldValue: props.setFieldValue, validator: props.validator }),
        React.createElement(NameField_1.default, { values: props.values, validator: props.validator, setFieldValue: props.setFieldValue }),
        React.createElement(TextField_1.default, { name: "trading_name", value: props.values.trading_name, validator: props.validator, label: 'Trading name', placeholder: 'Joe Bloggs Limited', onChange: function (value) {
                props.setFieldValue('trading_name', value);
            }, disabled: props.values.debtor_id !== null }),
        React.createElement(formik_1.ErrorMessage, { name: "trading_name" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
        React.createElement(TextField_1.default, { name: "companies_house_number", value: props.values.companies_house_number, validator: props.validator, label: 'Companies house number', placeholder: '07738271', onChange: function (value) {
                props.setFieldValue('companies_house_number', value);
            }, disabled: props.values.debtor_id !== null }),
        React.createElement(formik_1.ErrorMessage, { name: "companies_house_number" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
        React.createElement(TextField_1.default, { name: "email", value: props.values.email, validator: props.validator, label: 'Debtor email', placeholder: 'name@example.co.uk', onChange: function (value) {
                props.setFieldValue('email', value);
            }, disabled: props.values.debtor_id !== null }),
        React.createElement(formik_1.ErrorMessage, { name: "email" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
        React.createElement("label", null, "Address"),
        React.createElement(TextField_1.default, { name: "address_1", value: props.values.address_1, validator: props.validator, placeholder: 'Address line 1', onChange: function (value) {
                props.setFieldValue('address_1', value);
            }, disabled: props.values.debtor_id !== null }),
        React.createElement(TextField_1.default, { name: "address_2", value: props.values.address_2, validator: props.validator, placeholder: 'Address line 2', onChange: function (value) {
                props.setFieldValue('address_2', value);
            }, disabled: props.values.debtor_id !== null }),
        React.createElement(TextField_1.default, { name: "city", value: props.values.city, validator: props.validator, placeholder: 'City', onChange: function (value) {
                props.setFieldValue('city', value);
            }, disabled: props.values.debtor_id !== null }),
        React.createElement(TextField_1.default, { name: "post_code", value: props.values.post_code, validator: props.validator, placeholder: 'Postcode', onChange: function (value) {
                props.setFieldValue('post_code', value);
            }, disabled: props.values.debtor_id !== null }),
        React.createElement(formik_1.ErrorMessage, { name: "address_1" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
        React.createElement(formik_1.ErrorMessage, { name: "address_w" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
        React.createElement(formik_1.ErrorMessage, { name: "city" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
        React.createElement(formik_1.ErrorMessage, { name: "post_code" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); })));
};
exports.default = AddDebtWizardPage8Debtor;
