"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var fontawesome_svg_core_1 = require("@fortawesome/fontawesome-svg-core");
var faCalendarPlus_1 = require("@fortawesome/pro-light-svg-icons/faCalendarPlus");
var faPaperPlane_1 = require("@fortawesome/pro-light-svg-icons/faPaperPlane");
var faExclamationTriangle_1 = require("@fortawesome/pro-light-svg-icons/faExclamationTriangle");
var faPencilAlt_1 = require("@fortawesome/pro-light-svg-icons/faPencilAlt");
var faBackward_1 = require("@fortawesome/pro-light-svg-icons/faBackward");
var faBan_1 = require("@fortawesome/pro-light-svg-icons/faBan");
var faComments_1 = require("@fortawesome/pro-light-svg-icons/faComments");
var faFileAlt_1 = require("@fortawesome/pro-light-svg-icons/faFileAlt");
var faHandshake_1 = require("@fortawesome/pro-light-svg-icons/faHandshake");
var faTrashAlt_1 = require("@fortawesome/pro-light-svg-icons/faTrashAlt");
var faForward_1 = require("@fortawesome/pro-light-svg-icons/faForward");
var faPeopleCarry_1 = require("@fortawesome/pro-light-svg-icons/faPeopleCarry");
var faCheckDouble_1 = require("@fortawesome/pro-light-svg-icons/faCheckDouble");
var faCaretUp_1 = require("@fortawesome/pro-light-svg-icons/faCaretUp");
var faFilePdf_1 = require("@fortawesome/pro-light-svg-icons/faFilePdf");
var faPlay_1 = require("@fortawesome/pro-light-svg-icons/faPlay");
var faPause_1 = require("@fortawesome/pro-light-svg-icons/faPause");
var faStop_1 = require("@fortawesome/pro-light-svg-icons/faStop");
var faEye_1 = require("@fortawesome/pro-light-svg-icons/faEye");
var faCheck_1 = require("@fortawesome/pro-light-svg-icons/faCheck");
var faSearch_1 = require("@fortawesome/pro-light-svg-icons/faSearch");
var faAlignLeft_1 = require("@fortawesome/pro-light-svg-icons/faAlignLeft");
var faCalendar_1 = require("@fortawesome/pro-light-svg-icons/faCalendar");
var faMapMarkerAlt_1 = require("@fortawesome/pro-light-svg-icons/faMapMarkerAlt");
var faEnvelopeOpenText_1 = require("@fortawesome/pro-light-svg-icons/faEnvelopeOpenText");
var faCog_1 = require("@fortawesome/pro-light-svg-icons/faCog");
var faBars_1 = require("@fortawesome/pro-light-svg-icons/faBars");
var faTimes_1 = require("@fortawesome/pro-light-svg-icons/faTimes");
var faEllipsisV_1 = require("@fortawesome/pro-light-svg-icons/faEllipsisV");
var faIdCard_1 = require("@fortawesome/pro-light-svg-icons/faIdCard");
var faFileUpload_1 = require("@fortawesome/pro-light-svg-icons/faFileUpload");
var faBell_1 = require("@fortawesome/pro-light-svg-icons/faBell");
var faArrowToRight_1 = require("@fortawesome/pro-light-svg-icons/faArrowToRight");
var faMagic_1 = require("@fortawesome/pro-light-svg-icons/faMagic");
var faFileDownload_1 = require("@fortawesome/pro-light-svg-icons/faFileDownload");
var faCubes_1 = require("@fortawesome/pro-light-svg-icons/faCubes");
var faCross_1 = require("@fortawesome/pro-light-svg-icons/faCross");
var faEdit_1 = require("@fortawesome/pro-light-svg-icons/faEdit");
fontawesome_svg_core_1.config.autoAddCss = false;
fontawesome_svg_core_1.library.add(faPencilAlt_1.faPencilAlt, faPeopleCarry_1.faPeopleCarry, faBackward_1.faBackward, faBan_1.faBan, faComments_1.faComments, faArrowToRight_1.faArrowToRight, faFileAlt_1.faFileAlt, faHandshake_1.faHandshake, faTrashAlt_1.faTrashAlt, faForward_1.faForward, faCalendarPlus_1.faCalendarPlus, faPaperPlane_1.faPaperPlane, faCheckDouble_1.faCheckDouble, faCaretUp_1.faCaretUp, faBell_1.faBell, faFilePdf_1.faFilePdf, faEllipsisV_1.faEllipsisV, faTimes_1.faTimes, faPlay_1.faPlay, faCog_1.faCog, faPause_1.faPause, faStop_1.faStop, faEye_1.faEye, faCheck_1.faCheck, faMagic_1.faMagic, faSearch_1.faSearch, faAlignLeft_1.faAlignLeft, faCalendar_1.faCalendar, faMapMarkerAlt_1.faMapMarkerAlt, faEnvelopeOpenText_1.faEnvelopeOpenText, faBars_1.faBars, faIdCard_1.faIdCard, faFileUpload_1.faFileUpload, faExclamationTriangle_1.faExclamationTriangle, faFileDownload_1.faFileDownload, faCubes_1.faCubes, faCross_1.faCross, faEdit_1.faEdit);
