"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var TextField_1 = require("../../../../../common/TextField");
var PurchaseOrderNumber = function (props) {
    return (React.createElement(TextField_1.default, { type: "text", name: "purchase_order_number", validator: props.validator, value: props.value, label: window.location.href.includes('page9')
            ? 'Purchase Order Number'
            : 'Do you have a Purchase order number for the goods/services provided?', placeholder: 'Job reference', disabled: false }));
};
exports.default = PurchaseOrderNumber;
