"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var GuidanceChecklists = function () {
    return (React.createElement("div", null,
        React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("th", null, "File"),
                React.createElement("th", null, "Jurisdiction"),
                React.createElement("th", null, "View"),
                React.createElement("th", null, "Download")),
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", null, 'T&Cs Checklist Supply of Goods - Business to Business'),
                    React.createElement("td", null, "England and Wales"),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/T&Cs Checklist Supply of Goods - Business to Business.pdf", rel: "noreferrer", target: "_blank" }, "View")),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/T&Cs Checklist Supply of Goods - Business to Business.pdf", download: true }, "Download"))),
                React.createElement("tr", null,
                    React.createElement("td", null, 'T&Cs Checklist Supply of Goods - Business to Consumers'),
                    React.createElement("td", null, "England and Wales"),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/T&Cs Checklist Supply of Goods - Business to Consumers.pdf", rel: "noreferrer", target: "_blank" }, "View")),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/T&Cs Checklist Supply of Goods - Business to Consumers.pdf", download: true }, "Download"))),
                React.createElement("tr", null,
                    React.createElement("td", null, 'T&Cs Checklist Supply of Services - Business to Business'),
                    React.createElement("td", null, "England and Wales"),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/T&Cs Checklist Supply of Services - Business to Business.pdf", rel: "noreferrer", target: "_blank" }, "View")),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/T&Cs Checklist Supply of Services - Business to Business.pdf", download: true }, "Download"))),
                React.createElement("tr", null,
                    React.createElement("td", null, 'T&Cs Checklist Supply of Services - Business to Consumers'),
                    React.createElement("td", null, "England and Wales"),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/T&Cs Checklist Supply of Services - Business to Consumers.pdf", rel: "noreferrer", target: "_blank" }, "View")),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/T&Cs Checklist Supply of Services - Business to Consumers.pdf", download: true }, "Download"))),
                React.createElement("tr", null,
                    React.createElement("td", null, 'T&Cs Checklist Supply of Goods - Business to Business'),
                    React.createElement("td", null, "Scotland"),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/scotland/T&C's Checklist Supply of Goods - Business to Business.pdf", rel: "noreferrer", target: "_blank" }, "View")),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/scotland/T&C's Checklist Supply of Goods - Business to Business.pdf", download: true }, "Download"))),
                React.createElement("tr", null,
                    React.createElement("td", null, 'T&Cs Checklist Supply of Goods - Business to Consumers'),
                    React.createElement("td", null, "Scotland"),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/scotland/T&C's Checklist Supply of Goods - Business to Business.pdf", rel: "noreferrer", target: "_blank" }, "View")),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/scotland/T&C's Checklist Supply of Goods - Business to Business.pdf", download: true }, "Download"))),
                React.createElement("tr", null,
                    React.createElement("td", null, 'T&Cs Checklist Supply of Services - Business to Business'),
                    React.createElement("td", null, "Scotland"),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/scotland/T&C's Checklist Supply of Services - Business to Business.pdf", rel: "noreferrer", target: "_blank" }, "View")),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/scotland/T&C's Checklist Supply of Services - Business to Business.pdf", download: true }, "Download"))),
                React.createElement("tr", null,
                    React.createElement("td", null, 'T&Cs Checklist Supply of Services - Business to Consumers'),
                    React.createElement("td", null, "Scotland"),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/scotland/T&C's Checklist Supply of Services - Business to Consumers.pdf", rel: "noreferrer", target: "_blank" }, "View")),
                    React.createElement("td", null,
                        React.createElement("a", { href: "/assets/documents/scotland/T&C's Checklist Supply of Services - Business to Consumers.pdf", download: true }, "Download")))))));
};
exports.default = GuidanceChecklists;
