"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var FileInput_1 = require("../../../../common/FileInput");
var scrollToTop_1 = require("../../../../../utils/scrollToTop");
var AddDebtWizardPage7 = function (props) {
    (0, scrollToTop_1.default)();
    return (React.createElement("div", { className: "add-debt-wizard-page flex-column" },
        React.createElement("div", { className: "add-debt-wizard-form-contents" },
            React.createElement("div", { className: "add-debt-wizard-form-description" },
                React.createElement("h2", null, "Invoice"),
                React.createElement("p", null, "You have the option of uploading a relevant document to support your debt. While this is optional it will improve the likelihood of your debt being recovered.")),
            React.createElement(FileInput_1.default, { setFileUpload: function (file) { return props.setFileUpload(props.setFieldValue, file); }, value: props.values.supporting_documents !== null ? props.values.supporting_documents.name : null })),
        React.createElement("div", { className: "link_navigation flex-row" },
            React.createElement(react_router_dom_1.Link, { to: "/dashboard/debtwizard/page7", className: "button nav-button-grey" }, "Previous"),
            React.createElement(react_router_dom_1.Link, { to: "/dashboard/debtwizard/page9", className: "button nav-button-grey", id: 'active' }, "Next"))));
};
exports.default = AddDebtWizardPage7;
