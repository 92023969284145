"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var TrackLimitNumbers_1 = require("./TrackLimitNumbers");
var TrackLimit = function (props) {
    return (React.createElement("div", { className: "track-limit flex-column" },
        React.createElement(TrackLimitNumbers_1.default, { values: {
                amount: props.value1,
                of: props.value2,
                dateUntil: new Date(props.value3).toLocaleDateString('en-GB', {
                    timeZone: 'UTC',
                }),
            } })));
};
exports.default = TrackLimit;
