"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var TextField_1 = require("../../../../../common/TextField");
var NameField = function (props) {
    var nameByProtocol = function () {
        if (props.values.protocol_id === 1) {
            return (React.createElement(React.Fragment, null,
                React.createElement(TextField_1.default, { name: "first_name", value: props.values.first_name, validator: props.validator, label: 'What is the full name of the debtor?', placeholder: 'First name', onChange: function (value) {
                        props.setFieldValue('first_name', value);
                    }, disabled: props.values.debtor_id !== null }),
                React.createElement(formik_1.ErrorMessage, { name: "first_name" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
                React.createElement(TextField_1.default, { name: "middle_name", value: props.values.middle_name, validator: props.validator, placeholder: 'Middle name', onChange: function (value) {
                        props.setFieldValue('middle_name', value);
                    }, disabled: props.values.debtor_id !== null }),
                React.createElement(formik_1.ErrorMessage, { name: "middle_name" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
                React.createElement(TextField_1.default, { name: "last_name", value: props.values.last_name, validator: props.validator, placeholder: 'Surname', onChange: function (value) {
                        props.setFieldValue('last_name', value);
                    }, disabled: props.values.debtor_id !== null }),
                React.createElement(formik_1.ErrorMessage, { name: "last_name" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); })));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(TextField_1.default, { name: "company_name", value: props.values.company_name, validator: props.validator, label: 'What is the full name of the debtor?', hint: 'This should be the name of the company that owes this debt to you, and who we will contact on your behalf.', placeholder: 'Joe Bloggs LTD', onChange: function (value) {
                    props.setFieldValue('company_name', value);
                }, disabled: props.values.debtor_id !== null }),
            React.createElement(formik_1.ErrorMessage, { name: "company_name" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); })));
    };
    return React.createElement(React.Fragment, null, nameByProtocol());
};
exports.default = NameField;
