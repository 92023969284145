"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var DebtorTile_1 = require("./DebtorTile");
var DebtorTilesContainer = function (props) {
    return (React.createElement("div", { className: "debtor_tile_container flex-row-wrap ".concat(props.debtors.length >= 1 ? '' : 'hidden_field') }, props.debtors.length >= 1
        ? props.debtors.map(function (debtor) {
            return (React.createElement(DebtorTile_1.default, { key: debtor.id, debtor: debtor, values: props.values, onClick: function () {
                    props.setDebtor(props.setFieldValue, debtor, props.values);
                    if (props.values.debtor_id === null) {
                        props.setSelectedDebtor(debtor);
                    }
                    else
                        props.setSelectedDebtor({ id: null });
                } }));
        })
        : null));
};
exports.default = DebtorTilesContainer;
