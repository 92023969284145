"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Yup = require("yup");
exports.default = Yup.object().shape({
    // .required("Required"),
    email: Yup.string()
        .trim()
        .email()
        .nullable()
        .max(50, 'Email cannot be more than 50 characters'),
    address_1: Yup.string()
        .trim()
        .max(100, 'Address Line 1 cannot be more than 100 characters')
        .nullable()
        .required('You must provide address line one.'),
    address_2: Yup.string()
        .trim()
        .max(100, 'Address Line 2 cannot be more than 100 characters')
        .nullable(),
    city: Yup.string()
        .trim()
        .max(20, 'City cannot be more than 20 characters')
        .nullable()
        .required(),
    post_code: Yup.string()
        .trim()
        .required('Postcode is a required field')
        .max(8, 'Postcode cannot exceed 8 characters')
        .nullable()
        .matches(/[A-Za-z][A-Ha-hK-Yk-y]?[0-9][A-Za-z0-9]? [0-9][A-Za-z]{2}|[Gg][Ii][Rr] 0[Aa]{2}/, 'Invalid Postcode Format'),
    original_amount: Yup.number()
        .typeError('The amount needs to be a number')
        .min(25, 'Minimum value is £25')
        .max(20000, 'Maximum value is £20,000')
        .positive('Number must be positive')
        .required('Required'),
    invoice_reference: Yup.string()
        .trim()
        .max(20, 'Invoice reference must be 20 characters or less')
        .required('Required'),
    invoice_due_date: Yup.date().required('Required'),
    invoice_sent_date: Yup.date().required('Required'),
    reminder_sent_date: Yup.date(),
    protocol_id: Yup.number().required('Required'),
    representative_name: Yup.string()
        .trim()
        .when('protocol_id', {
        is: function (protocol_id) { return protocol_id === 2; },
        then: Yup.string().required('Required'),
    })
        .nullable(),
    interest_rate: Yup.number()
        .moreThan(-1)
        .max(8000, 'The interest rate can be a maximum of 8000%'),
    purchase_order_number: Yup.string()
        .trim()
        .max(20, 'Purchase order number must be 20 characters or less'),
    trading_name: Yup.string()
        .trim()
        .max(100, 'Trading name cannot be more than 100 characters'),
    companies_house_number: Yup.string()
        .trim()
        .max(20, 'Companies house number can not be more than 20 characters'),
    //This is an example of conditional validation without cyclic dependency errors using yup.
    //No arrow functions, YUP will make formik throw an error.  Not sure if it would be fine outside of formik, 'tis a test for another day.
    goods_description: Yup.string()
        .trim()
        .max(250, 'Good description must be 250 characters or less')
        .test('goods or services', 'If a description of services is not given, then a description of goods provided to the debtor is required', function (value) {
        var services_description = this.parent.services_description;
        if (!services_description) {
            return value != null;
        }
        return true;
    }),
    services_description: Yup.string()
        .trim()
        .max(250, 'Services description must be 250 characters or less')
        .test('goods or services', 'If a description of goods is not given, then a description of services provided to the debtor is required', function (value) {
        var goods_description = this.parent.goods_description;
        if (!goods_description) {
            return value != null;
        }
        return true;
    }),
    first_name: Yup.string()
        .trim()
        .max(100, 'First name cannot be longer than 20 characters')
        .nullable()
        .test('Name', 'When the debtor is an individual, you must provide their first name.', function (value) {
        var protocol_id = this.parent.protocol_id;
        if (protocol_id === 1) {
            return value != null;
        }
        return true;
    }),
    middle_name: Yup.string()
        .trim()
        .max(100, 'Name cannot be longer than 20 characters')
        .nullable(),
    last_name: Yup.string()
        .trim()
        .max(100, 'Name cannot be longer than 20 characters')
        .nullable()
        .test('Name', 'When the debtor is an individual, you must provide their surname.', function (value) {
        var protocol_id = this.parent.protocol_id;
        if (protocol_id === 1) {
            return value != null;
        }
        return true;
    }),
    company_name: Yup.string()
        .trim()
        .max(100, 'Company name cannot be longer than 100 characters')
        .nullable()
        .test('Name', 'If protocol is business company name must be filled', function (value) {
        var protocol_id = this.parent.protocol_id;
        if (protocol_id === 2) {
            return value != null;
        }
        return true;
    }),
});
