"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var RadioButton_1 = require("../../../../../common/RadioButton");
var TextField_1 = require("../../../../../common/TextField");
var AddDebtWizardPage8DebtType = function (props) {
    return (React.createElement("div", { className: "form-panel flex-column" },
        React.createElement("label", null, "Debtor Type"),
        React.createElement("div", { className: "".concat(props.values.debtor_id !== null ? 'protocol-tile-container-disabled' : '') },
            React.createElement("label", { className: "flex-row" },
                React.createElement(RadioButton_1.default, { value: 1, checked: props.values.protocol_id === 1, name: 'protocol_id', handleRadioChange: function () {
                        props.setFieldValue('protocol_id', 1);
                    } }),
                "Individual"),
            React.createElement("label", { className: "flex-row" },
                React.createElement(RadioButton_1.default, { value: 2, checked: props.values.protocol_id === 2, name: 'protocol_id', handleRadioChange: function () {
                        props.setFieldValue('protocol_id', 2);
                    } }),
                "Business(or other organisation)")),
        React.createElement("label", { style: { transitionDuration: '0.4s' }, className: props.values.protocol_id === 1 ? 'hidden_field' : '' },
            "Representative Name",
            React.createElement(TextField_1.default, { type: "text", name: "representative_name", value: props.values.representative_name, validator: props.validator, disabled: props.values.debtor_id !== null })),
        React.createElement(formik_1.ErrorMessage, { name: "representative_name" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
        React.createElement("label", { className: "flex-column" },
            "Good(s)",
            React.createElement("textarea", { value: props.values.goods_description, onChange: function (e) { return props.setFieldValue('goods_description', e.target.value); } })),
        React.createElement(formik_1.ErrorMessage, { name: "goods_description" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
        React.createElement("label", { className: "flex-column" },
            "Service(s)",
            React.createElement("textarea", { value: props.values.services_description, onChange: function (e) { return props.setFieldValue('services_description', e.target.value); } })),
        React.createElement(formik_1.ErrorMessage, { name: "services_description" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); })));
};
exports.default = AddDebtWizardPage8DebtType;
