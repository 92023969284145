"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var DebtNotes_1 = require("../DebtNotes/DebtNotes");
var ExistingDemands = function (_a) {
    var notes = _a.notes, reference = _a.reference, handleLoadingComponent = _a.handleLoadingComponent, children = _a.children;
    return (React.createElement("div", { className: 'demand-list' },
        React.createElement("header", null,
            React.createElement("h2", null,
                "Actions Taken on Debt ",
                reference),
            children),
        React.createElement(DebtNotes_1.default, { notes: notes, handleLoadingComponent: handleLoadingComponent })));
};
exports.default = ExistingDemands;
