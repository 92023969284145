"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var date_fns_1 = require("date-fns");
exports.default = (function (existingDate) {
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    var dateTime = date + ' ' + time;
    var strictDateWord = (0, date_fns_1.formatDistanceStrict)(new Date(existingDate), new Date(dateTime));
    return strictDateWord;
});
