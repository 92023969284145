"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var core_1 = require("@rradar/core");
var lodash_1 = require("lodash");
var react_router_dom_1 = require("react-router-dom");
var getState_1 = require("../../utils/getState");
var permissions_1 = require("../../../app/utils/permissions");
var recoverInterface_1 = require("../../utils/recoverInterface");
var signout = function () {
    new Promise(function (resolve) {
        try {
            (0, recoverInterface_1.default)()
                .sign_out()
                .sign_out()
                .then(function (response) { return (0, core_1.ToastNotification)({ type: 'success', message: response.message }); });
            resolve(true);
        }
        catch (e) {
            (0, core_1.ToastNotification)({ type: 'error', message: e.message });
            throw new Error(e);
        }
    }).then(function () {
        window.location.href = '/';
    });
};
var switchCompany = function () { return __awaiter(void 0, void 0, void 0, function () {
    var newUrl, swithCompanyUrl;
    return __generator(this, function (_a) {
        newUrl = (0, getState_1.default)().api.authentication_url.split('?');
        swithCompanyUrl = "".concat(newUrl[0], "/companyselect?").concat(newUrl[1]);
        window.location.href = swithCompanyUrl;
        return [2 /*return*/];
    });
}); };
var QuickActions = function () {
    var user = (0, getState_1.default)().user;
    var _a = __read(React.useState(''), 2), companyName = _a[0], setCompanyName = _a[1];
    React.useEffect(function () {
        if (user.company_name) {
            if (user.company_name.length > 30) {
                var truncate = "".concat(user.company_name.slice(0, 20), "...");
                setCompanyName(truncate);
            }
            else
                setCompanyName(user.company_name);
        }
    }, [user.company_name]);
    var standardClassnames = 'button quick-action-buttons';
    var activeClassnames = 'active_dashboard_header' + ' ' + standardClassnames;
    return (React.createElement("div", { className: "quick-actions" },
        (0, lodash_1.isEmpty)((0, getState_1.default)().user) ? (React.createElement("div", null,
            React.createElement("button", { type: "button", className: "button quick-action-buttons", onClick: function () { return (window.location.href = (0, getState_1.default)().api.authentication_url); } }, "Log In"))) : null,
        user.role === 'customer' ? (React.createElement("div", { className: "customer-items" },
            React.createElement(react_router_dom_1.NavLink, { className: function (_a) {
                    var isActive = _a.isActive;
                    return (isActive ? activeClassnames : standardClassnames);
                }, to: "/dashboard", onClick: function () {
                    if (window.location.href.endsWith('dashboard')) {
                        window.location.reload();
                    }
                }, end: true }, "Dashboard"),
            React.createElement(react_router_dom_1.NavLink, { className: function (_a) {
                    var isActive = _a.isActive;
                    return (isActive ? activeClassnames : standardClassnames);
                }, to: "/dashboard/debtors" }, "Debtors"),
            React.createElement(react_router_dom_1.NavLink, { className: function (_a) {
                    var isActive = _a.isActive;
                    return (isActive ? activeClassnames : standardClassnames);
                }, to: "/dashboard/guidance" }, "Guidance"),
            React.createElement(react_router_dom_1.NavLink, { className: function (_a) {
                    var isActive = _a.isActive;
                    return (isActive ? activeClassnames : standardClassnames);
                }, to: "/dashboard/my_company" }, "My Company"))) : null,
        (0, permissions_1.hasPermission)((0, getState_1.default)().user, ['legal']) ? (React.createElement(react_router_dom_1.NavLink, { className: function (_a) {
                var isActive = _a.isActive;
                return (isActive ? activeClassnames : standardClassnames);
            }, to: "/admin", end: true }, "Dashboard")) : null,
        (0, permissions_1.hasPermission)((0, getState_1.default)().user, ['admin', 'legal']) ? (React.createElement(react_router_dom_1.NavLink, { className: function (_a) {
                var isActive = _a.isActive;
                return (isActive ? activeClassnames : standardClassnames);
            }, to: '/admin/debts' }, "Debts")) : null,
        (0, permissions_1.hasPermission)((0, getState_1.default)().user, ['admin', 'legal']) ? (React.createElement(react_router_dom_1.NavLink, { className: function (_a) {
                var isActive = _a.isActive;
                return (isActive ? activeClassnames : standardClassnames);
            }, to: "/admin/companies" }, "Companies")) : null,
        !(0, lodash_1.isEmpty)(user) ? (React.createElement("div", { className: "quick-actions-profile" },
            React.createElement(core_1.ProfileItem, { company_name: companyName, full_name: "".concat(user.user_first_name, " ").concat(user.user_last_name), isCompanySwitchable: user.company_can_switch, logout: function () { return signout(); }, switchCompany: function () {
                    switchCompany();
                }, color: "white", dropdown: [
                    {
                        title: 'Reset tutorial',
                        hasPermission: true,
                        hasDivider: false,
                        onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, (0, recoverInterface_1.default)().me().setJoyrideOn()];
                                    case 1:
                                        _a.sent();
                                        window.location.reload();
                                        return [2 /*return*/];
                                }
                            });
                        }); },
                    },
                ] }))) : null));
};
exports.default = QuickActions;
