"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.debtorBundler = exports.debtSeperator = void 0;
var debtSeperator = function (object) {
    var debt = __assign({}, object);
    var debtor = debt.debtor;
    delete debtor.id;
    delete debt.debtor;
    delete debt.debtor_id;
    var newObject = __assign(__assign({}, debt), debtor);
    return newObject;
};
exports.debtSeperator = debtSeperator;
var debtorBundler = function (object) {
    var data = __assign({}, object);
    var debtor = {
        address_1: data.address_1,
        address_2: data.address_2,
        city: data.city,
        company_id: data.company_id,
        country: data.country,
        email: data.email,
        id: data.id,
        name: data.name,
        post_code: data.post_code,
        protocol_id: data.protocol_id
    };
    delete data.address_1;
    delete data.address_2;
    delete data.city;
    delete data.company_id;
    delete data.country;
    delete data.email;
    delete data.id;
    delete data.name;
    delete data.post_code;
    delete data.protocol_id;
    delete data.created_at;
    delete data.updated_at;
    var debt = data;
    debt.debtor = debtor;
    return debt;
};
exports.debtorBundler = debtorBundler;
