"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classnames_1 = require("classnames");
var Button_1 = require("./Button");
var ButtonGroup = function (props) { return (React.createElement("ul", { className: (0, classnames_1.default)({
        'button-group': true,
        'button-group-singular': props.buttons.length === 1,
    }), style: props.style }, props.buttons.map(function (button) { return (React.createElement("li", { key: button.label },
    React.createElement(Button_1.default, { label: button.label, disabled: button.disabled, method: button.method }))); }))); };
exports.default = ButtonGroup;
