"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var AdminDashboardPageNumbers = function (_a) {
    var indexKey = _a.indexKey, setPageNumber = _a.setPageNumber;
    return (React.createElement("div", { key: indexKey.toString() },
        React.createElement("a", { onClick: function () { return setPageNumber(indexKey); } },
            "Page ",
            indexKey)));
};
exports.default = AdminDashboardPageNumbers;
