"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var recoverInterface_1 = require("./recoverInterface");
exports.default = (function () { return __awaiter(void 0, void 0, Promise, function () {
    var debt_status_counts_response, readableHeadings, headingsOrder, tableToReturn, key;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, recoverInterface_1.default)()
                    .debt_status_counts()
                    .get()
                    .then(function (response) {
                    debt_status_counts_response = response;
                })];
            case 1:
                _a.sent();
                readableHeadings = {
                    unsubmitted: 'Unsubmitted',
                    reverted: 'Reverted',
                    submitted: 'Submitted',
                    stage_1: '1st demand',
                    stage_1_ended: 'Due to reconfirm',
                    stage_2_authorised: 'Reconfirmed',
                    stage_2: '2nd demand',
                    declined: 'Declined',
                    overdue: 'Overdue',
                    recovered: 'Recovered',
                    closed: 'Closed',
                    litigation_requested: 'In consultation',
                    in_litigation: 'In litigation',
                };
                headingsOrder = {
                    unsubmitted: 1,
                    reverted: 2,
                    submitted: 3,
                    stage_1: 4,
                    stage_1_ended: 5,
                    stage_2_authorised: 6,
                    stage_2: 7,
                    overdue: 8,
                    declined: 9,
                    closed: 10,
                    recovered: 11,
                    litigation_requested: 12,
                    in_litigation: 13,
                };
                tableToReturn = [];
                for (key in debt_status_counts_response.debt_status_counts) {
                    tableToReturn.push({
                        heading: "".concat(readableHeadings[key], " (").concat(debt_status_counts_response.debt_status_counts[key], ")"),
                        value: key,
                        order: headingsOrder[key],
                    });
                }
                tableToReturn.sort(function (a, b) {
                    var valueA = a.order;
                    var valueB = b.order;
                    if (valueA < valueB) {
                        return -1;
                    }
                    if (valueA > valueB) {
                        return 1;
                    }
                    return 0;
                });
                return [2 /*return*/, tableToReturn];
        }
    });
}); });
