"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var getAmounts = function (data) {
    var amounts = data;
    (0, react_1.useEffect)(function () {
        for (var k in amounts) {
            var value = amounts[k].replace(',', '');
            amounts[k] = parseFloat(value);
        }
    }, []);
    var dataArray = [];
    var unsubmitted = 0;
    var submitted = 0;
    var stage_1 = 0;
    var stage_2 = 0;
    var overdue = 0;
    amounts.overdue ? (overdue += amounts.overdue) : null;
    amounts.reverted ? (unsubmitted += amounts.reverted) : null;
    amounts.stage_1 ? (stage_1 += amounts.stage_1) : null;
    amounts.stage_1_ended ? (overdue += amounts.stage_1_ended) : null;
    amounts.stage_2_authorised ? (stage_2 += amounts.stage_2_authorised) : null;
    amounts.submitted ? (submitted += amounts.submitted) : null;
    amounts.unsubmitted ? (unsubmitted += amounts.unsubmitted) : null;
    dataArray.push(unsubmitted);
    dataArray.push(submitted);
    dataArray.push(stage_1);
    dataArray.push(stage_2);
    dataArray.push(overdue);
    return dataArray;
};
exports.default = getAmounts;
