"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var JurisdictionButton_1 = require("../../../AddDebtWizard/addDebt/JurisdictionPicker/JurisdictionButton");
var EditDebtJurisidction = function (props) {
    return (React.createElement("div", { className: "flex-row ".concat(props.disabled ? 'protocol-tile-container-disabled' : '') },
        React.createElement(JurisdictionButton_1.default, { setFieldValue: props.setFieldValue, text: 'England and Wales', imagePath: '/assets/img/countries/gb.svg', jurisdiction: 'england_wales', value: props.values.jurisdiction }),
        React.createElement(JurisdictionButton_1.default, { setFieldValue: props.setFieldValue, text: 'Scotland', imagePath: '/assets/img/countries/gb-sct.svg', jurisdiction: 'scotland', value: props.values.jurisdiction })));
};
exports.default = EditDebtJurisidction;
