"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_chartjs_2_1 = require("react-chartjs-2");
var getAmounts_1 = require("../../../../utils/getAmounts");
var ActiveAmountsBreakdown = function (props) {
    var amounts = (0, getAmounts_1.default)(props.amounts);
    var amountsLabels = function () {
        return ['Unsubmitted', 'Submitted', 'Stage 1', 'Stage 2', 'Awaiting Actions'];
    };
    var data = {
        labels: amountsLabels(),
        datasets: [
            {
                backgroundColor: ['#8d3b72', '#5ec58e', '#ffd166', '#555555', '#1298a4'],
                borderColor: '#f4f4f4',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: amounts,
            },
        ],
    };
    var options = {
        legend: {
            display: false,
        },
        tooltips: {
            callbacks: {
                label: function (value) {
                    return parseFloat(value.value).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'GBP',
                    });
                },
            },
        },
    };
    return (React.createElement("div", { style: {
            height: '180px',
            display: 'flex',
            color: 'black',
            marginTop: '1em',
        } },
        React.createElement(react_chartjs_2_1.Bar, { data: data, width: 0.5, height: 0.5, options: options })));
};
exports.default = ActiveAmountsBreakdown;
