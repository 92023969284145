"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var IndexDebtors_1 = require("../../../components/Pages/ViewDebtors/IndexDebtors");
var core_1 = require("@rradar/core");
var Debtors = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "dashboard-body" },
                React.createElement("div", { className: "dashboard-header" },
                    React.createElement(core_1.Heading1, { title: "Debtors" })),
                React.createElement(IndexDebtors_1.default, null)))));
};
exports.default = Debtors;
