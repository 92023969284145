"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var formik_1 = require("formik");
var ProtocolSelector_1 = require("./ProtocolTile/ProtocolSelector");
var HeadedTextareaInputContainer_1 = require("./HeadedTextareaInput/HeadedTextareaInputContainer");
var scrollToTop_1 = require("../../../../../utils/scrollToTop");
var AddDebtWizardPage3 = function (props) {
    (0, scrollToTop_1.default)();
    return (React.createElement("div", { className: "add-debt-wizard-page flex-column" },
        React.createElement("div", { className: "add-debt-wizard-form-contents" },
            React.createElement("div", { className: "add-debt-wizard-form-description" },
                React.createElement("h2", null, "Debt Type"),
                React.createElement("p", null, "Select if your debt is owed by an individual or an organisation, and what you are owed for."),
                React.createElement("p", null, "There are a number of extra steps that must be taken when attempting to recover a debt from an individual. These mean that the debt may take longer to be recovered.")),
            React.createElement("div", { className: "flex-row-wrap" },
                React.createElement("div", { className: "form-panel" },
                    React.createElement("label", { style: { width: '25vw' } }, "Please select the type of debtor by selecting if it\u2019s an individual or business."),
                    React.createElement(ProtocolSelector_1.default, { values: props.values, setFieldValue: props.setFieldValue, validator: props.validator }),
                    React.createElement(formik_1.ErrorMessage, { name: "protocol_id" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); })),
                React.createElement("div", { className: "form-panel" },
                    React.createElement("label", { className: "required" }, "Please describe the goods and/or services that you provided."),
                    React.createElement(HeadedTextareaInputContainer_1.default, { values: props.values, setFieldValue: props.setFieldValue })))),
        React.createElement("div", { className: "link_navigation flex-row" },
            React.createElement(react_router_dom_1.Link, { to: "/dashboard/debtwizard/page3", className: "button nav-button-grey" }, "Previous"),
            React.createElement(react_router_dom_1.Link, { to: "/dashboard/debtwizard/page5", className: "button nav-button-grey", id: 'active' }, "Next"))));
};
exports.default = AddDebtWizardPage3;
