"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (response, filename, filetype) {
    if (filename === void 0) { filename = 'download'; }
    if (filetype === void 0) { filetype = ''; }
    var newBlob = new Blob([response]);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
    }
    var data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download = "".concat(filename).concat(filetype === '' ? "" : ".".concat(filetype));
    // link.click();
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    setTimeout(function () {
        window.URL.revokeObjectURL(data), 100;
    });
});
