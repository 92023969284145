"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classnames_1 = require("classnames");
var lodash_1 = require("lodash");
var react_responsive_1 = require("react-responsive");
var getState_1 = require("../../../../../utils/getState");
var ViewDebt_1 = require("../ViewDebt");
var DebtStatus = /** @class */ (function (_super) {
    __extends(DebtStatus, _super);
    function DebtStatus() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.statusMessageHandler = function () {
            var isAdmin = !(0, lodash_1.isEmpty)((0, getState_1.default)().user) && (0, getState_1.default)().user.role !== 'customer';
            switch (_this.props.debtStatus) {
                case ViewDebt_1.IDebtStatus.SUBMITTED:
                    return isAdmin
                        ? 'Please generate, print and send a demand letter.'
                        : 'Your debt has successfully been submitted to the rradar team.';
                case ViewDebt_1.IDebtStatus.STAGE_1:
                    return isAdmin
                        ? "We've sent the customers client their first demand letter."
                        : 'We working on your case recovering what your business is owed. No further action is needed from yourself.';
                case ViewDebt_1.IDebtStatus.STAGE_2:
                    return isAdmin
                        ? "We've sent the second demand letter."
                        : "Okay, so they have not responded to our first letter. Don't worry though, we have moved your claim into the second stage of recovery.";
                case ViewDebt_1.IDebtStatus.OVERDUE:
                    return 'This debt is now overdue. Please contact the customer.';
                case ViewDebt_1.IDebtStatus.RECOVERED:
                    return isAdmin ? 'Recovery has been successful.' : 'Congratulations, your owed amount has been recovered.';
            }
        };
        _this.handleTimelineStage = function (status) {
            if (status === _this.props.debtStatus) {
                return true;
            }
            return false;
        };
        _this.render = function () {
            return (React.createElement("div", null,
                React.createElement(react_responsive_1.default, { minDeviceWidth: 1024 },
                    React.createElement("div", { className: "debt-status-timeline flex-row" },
                        _this.handleTimelineStage(ViewDebt_1.IDebtStatus.UNSUBMITTED) ? (React.createElement("div", { className: (0, classnames_1.default)({
                                'debt-status-active': _this.handleTimelineStage(ViewDebt_1.IDebtStatus.UNSUBMITTED),
                                'debt-status-await': _this.handleTimelineStage(ViewDebt_1.IDebtStatus.UNSUBMITTED) && (0, getState_1.default)().user.role === 'customer',
                            }) }, (0, getState_1.default)().user.role === 'customer' ? 'Requires Attention' : 'Pending Customer Action')) : null,
                        _this.handleTimelineStage(ViewDebt_1.IDebtStatus.REVERTED) ? (React.createElement("div", { className: (0, classnames_1.default)({
                                'debt-status-active': _this.handleTimelineStage(ViewDebt_1.IDebtStatus.REVERTED),
                                'debt-status-await': _this.handleTimelineStage(ViewDebt_1.IDebtStatus.REVERTED) && (0, getState_1.default)().user.role === 'customer',
                            }) }, (0, getState_1.default)().user.role === 'customer' ? 'Requires Attention' : 'Pending Customer Action')) : null,
                        React.createElement("div", { className: (0, classnames_1.default)({
                                'debt-status-active': _this.handleTimelineStage(ViewDebt_1.IDebtStatus.SUBMITTED),
                                'debt-status-await': _this.handleTimelineStage(ViewDebt_1.IDebtStatus.SUBMITTED) && (0, getState_1.default)().user.role !== 'customer',
                            }) }, (0, getState_1.default)().user.role === 'customer' ? 'In Review' : 'Requires Review'),
                        React.createElement("div", { className: (0, classnames_1.default)({
                                'debt-status-active': _this.handleTimelineStage(ViewDebt_1.IDebtStatus.STAGE_1) ||
                                    ((0, getState_1.default)().user.role !== 'customer' && _this.handleTimelineStage(ViewDebt_1.IDebtStatus.STAGE_1_ENDED)),
                                'debt-status-await': _this.handleTimelineStage(ViewDebt_1.IDebtStatus.STAGE_1_ENDED) && (0, getState_1.default)().user.role === 'customer',
                            }) }, _this.handleTimelineStage(ViewDebt_1.IDebtStatus.STAGE_1_ENDED) ? 'Awaiting Advancement' : 'First Demand'),
                        React.createElement("div", { className: (0, classnames_1.default)({
                                'debt-status-active': _this.handleTimelineStage(ViewDebt_1.IDebtStatus.STAGE_2) ||
                                    (_this.handleTimelineStage(ViewDebt_1.IDebtStatus.STAGE_2_AUTHORISED) && (0, getState_1.default)().user.role === 'customer'),
                                'debt-status-await': _this.handleTimelineStage(ViewDebt_1.IDebtStatus.STAGE_2_AUTHORISED) && (0, getState_1.default)().user.role !== 'customer',
                            }) }, "Second Demand"),
                        _this.handleTimelineStage(ViewDebt_1.IDebtStatus.OVERDUE) ? (React.createElement("div", { className: (0, classnames_1.default)({
                                'debt-status-active': _this.handleTimelineStage(ViewDebt_1.IDebtStatus.OVERDUE),
                                'debt-status-await': _this.handleTimelineStage(ViewDebt_1.IDebtStatus.OVERDUE) && (0, getState_1.default)().user.role === 'customer',
                            }) }, (0, getState_1.default)().user.role === 'customer' ? 'Requires Attention' : 'Pending Customer Action')) : null,
                        !_this.handleTimelineStage(ViewDebt_1.IDebtStatus.CLOSED) &&
                            !_this.handleTimelineStage(ViewDebt_1.IDebtStatus.REQUESTED) &&
                            !_this.handleTimelineStage(ViewDebt_1.IDebtStatus.LITIGATION) &&
                            !_this.handleTimelineStage(ViewDebt_1.IDebtStatus.DECLINED) ? (React.createElement("div", { className: (0, classnames_1.default)({
                                'debt-status-active': _this.handleTimelineStage(ViewDebt_1.IDebtStatus.RECOVERED),
                            }) }, "Recovered")) : null,
                        _this.handleTimelineStage(ViewDebt_1.IDebtStatus.CLOSED) ? (React.createElement("div", { className: (0, classnames_1.default)({
                                'debt-status-active': _this.handleTimelineStage(ViewDebt_1.IDebtStatus.CLOSED),
                            }) }, "Closed")) : null,
                        _this.handleTimelineStage(ViewDebt_1.IDebtStatus.DECLINED) ? (React.createElement("div", { className: (0, classnames_1.default)({
                                'debt-status-active': _this.handleTimelineStage(ViewDebt_1.IDebtStatus.DECLINED),
                            }) }, "Declined")) : null,
                        _this.handleTimelineStage(ViewDebt_1.IDebtStatus.REQUESTED) ? (React.createElement("div", { className: (0, classnames_1.default)({
                                'debt-status-active': _this.handleTimelineStage(ViewDebt_1.IDebtStatus.REQUESTED),
                                'debt-status-await': _this.handleTimelineStage(ViewDebt_1.IDebtStatus.REQUESTED) && (0, getState_1.default)().user.role !== 'customer',
                            }) }, "In consultation")) : null,
                        _this.handleTimelineStage(ViewDebt_1.IDebtStatus.LITIGATION) ? (React.createElement("div", { className: (0, classnames_1.default)({
                                'debt-status-active': _this.handleTimelineStage(ViewDebt_1.IDebtStatus.LITIGATION),
                            }) }, "Litigation")) : null))));
        };
        return _this;
    }
    return DebtStatus;
}(React.Component));
exports.default = DebtStatus;
