"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var Hint_1 = require("../../../../common/Hint");
var DebtorTilesContainer_1 = require("../Debtor/DebtorTilesContainer");
var scrollToTop_1 = require("../../../../../utils/scrollToTop");
var AddDebtWizardPage2 = function (props) {
    (0, scrollToTop_1.default)();
    return (React.createElement("div", { className: "add-debt-wizard-page flex-column" },
        React.createElement("div", { className: "add-debt-wizard-form-contents" },
            React.createElement("div", { className: "wizard-form-description" },
                React.createElement("h2", null, "Debtor Search"),
                React.createElement("p", null, "To save you some time, if you have already used the details of a debtor, you can search for them and select them on this page."),
                React.createElement("p", null,
                    "This will prepopulate the next two pages with their information.",
                    ' ',
                    React.createElement(Hint_1.default, { id: "" }, "A debtor is person or an organisation that - in relation to your business - owes you money"))),
            React.createElement("div", { className: "flex-row-wrap" },
                React.createElement("input", { type: "text", placeholder: "Enter a debtor's name", value: props.search_text, onChange: function (e) {
                        props.searchAndPopulateDebtors(e.target.value, props.setFieldValue, props.values);
                    } }),
                React.createElement(react_router_dom_1.Link, { to: "page3", className: "button red-button", id: 'active', style: { marginLeft: '2em' } }, "Skip")),
            React.createElement(DebtorTilesContainer_1.default, { debtors: props.debtors, setDebtor: props.setDebtor, values: props.values, setFieldValue: props.setFieldValue, setSelectedDebtor: props.setSelectedDebtor })),
        React.createElement("div", { className: "link_navigation flex-row" },
            React.createElement(react_router_dom_1.Link, { to: "/dashboard/debtwizard/page1", className: "button nav-button-grey" }, "Previous"),
            React.createElement(react_router_dom_1.Link, { to: "/dashboard/debtwizard/page3", className: "button nav-button-grey", id: 'active' }, "Next"))));
};
exports.default = AddDebtWizardPage2;
