"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_dropzone_1 = require("react-dropzone");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var core_1 = require("@rradar/core");
var FileInput = function (props) {
    var _a = __read(React.useState(''), 2), fileName = _a[0], setFileName = _a[1];
    React.useEffect(function () {
        if (props.value !== null) {
            setFileName(props.value);
        }
    }, [props.value]);
    var setText = function (isDragActive, isDragReject) {
        if ((!isDragActive && fileName === '') || (isDragActive && !isDragReject)) {
            return 'Click here or drag files to upload';
        }
        if (!isDragActive && fileName !== '') {
            return fileName;
        }
        if (isDragReject) {
            return 'File type not accepted, sorry!';
        }
    };
    return (React.createElement(react_dropzone_1.default, { onDropRejected: function () {
            return (0, core_1.ToastNotification)({ type: 'error', message: 'Only .docx, .png. .jpeg amd .pdf accepted' });
        }, accept: 'image/jpeg, image/png, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document', multiple: false, onDrop: function (acceptedFiles) {
            setFileName(acceptedFiles[0].name);
            props.setFileUpload(acceptedFiles[0]);
        } }, function (_a) {
        var getRootProps = _a.getRootProps, getInputProps = _a.getInputProps, isDragActive = _a.isDragActive, isDragReject = _a.isDragReject;
        return (React.createElement("section", { style: {
                backgroundColor: '#555',
                color: '#FFF',
                width: '240px',
                padding: '2em',
                marginTop: '1em',
                cursor: 'pointer',
            } },
            React.createElement("div", __assign({ className: "flex-column", style: { alignItems: 'center' } }, getRootProps()),
                React.createElement("input", __assign({}, getInputProps())),
                React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'file-upload'], size: '4x' }),
                React.createElement("p", { style: { padding: '0.5em 0.5em 0 0.5em', textAlign: 'center' } }, setText(isDragActive, isDragReject)))));
    }));
};
exports.default = FileInput;
