"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebtExtensionContent = void 0;
var React = require("react");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var InformationTip_1 = require("../../../../../../../common/InformationTip");
var DebtExtensionForm_1 = require("./DebtExtensionForm");
var LoadingContainer_1 = require("../../../../../../../common/LoadingContainer");
function DebtExtensionContent(props) {
    return (React.createElement("section", null,
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "modal-header flex-row" },
                React.createElement("h3", null, "Approve a Debt Extension"),
                React.createElement("div", { className: "flex-row" },
                    React.createElement("div", { className: "isPointer", onClick: function () {
                            props.handleExtensionModal();
                        } },
                        React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'times'] }))))),
        React.createElement("div", { className: "container" },
            React.createElement("p", null, "This will give your debtor 30 more days to pay their debt, starting from the date you enter below."),
            React.createElement(InformationTip_1.default, null,
                React.createElement("div", null, "It is your debtor\u2019s legal right to ask for this extra time, and you must grant it if asked.")),
            React.createElement("p", null,
                "If the debtor has asked for more information, you must enter the date on which they have received that information. ",
                React.createElement("strong", null, "Note: you need their confirmation that they have received it.")),
            React.createElement("p", null, "If the debtor has simply asked for more time, enter the date on which they asked for the extra time. They will be given another 30 days from this date to pay their debt."),
            React.createElement(LoadingContainer_1.default, { render: function (config) {
                    return React.createElement(DebtExtensionForm_1.DebtExtensionForm, __assign({}, config, props));
                } }))));
}
exports.DebtExtensionContent = DebtExtensionContent;
