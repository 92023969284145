"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var formik_1 = require("formik");
var DatePicker_1 = require("../DatePicker/DatePicker");
var TextField_1 = require("../../../../common/TextField");
var scrollToTop_1 = require("../../../../../utils/scrollToTop");
var AddDebtWizardPage6 = function (props) {
    (0, scrollToTop_1.default)();
    return (React.createElement("div", { className: "add-debt-wizard-page flex-column" },
        React.createElement("div", { className: "add-debt-wizard-form-contents" },
            React.createElement("div", { className: "add-debt-wizard-form-description" },
                React.createElement("h2", null, "Invoice details"),
                React.createElement("p", null, "Please tell us about the invoice, and when you have contacted the debtor.")),
            React.createElement("div", { className: "flex-row-wrap" },
                React.createElement("div", { className: "form-panel" },
                    React.createElement(TextField_1.default, { type: "text", name: "invoice_reference", validator: props.validator, label: 'What is your invoice number for this debt?', value: props.values.invoice_reference, placeholder: 'Invoice Ref', disabled: false }),
                    React.createElement(formik_1.ErrorMessage, { name: "invoice_reference" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
                    React.createElement(DatePicker_1.default, { name: "invoice_sent_date", validator: props.validator, setFieldValue: props.setFieldValue, value: props.values.invoice_sent_date, label: 'When was the invoice sent?' }),
                    React.createElement(formik_1.ErrorMessage, { name: "invoice_sent_date" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); })),
                React.createElement("div", { className: "form-panel" },
                    React.createElement(DatePicker_1.default, { name: "invoice_due_date", validator: props.validator, setFieldValue: props.setFieldValue, value: props.values.invoice_due_date, label: 'When was payment due?' }),
                    React.createElement(formik_1.ErrorMessage, { name: "invoice_due_date" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
                    React.createElement(DatePicker_1.default, { name: "reminder_sent_date", validator: props.validator, setFieldValue: props.setFieldValue, value: props.values.reminder_sent_date, label: 'If you have sent reminders, when was the last of these sent?' })))),
        React.createElement("div", { className: "link_navigation flex-row" },
            React.createElement(react_router_dom_1.Link, { to: "/dashboard/debtwizard/page6", className: "button nav-button-grey" }, "Previous"),
            React.createElement(react_router_dom_1.Link, { to: "/dashboard/debtwizard/page8", className: "button nav-button-grey", id: 'active' }, "Next"))));
};
exports.default = AddDebtWizardPage6;
