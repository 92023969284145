"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_1 = require("react-router");
var formik_1 = require("formik");
var AddDebtWizardPage1_1 = require("./AddDebtWizardPages/AddDebtWizardPage1");
var AddDebtWizardPage2_1 = require("./AddDebtWizardPages/AddDebtWizardPage2");
var AddDebtWizardPage3_1 = require("./AddDebtWizardPages/AddDebtWizardPage3");
var AddDebtWizardPage4_1 = require("./AddDebtWizardPages/AddDebtWizardPage4");
var AddDebtWizardPage5_1 = require("./AddDebtWizardPages/AddDebtWizardPage5");
var AddDebtWizardPage6_1 = require("./AddDebtWizardPages/AddDebtWizardPage6");
var AddDebtWizardPage7_1 = require("./AddDebtWizardPages/AddDebtWizardPage7");
var AddDebtWizardPage8_1 = require("./AddDebtWizardPages/AddDebtWizardPage8");
var AddDebtWizardPage9_1 = require("./AddDebtWizardPages/AddDebtWizardPage9");
var DebtorSelected_1 = require("./Debtor/DebtorSelected");
var AddDebtWizardRouter = function (props) {
    var _a = __read(React.useState({ id: null }), 2), selectedDebtor = _a[0], setSelectedDebtor = _a[1];
    var _b = __read(React.useState(25), 2), pounds = _b[0], setPounds = _b[1];
    var _c = __read(React.useState('00'), 2), pence = _c[0], setPence = _c[1];
    var handleOriginalAmount = function () {
        var amountPounds = pounds.toString();
        var amountPence = pence.toString();
        props.setFieldValue('original_amount', parseFloat("".concat(amountPounds, ".").concat(amountPence)));
    };
    React.useEffect(function () {
        handleOriginalAmount();
    }, [pounds, pence]);
    return (React.createElement(formik_1.Form, { className: "flex-column add-debt-wizard-form" },
        React.createElement(DebtorSelected_1.default, { debtor: selectedDebtor, setDebtor: props.setDebtor, values: props.values, setFieldValue: props.setFieldValue, setSelectedDebtor: setSelectedDebtor }),
        React.createElement(react_router_1.Routes, null,
            React.createElement(react_router_1.Route, { path: "/page1", element: React.createElement(AddDebtWizardPage1_1.default, { setTCModal: props.setTCModal }) }),
            React.createElement(react_router_1.Route, { path: "/page2", element: React.createElement(AddDebtWizardPage2_1.default, { searchAndPopulateDebtors: props.searchAndPopulateDebtors, debtors: props.debtors, setDebtor: props.setDebtor, setSelectedDebtor: setSelectedDebtor, values: props.values, setFieldValue: props.setFieldValue, search_text: props.search_text }) }),
            React.createElement(react_router_1.Route, { path: "/page3", element: React.createElement(AddDebtWizardPage3_1.default, { values: props.values, setFieldValue: props.setFieldValue, validator: props.validator }) }),
            React.createElement(react_router_1.Route, { path: "/page4", element: React.createElement(AddDebtWizardPage4_1.default, { values: props.values, setFieldValue: props.setFieldValue, validator: props.validator }) }),
            React.createElement(react_router_1.Route, { path: "/page5", element: React.createElement(AddDebtWizardPage5_1.default, { values: props.values, validator: props.validator, setFieldValue: props.setFieldValue }) }),
            React.createElement(react_router_1.Route, { path: "/page6", element: React.createElement(AddDebtWizardPage6_1.default, { values: props.values, validator: props.validator, setFieldValue: props.setFieldValue, pounds: pounds, setPounds: setPounds, pence: pence, setPence: setPence }) }),
            React.createElement(react_router_1.Route, { path: "/page7", element: React.createElement(AddDebtWizardPage7_1.default, { values: props.values, validator: props.validator, setFieldValue: props.setFieldValue }) }),
            React.createElement(react_router_1.Route, { path: "/page8", element: React.createElement(AddDebtWizardPage8_1.default, { values: props.values, validator: props.validator, setFieldValue: props.setFieldValue, setFileUpload: props.setFileUpload }) }),
            React.createElement(react_router_1.Route, { path: "/page9", element: React.createElement(AddDebtWizardPage9_1.default, { values: props.values, validator: props.validator, setFieldValue: props.setFieldValue, setFileUpload: props.setFileUpload, handleSubmit: props.handleSubmit, debts_remaining: props.my_company !== null ? props.my_company.debts_overview.debts_currently_creatable : 0, errors: props.errors, pounds: pounds, setPounds: setPounds, pence: pence, setPence: setPence, disabled: props.disabled }) }),
            React.createElement(react_router_1.Route, { path: "*", element: React.createElement(react_router_1.Navigate, { replace: true, to: '/dashboard/debtwizard/page1' }) }))));
};
exports.default = AddDebtWizardRouter;
