"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var core_1 = require("@rradar/core");
var TrackingboxContainer_1 = require("./components/trackingbox/TrackingboxContainer");
var DebtsTableContainer_1 = require("../../Tables/debts/DebtsTableContainer");
var getStatusFilters_1 = require("../../../utils/getStatusFilters");
var recoverInterface_1 = require("../../../utils/recoverInterface");
var redirectOnRole_1 = require("../../../utils/redirectOnRole");
var RecoverJoyride_1 = require("../../common/Joyride/RecoverJoyride");
var getState_1 = require("../../../utils/getState");
var Dashboard = /** @class */ (function (_super) {
    __extends(Dashboard, _super);
    function Dashboard() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            company: { debts_overview: undefined },
            debts_overview: {
                debts_created_this_period: 0,
                total_amount_tracked: '0',
                debts_currently_creatable: 0,
                end_of_month: 'n/a',
                total_amount_recovered: '',
                amounts_breakdown: {},
                counts_breakdown: {},
                end_of_period: '',
                active_debts_count: 0,
            },
            debtFilters: [],
        };
        _this.getData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, getStatusFilters_1.default)().then(function (debtFilters) {
                            _this.setState({
                                debtFilters: debtFilters,
                            });
                        });
                        return [4 /*yield*/, (0, recoverInterface_1.default)()
                                .companies()
                                .my_company()
                                .get()
                                .then(function (response) {
                                _this.setState({ company: response.my_company });
                            })
                                .catch(function (error) { return (0, core_1.ToastNotification)({ type: 'error', message: error.message }); })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, redirectOnRole_1.default)(true);
                        return [4 /*yield*/, this.getData().then(function () {
                                _this.setState({ debts_overview: _this.state.company.debts_overview });
                            })];
                    case 1:
                        _a.sent();
                        console.log(this.state.company.debts_overview);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.render = function () {
            return (React.createElement("div", { className: "dashboard" },
                React.createElement("div", { className: "dashboard-body" },
                    React.createElement("div", { className: "container" },
                        React.createElement(TrackingboxContainer_1.default, { totalValue: _this.state.debts_overview.total_amount_tracked, activeDebts: _this.state.debts_overview.active_debts_count, creatableDebts: _this.state.debts_overview.debts_currently_creatable, periodEnd: _this.state.debts_overview.end_of_period, total_amount_recovered: _this.state.debts_overview.total_amount_recovered, join_date: _this.state.company.created_at, bank_details: _this.state.company['bank_account_details_present?'], breakdown: {
                                amounts: _this.state.debts_overview.amounts_breakdown,
                                counts: _this.state.debts_overview.counts_breakdown,
                            } }),
                        React.createElement(DebtsTableContainer_1.default, { tableFilters: _this.state.debtFilters }))),
                (0, getState_1.default)().user.user_profile.joyride_turned_off_at === null ? React.createElement(RecoverJoyride_1.default, null) : null));
        };
        return _this;
    }
    return Dashboard;
}(React.Component));
exports.default = Dashboard;
