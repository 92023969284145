"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var lodash_1 = require("lodash");
var core_1 = require("@rradar/core");
var getState_1 = require("../../../utils/getState");
var CompanyDetails_1 = require("./components/CompanyDetails");
var CompanyBankDetails_1 = require("./components/CompanyBankDetails");
var CompanyObservations_1 = require("./components/CompanyObservations");
var CompanyDebts_1 = require("./components/CompanyDebts");
var recoverInterface_1 = require("../../../utils/recoverInterface");
var EditCompany = /** @class */ (function (_super) {
    __extends(EditCompany, _super);
    function EditCompany() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            company: {},
            company_bank_details: {},
            tab: 'company',
            debts: {},
            redirectAfterSubmission: false,
        };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!window.location.href.includes('my_company')) return [3 /*break*/, 4];
                        return [4 /*yield*/, (0, recoverInterface_1.default)()
                                .companies()
                                .my_company()
                                .get()
                                .then(function (response) {
                                return _this.setState({ company: response.my_company }, function () {
                                    if (_this.state.company['bank_account_details_present?'] === true &&
                                        (0, getState_1.default)().user.user_primary === false) {
                                        _this.setState({ tab: 'company' }, function () { });
                                    }
                                    else if (_this.state.company['bank_account_details_present?'] === false &&
                                        (0, getState_1.default)().user.user_primary === true) {
                                        _this.setState({ tab: 'bank_details' }, function () { });
                                    }
                                });
                            })
                                .catch(function (error) { return (0, core_1.ToastNotification)({ type: 'error', message: error.message }); })];
                    case 1:
                        _a.sent();
                        if (!((0, getState_1.default)().user.user_primary === true)) return [3 /*break*/, 3];
                        return [4 /*yield*/, (0, recoverInterface_1.default)()
                                .companies()
                                .my_company_bank_details()
                                .get()
                                .then(function (response) {
                                return _this.setState({
                                    company_bank_details: response.my_company_bank_details,
                                });
                            })
                                .catch(function (error) { return (0, core_1.ToastNotification)({ type: 'error', message: error.message }); })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [3 /*break*/, 7];
                    case 4: return [4 /*yield*/, (0, recoverInterface_1.default)()
                            .companies()
                            .get(parseInt(this.props.id, 10))
                            .then(function (response) { return _this.setState({ company: response.company }); })
                            .catch(function (error) { return (0, core_1.ToastNotification)({ type: 'error', message: error.message }); })];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, (0, recoverInterface_1.default)()
                                .companies()
                                .access(parseInt(this.props.id, 10))
                                .bank_details()
                                .get()
                                .then(function (response) {
                                return _this.setState({
                                    company_bank_details: response.company_bank_details,
                                });
                            })
                                .catch(function (error) { return (0, core_1.ToastNotification)({ type: 'error', message: error.message }); })];
                    case 6:
                        _a.sent();
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        }); };
        _this.submitDetails = function (body) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!((0, getState_1.default)().user.role === 'customer')) return [3 /*break*/, 2];
                        return [4 /*yield*/, (0, recoverInterface_1.default)()
                                .companies()
                                .my_company_bank_details()
                                .put(body)
                                .then(function (response) {
                                (0, core_1.ToastNotification)({ type: 'success', message: response.message });
                                setTimeout(function () {
                                    if (_this.state.redirectAfterSubmission === true) {
                                        window.location.href = '/dashboard';
                                    }
                                }, 2000);
                            })
                                .catch(function (error) { return (0, core_1.ToastNotification)({ type: 'error', message: error.message }); })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, (0, recoverInterface_1.default)()
                            .companies()
                            .access(parseInt(this.props.id, 10))
                            .bank_details()
                            .update(body)
                            .then(function (response) {
                            (0, core_1.ToastNotification)({ type: 'success', message: response.message });
                            setTimeout(function () {
                                if (_this.state.redirectAfterSubmission === true) {
                                    window.location.href = '/admin/companies';
                                }
                            }, 2000);
                        })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.switchTab = function (value) {
            _this.setState({ tab: value });
        };
        _this.setRedirectAfterSubmission = function (value, method) {
            _this.setState({ redirectAfterSubmission: value }, function () { return method; });
        };
        _this.getRedirectAfterSubmission = function () {
            return _this.state.redirectAfterSubmission;
        };
        _this.submitObservation = function (body) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, (0, recoverInterface_1.default)()
                            .companies()
                            .edit(this.state.company.id, body)
                            .then(function (response) { return (0, core_1.ToastNotification)({ type: 'success', message: response.message }); })
                            .catch(function (error) { return (0, core_1.ToastNotification)({ type: 'error', message: error.message }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.renderTab = function () {
            switch (_this.state.tab) {
                case 'company':
                    return React.createElement(CompanyDetails_1.default, { data: _this.state.company });
                case 'bank_details':
                    return (React.createElement(CompanyBankDetails_1.default, { data: _this.state.company_bank_details, onSubmit: _this.submitDetails, id: _this.state.company.id, setRedirect: _this.setRedirectAfterSubmission, getRedirect: _this.getRedirectAfterSubmission }));
                case 'debts':
                    return React.createElement(CompanyDebts_1.default, { id: _this.state.company.id });
                case 'observations':
                    return (React.createElement(CompanyObservations_1.default, { submitObservation: _this.submitObservation, observations: _this.state.company.observations !== null && _this.state.company.observations !== undefined
                            ? _this.state.company.observations
                            : '' }));
            }
        };
        _this.renderTabButtons = function () {
            return (React.createElement("div", { className: "tab-buttons flex-row" },
                React.createElement("button", { type: "button", className: "tab-button ".concat(_this.state.tab === 'company' ? 'active_tab' : 'inactive_tab'), onClick: function () {
                        _this.switchTab('company');
                    } }, "Company"),
                ((0, getState_1.default)().user.role === 'customer' && (0, getState_1.default)().user.user_primary === true) ||
                    (0, getState_1.default)().user.role !== 'customer' ? (React.createElement("button", { type: "button", className: "tab-button ".concat(_this.state.tab === 'bank_details' ? 'active_tab' : 'inactive_tab'), onClick: function () {
                        _this.switchTab('bank_details');
                    } }, "Bank Details")) : null,
                (0, getState_1.default)().user.role !== 'customer' ? (React.createElement("button", { type: "button", className: "tab-button ".concat(_this.state.tab === 'debts' ? 'active_tab' : 'inactive_tab'), onClick: function () {
                        _this.switchTab('debts');
                    } }, "Debts")) : null,
                (0, getState_1.default)().user.role !== 'customer' ? (React.createElement("button", { type: "button", className: "tab-button ".concat(_this.state.tab === 'observations' ? 'active_tab' : 'inactive_tab'), onClick: function () {
                        _this.switchTab('observations');
                    } }, "Observations")) : null));
        };
        _this.ifCompanyExits = function () {
            return (React.createElement("div", null,
                _this.renderTabButtons(),
                _this.state.company['bank_account_details_present?'] === false && (0, getState_1.default)().user.role === 'customer' ? (React.createElement("div", { className: "toDo" }, "Please enter your company's bank details before doing anything else")) : null,
                _this.state.company['bank_account_details_present?'] === false &&
                    (0, getState_1.default)().user.role === 'customer' &&
                    !(0, getState_1.default)().user.user_primary ? (React.createElement("div", { className: "toDo" }, "You must be the primary user of your company to add details")) : null,
                _this.state.company['bank_account_details_present?'] === false &&
                    ((0, getState_1.default)().user.role === 'admin' || (0, getState_1.default)().user.role === 'legal') ? (React.createElement("div", { className: "toDo" }, "This company has no bank details attached to their recover account.")) : null,
                React.createElement("div", null, _this.renderTab())));
        };
        _this.render = function () {
            return (React.createElement("div", { className: "container" },
                React.createElement("div", { className: "dashboard-body" },
                    React.createElement("div", { className: "dashboard-header flex-column" },
                        React.createElement(core_1.Heading1, { title: _this.state.company.name })),
                    !(0, lodash_1.isEmpty)(_this.state.company)
                        ? _this.ifCompanyExits()
                        : 'Please contact rradar as it appears you have no company')));
        };
        return _this;
    }
    return EditCompany;
}(React.Component));
exports.default = EditCompany;
