"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var formik_1 = require("formik");
var TextField_1 = require("../../../../common/TextField");
var scrollToTop_1 = require("../../../../../utils/scrollToTop");
var NameField_1 = require("./NameField/NameField");
var AddDebtWizardPage4 = function (props) {
    (0, scrollToTop_1.default)();
    return (React.createElement("div", { className: "add-debt-wizard-page flex-column" },
        React.createElement("div", { className: "add-debt-wizard-form-contents flex-column" },
            React.createElement("div", { className: "wizard-form-description" },
                React.createElement("h2", null, "Debtor details"),
                React.createElement("p", null, "To help you recover the debt please complete the following fields with details of the person or the company you wish to recover the debt from.")),
            React.createElement("div", { className: "flex-row-wrap" },
                React.createElement("div", { className: "form-panel" },
                    React.createElement(NameField_1.default, { values: props.values, setFieldValue: props.setFieldValue, validator: props.validator }),
                    React.createElement(TextField_1.default, { name: "trading_name", value: props.values.trading_name, validator: props.validator, label: 'What is the trading name of the debtor?', hint: 'This should be the name registered with companies house.', placeholder: 'Joe Bloggs Limited', onChange: function (value) {
                            props.setFieldValue('trading_name', value);
                        }, disabled: props.values.debtor_id !== null }),
                    React.createElement(formik_1.ErrorMessage, { name: "trading_name" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); })),
                React.createElement("div", { className: "form-panel" },
                    React.createElement(TextField_1.default, { name: "email", value: props.values.email, validator: props.validator, label: 'What is the email address you have for this debtor?', hint: "The email of either the individual or the debtor's representative or accounts team.", placeholder: 'name@example.co.uk', onChange: function (value) {
                            props.setFieldValue('email', value);
                        }, disabled: props.values.debtor_id !== null }),
                    React.createElement(formik_1.ErrorMessage, { name: "email" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
                    React.createElement(TextField_1.default, { name: "companies_house_number", value: props.values.companies_house_number, validator: props.validator, label: 'What is the company number of the debtor?', hint: 'The companies house number for this company.', placeholder: '07738271', onChange: function (value) {
                            props.setFieldValue('companies_house_number', value);
                        }, disabled: props.values.debtor_id !== null }),
                    React.createElement(formik_1.ErrorMessage, { name: "companies_house_number" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); })),
                React.createElement("div", { className: "form-panel" },
                    React.createElement("label", { className: "required" }, "What is the debtor's postal address?"),
                    React.createElement(TextField_1.default, { name: "address_1", value: props.values.address_1, validator: props.validator, placeholder: 'Address Line 1', onChange: function (value) {
                            props.setFieldValue('address_1', value);
                        }, disabled: props.values.debtor_id !== null }),
                    React.createElement(TextField_1.default, { name: "address_2", value: props.values.address_2, validator: props.validator, placeholder: 'Address Line 2', onChange: function (value) {
                            props.setFieldValue('address_2', value);
                        }, disabled: props.values.debtor_id !== null }),
                    React.createElement(TextField_1.default, { name: "city", value: props.values.city, validator: props.validator, placeholder: 'City', onChange: function (value) {
                            props.setFieldValue('city', value);
                        }, disabled: props.values.debtor_id !== null }),
                    React.createElement(TextField_1.default, { name: "post_code", value: props.values.post_code, validator: props.validator, placeholder: 'Postcode', onChange: function (value) {
                            props.setFieldValue('post_code', value);
                        }, disabled: props.values.debtor_id !== null }),
                    React.createElement(formik_1.ErrorMessage, { name: "address_1" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
                    React.createElement(formik_1.ErrorMessage, { name: "address_2" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
                    React.createElement(formik_1.ErrorMessage, { name: "city" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
                    React.createElement(formik_1.ErrorMessage, { name: "post_code" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); })))),
        React.createElement("div", { className: "link_navigation flex-row" },
            React.createElement(react_router_dom_1.Link, { to: "/dashboard/debtwizard/page4", className: "button nav-button-grey" }, "Previous"),
            React.createElement(react_router_dom_1.Link, { to: "/dashboard/debtwizard/page6", className: "button nav-button-grey", id: 'active' }, "Next"))));
};
exports.default = AddDebtWizardPage4;
