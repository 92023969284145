"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var getState_1 = require("../../../../../utils/getState");
var TrackLimitNumbers = function (props) {
    return (React.createElement("div", { className: 'track-limit-numbers' },
        React.createElement("span", { className: "limit-sub-title" }, "You are tracking: "),
        React.createElement("div", { className: "limit-title" },
            props.values.amount || 0,
            " ",
            props.values.amount === 1 ? 'Debt' : 'Debts'),
        React.createElement("div", null,
            React.createElement("button", { type: "button", className: "button white-button createDebt", onClick: function () {
                    if (props.values.of !== 0) {
                        window.location.href = '/dashboard/debtwizard';
                    }
                    else {
                        window.open("".concat((0, getState_1.default)().dashboard_ui, "/store/package-tiers/").concat((0, getState_1.default)().tier_group));
                    }
                } }, props.values.of !== 0 ? 'Recover New Debt' : 'Buy Recoveries')),
        React.createElement("div", null,
            "You can submit ",
            props.values.of || 0,
            " debts before ",
            props.values.dateUntil)));
};
exports.default = TrackLimitNumbers;
