"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var RadioButton_1 = require("../../../../common/RadioButton");
var ProtocolRadio = function (props) {
    return (React.createElement("div", null,
        React.createElement("label", { className: 'flex-row' },
            React.createElement(RadioButton_1.default, { checked: props.value === 'england_wales', value: 'england_wales', name: 'england_wales', handleRadioChange: function () {
                    props.setFieldValue('jurisdiction', 'england_wales');
                } }),
            "England and Wales"),
        React.createElement("label", { className: 'flex-row' },
            React.createElement(RadioButton_1.default, { checked: props.value === 'scotland', value: 'scotland', name: 'scotland', handleRadioChange: function () {
                    props.setFieldValue('jurisdiction', 'scotland');
                } }),
            "Scotland")));
};
exports.default = ProtocolRadio;
