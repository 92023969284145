"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_confirm_alert_1 = require("react-confirm-alert");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var DebtActionButton_1 = require("./DebtActionButton");
var getState_1 = require("../../../../../../utils/getState");
var DebtActionButtonContainer = function (props) {
    var editButton = function (disabled) {
        return (React.createElement(DebtActionButton_1.default, { icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'pencil-alt'] }), text: 'Edit', tip: 'Edit this debt.', handleClick: function () { return (window.location.href = "/dashboard/debts/".concat(props.reference, "/edit")); }, disabled: disabled }));
    };
    var submitButton = function (disabled) {
        return (React.createElement(DebtActionButton_1.default, { icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'paper-plane'] }), text: 'Submit', tip: 'Submit this debt for recovery.', handleClick: function () {
                (0, react_confirm_alert_1.confirmAlert)({
                    title: 'Submit Debt',
                    message: 'Are you sure you wish to submit this debt?  You can no longer edit once it has been submitted.',
                    buttons: [
                        {
                            label: 'Cancel',
                            onClick: function () {
                                return null;
                            },
                        },
                        {
                            label: 'Confirm',
                            onClick: function () { return props.handleCustomerAction('submit'); },
                        },
                    ],
                });
            }, disabled: disabled }));
    };
    var reconfirmButton = function (disabled) {
        return (React.createElement(DebtActionButton_1.default, { icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'forward'] }), tip: 'Advance this recovery to Stage 2', text: 'Advance', handleClick: function () {
                (0, react_confirm_alert_1.confirmAlert)({
                    title: 'Advance Debt',
                    message: 'Do you want to keep recovering this debt?  Clicking confirm will progress the debt to stage two',
                    buttons: [
                        {
                            label: 'Cancel',
                            onClick: function () {
                                return null;
                            },
                        },
                        {
                            label: 'Confirm',
                            onClick: function () { return props.handleCustomerAction('reconfirm'); },
                        },
                    ],
                });
            }, disabled: disabled }));
    };
    var revertButton = function (disabled) {
        return (React.createElement(DebtActionButton_1.default, { icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'backward'] }), text: 'Revert', tip: 'Send this recovery back for corrections.', handleClick: function () {
                props.handleRevertModal();
            }, disabled: disabled }));
    };
    var declineButton = function (disabled) {
        return (React.createElement(DebtActionButton_1.default, { icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'ban'] }), text: 'Decline', tip: 'Decline this recovery as unsuitable.', handleClick: function () {
                props.handleDebtDeclineModal();
            }, disabled: disabled }));
    };
    var adviseButton = function (disabled) {
        return (React.createElement(DebtActionButton_1.default, { icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'comments'] }), text: 'Comment', tip: 'Send a comment.', handleClick: function () {
                props.handleDebtAdvisoryCommentModal();
            }, disabled: disabled }));
    };
    var commentButton = function (disabled) {
        return (React.createElement(DebtActionButton_1.default, { icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'comments'] }), text: 'Comment', tip: 'Send a comment.', handleClick: function () {
                props.handleDebtAdvisoryCommentModal();
            }, disabled: disabled }));
    };
    var requestButton = function (disabled) {
        return (React.createElement(DebtActionButton_1.default, { icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'forward'] }), text: 'Advance', tip: 'Request a no-obligation consultation for options beyond rradarrecover.', handleClick: function () {
                props.handleRequestLitigationModal();
            }, disabled: disabled }));
    };
    var viewButton = function (disabled) {
        return (React.createElement(DebtActionButton_1.default, { icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'file-alt'] }), text: 'Demand', tip: 'Generate the next demand letter for this recovery.', handleClick: function () {
                props.goToDemands();
            }, disabled: disabled }));
    };
    var resolveButton = function (disabled) {
        return (React.createElement(DebtActionButton_1.default, { icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'handshake'] }), tip: 'Mark this recovery as successful.', text: 'Resolve', handleClick: function () {
                (0, react_confirm_alert_1.confirmAlert)({
                    title: 'Recover Debt',
                    message: 'Are you sure you wish to mark this debt as recovered? This will permanently close the recovery process, and cannot be undone.',
                    buttons: [
                        {
                            label: 'Cancel',
                            onClick: function () {
                                return null;
                            },
                        },
                        {
                            label: 'Confirm',
                            onClick: function () {
                                return (0, getState_1.default)().user.role === 'customer'
                                    ? props.handleCustomerAction('resolve')
                                    : props.handleAdminAction('resolve');
                            },
                        },
                    ],
                });
            }, disabled: disabled }));
    };
    var extensionButton = function (disabled) {
        return (React.createElement(DebtActionButton_1.default, { disabled: disabled, tip: 'Add a 30-day extension to the time allowed for payment.', icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'arrow-to-right'] }), text: 'Extend', handleClick: function () {
                props.handleExtensionModal();
            } }));
    };
    var closeButton = function (disabled) {
        return (React.createElement(DebtActionButton_1.default, { icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'trash-alt'] }), text: 'Close', tip: 'Close this recovery as unsuccessful.', handleClick: function () {
                props.handleCloseModal();
            }, disabled: disabled }));
    };
    var disadviseButton = function (disabled) {
        return (React.createElement(DebtActionButton_1.default, { icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'times'] }), text: 'Disadvise', tip: 'Advise against litigation.', handleClick: function () {
                props.handleAdviseAgainstModal();
            }, disabled: disabled }));
    };
    var enterButton = function (disabled) {
        return (React.createElement(DebtActionButton_1.default, { icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'check'] }), tip: 'Accept litigation.', text: 'Accept', handleClick: function () {
                props.handleAcceptLitigationModal();
            }, disabled: disabled }));
    };
    var handleCustomerButtons = function () {
        var buttonOrder = {
            enabled: [],
            disabled: [],
        };
        if (props.status === 'reverted' || props.status === 'unsubmitted') {
            buttonOrder.enabled.push(editButton(false));
            buttonOrder.enabled.push(submitButton(false));
        }
        else {
            buttonOrder.disabled.push(editButton(true));
            buttonOrder.disabled.push(submitButton(true));
        }
        if (props.canExtend) {
            buttonOrder.enabled.push(extensionButton(false));
        }
        else {
            buttonOrder.disabled.push(extensionButton(true));
        }
        if (props.status === 'stage_1_ended') {
            buttonOrder.enabled.push(reconfirmButton(false));
        }
        else if (props.status === 'unsubmitted' || props.status === 'reverted' || props.status === 'stage_1') {
            buttonOrder.disabled.push(reconfirmButton(true));
        }
        if (props.status !== 'recovered' && props.status !== 'closed') {
            buttonOrder.enabled.push(commentButton(false));
        }
        else {
            buttonOrder.disabled.push(commentButton(true));
        }
        if (props.status !== 'recovered' &&
            props.status !== 'closed' &&
            props.status !== 'litigation_requested' &&
            props.status !== 'in_litigation') {
            buttonOrder.enabled.push(resolveButton(false));
            buttonOrder.enabled.push(closeButton(false));
        }
        else {
            buttonOrder.disabled.push(resolveButton(true));
            buttonOrder.disabled.push(closeButton(true));
        }
        if (props.status === 'overdue') {
            buttonOrder.enabled.push(requestButton(false));
        }
        else if (props.status !== 'unsubmitted' &&
            props.status !== 'reverted' &&
            props.status !== 'stage_1' &&
            props.status !== 'stage_1_ended') {
            buttonOrder.disabled.push(requestButton(true));
        }
        return (React.createElement.apply(React, __spreadArray(__spreadArray(["div", { className: "flex-row-wrap" }], __read(buttonOrder.enabled), false), __read(buttonOrder.disabled), false)));
    };
    var handleAdminButtons = function () {
        var buttonOrder = {
            enabled: [],
            disabled: [],
        };
        if (props.status === 'submitted') {
            buttonOrder.enabled.push(revertButton(false));
            buttonOrder.enabled.push(declineButton(false));
        }
        else {
            buttonOrder.disabled.push(revertButton(true));
            buttonOrder.disabled.push(declineButton(true));
        }
        // if (props.status !== "paused" && props.status !== "recovered" && props.status !== "closed" && props.status !== "litigation_requested" && props.status !== "in_litigation") {
        //   buttonOrder.enabled.push(pauseButton(false))
        // }
        // else {
        //   buttonOrder.disabled.push(pauseButton(true))
        // }
        // if (props.status === "paused") {
        //   buttonOrder.enabled.push(resumeButton(false))
        // }
        // else {
        //   buttonOrder.disabled.push(resumeButton(true))
        // }
        if (props.status !== 'recovered' && props.status !== 'closed') {
            buttonOrder.enabled.push(adviseButton(false));
        }
        else {
            buttonOrder.disabled.push(adviseButton(true));
        }
        if (props.status === 'submitted' || props.status === 'stage_2_authorised') {
            buttonOrder.enabled.push(viewButton(false));
        }
        else {
            buttonOrder.disabled.push(viewButton(true));
        }
        if (props.status === 'litigation_requested') {
            buttonOrder.enabled.push(enterButton(false));
            buttonOrder.enabled.push(disadviseButton(false));
        }
        else {
            buttonOrder.disabled.push(enterButton(true));
            buttonOrder.disabled.push(disadviseButton(true));
        }
        if (props.status !== 'recovered' &&
            props.status !== 'closed' &&
            props.status !== 'litigation_requested' &&
            props.status !== 'in_litigation' &&
            props.status !== 'declined') {
            buttonOrder.enabled.push(resolveButton(false));
            buttonOrder.enabled.push(closeButton(false));
        }
        else {
            buttonOrder.disabled.push(resolveButton(true));
            buttonOrder.disabled.push(closeButton(true));
        }
        return (React.createElement.apply(React, __spreadArray(__spreadArray(["div", { className: "flex-row-wrap" }], __read(buttonOrder.enabled), false), __read(buttonOrder.disabled), false)));
    };
    return (React.createElement("div", { className: 'demand-list' },
        React.createElement("header", null,
            React.createElement("h2", null, "New Action")),
        React.createElement("div", { className: 'notes-container', style: { padding: '0.5em' } }, (0, getState_1.default)().user.role === 'customer' ? handleCustomerButtons() : handleAdminButtons())));
};
exports.default = DebtActionButtonContainer;
