"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDebtStatus = void 0;
var React = require("react");
var Cookie = require("js-cookie");
var core_1 = require("@rradar/core");
var getState_1 = require("../../../../utils/getState");
var debtSeperator_1 = require("../../../../utils/debtSeperator");
var DebtStatus_1 = require("./components/DebtStatus");
var DebtAmount_1 = require("./components/DebtAmount");
var ExistingDemands_1 = require("./components/ExistingDemands");
var DebtInformation_1 = require("./components/DebtInformation");
var DebtNeedAction_1 = require("./components/DebtNeedAction/DebtNeedAction");
var LoadingSpinner_1 = require("../../../common/LoadingSpinner/LoadingSpinner");
var recoverInterface_1 = require("../../../../utils/recoverInterface");
var DebtActionButtonContainer_1 = require("./components/DebtActionButtons/DebtActionButtonContainer");
var DebtExtensionModal_1 = require("./components/DebtModals/DebtExtensionModal");
var DebtRevertModal_1 = require("./components/DebtModals/DebtRevertModal");
var DebtAdvisoryCommentModal_1 = require("./components/DebtModals/DebtAdvisoryCommentModal");
var DebtRequestLitigationModal_1 = require("./components/DebtModals/DebtRequestLitigationModal");
var DebtDeclineModal_1 = require("./components/DebtModals/DebtDeclineModal");
var DebtsWhatNextContainer_1 = require("./components/DebtWhatsNext/DebtsWhatNextContainer");
var DebtCloseModal_1 = require("./components/DebtModals/DebtCloseModal");
var DebtAcceptLitigation_1 = require("./components/DebtModals/DebtAcceptLitigation");
var DebtAdviseAgainstModal_1 = require("./components/DebtModals/DebtAdviseAgainstModal");
var Modal = require('react-modal');
var IDebtStatus;
(function (IDebtStatus) {
    IDebtStatus["UNSUBMITTED"] = "unsubmitted";
    IDebtStatus["SUBMITTED"] = "submitted";
    IDebtStatus["STAGE_1"] = "stage_1";
    IDebtStatus["STAGE_1_ENDED"] = "stage_1_ended";
    IDebtStatus["STAGE_2"] = "stage_2";
    IDebtStatus["STAGE_2_AUTHORISED"] = "stage_2_authorised";
    IDebtStatus["OVERDUE"] = "overdue";
    IDebtStatus["RECOVERED"] = "recovered";
    IDebtStatus["CLOSED"] = "closed";
    IDebtStatus["DECLINED"] = "declined";
    IDebtStatus["REQUESTED"] = "litigation_requested";
    IDebtStatus["LITIGATION"] = "in_litigation";
    IDebtStatus["REVERTED"] = "reverted";
})(IDebtStatus = exports.IDebtStatus || (exports.IDebtStatus = {}));
Modal.setAppElement('#root');
var ViewDebt = /** @class */ (function (_super) {
    __extends(ViewDebt, _super);
    function ViewDebt() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            supporting_documents: [],
            showExtensionModal: false,
            showRevertModal: false,
            showRequestModal: false,
            showDeclineModal: false,
            showCommentModal: false,
            showCloseModal: false,
            showAdviseAgainstModal: false,
            showAcceptModal: false,
            notes: [],
            isLoading: false,
            debtNotFound: false,
            hasData: false,
        };
        _this.checkInt = function (value) {
            var element = document.getElementById('other_interest_rate');
            if (value === 'other')
                element.style.display = 'block';
            else {
                element.style.display = 'none';
            }
        };
        _this.handleExtensionModal = function () {
            _this.setState(function (prevState) {
                return {
                    showExtensionModal: !prevState.showExtensionModal,
                };
            });
        };
        _this.handleRequestModal = function () {
            _this.setState(function (prevState) {
                return {
                    showRequestModal: !prevState.showRequestModal,
                };
            });
        };
        _this.handleDeclineModal = function () {
            _this.setState(function (prevState) {
                return {
                    showDeclineModal: !prevState.showDeclineModal,
                };
            });
        };
        _this.handleCommentModal = function () {
            _this.setState(function (prevState) {
                return {
                    showCommentModal: !prevState.showCommentModal,
                };
            });
        };
        _this.handleRevertModal = function () {
            _this.setState(function (prevState) {
                return {
                    showRevertModal: !prevState.showRevertModal,
                };
            });
        };
        _this.handleCloseModal = function () {
            _this.setState(function (prevState) {
                return {
                    showCloseModal: !prevState.showCloseModal,
                };
            });
        };
        _this.handleAdviseAgainstModal = function () {
            _this.setState(function (prevState) {
                return {
                    showAdviseAgainstModal: !prevState.showAdviseAgainstModal,
                };
            });
        };
        _this.handleAcceptLitigationModal = function () {
            _this.setState(function (prevState) {
                return {
                    showAcceptModal: !prevState.showAcceptModal,
                };
            });
        };
        _this.handleLoadingComponent = function (load) {
            _this.setState({ isLoading: load });
        };
        _this.changeDates = function (debt) {
            var newDebt = debt;
            newDebt.invoice_due_date = new Date(debt.invoice_due_date).toLocaleDateString('en-GB', { timeZone: 'UTC' });
            newDebt.invoice_sent_date = new Date(debt.invoice_sent_date).toLocaleDateString('en-GB', { timeZone: 'UTC' });
            newDebt.reminder_sent_date = new Date(debt.reminder_sent_date).toLocaleDateString('en-GB', { timeZone: 'UTC' });
            newDebt.purchase_order_date = new Date(debt.purchase_order_date).toLocaleDateString('en-GB', { timeZone: 'UTC' });
            return debt;
        };
        _this.getData = function () { return __awaiter(_this, void 0, void 0, function () {
            var error_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.props.debt.id !== null)) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.props.reloadData();
                        return [4 /*yield*/, (0, recoverInterface_1.default)()
                                .debts()
                                .access(this.props.debt.id)
                                .actions()
                                .index()
                                .then(function (response) { return _this.setState({ notes: response.action }); })
                                .catch(function (error) { return (0, core_1.ToastNotification)({ type: 'error', message: error.message }); })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        this.setState({ debtNotFound: true });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.submitData = function (values) { return __awaiter(_this, void 0, void 0, function () {
            var invoiceDue, invoiceSent, reminder, engagement, request;
            var _this = this;
            return __generator(this, function (_a) {
                invoiceDue = values.invoice_due_date;
                invoiceSent = values.invoice_sent_date;
                reminder = values.reminder_sent_date;
                engagement = values.purchase_order_date;
                request = __assign({}, values);
                request.invoice_due_date = new Date(invoiceDue);
                request.invoice_sent_date = new Date(invoiceSent);
                request.reminder_sent_date = new Date(reminder);
                request.purchase_order_date = new Date(engagement);
                request = (0, debtSeperator_1.debtorBundler)(request);
                (0, recoverInterface_1.default)()
                    .debts()
                    .edit(this.props.debt.id, request)
                    .then(function (response) { return (0, core_1.ToastNotification)({ type: 'success', message: response.message }); })
                    .then(function () { return _this.getData(); })
                    .catch(function (error) { return (0, core_1.ToastNotification)({ type: 'error', message: error.message }); });
                return [2 /*return*/];
            });
        }); };
        _this.submitDocuments = function () { return __awaiter(_this, void 0, void 0, function () {
            var data;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = new FormData();
                        data.set('file', this.state.supporting_documents[0].file);
                        return [4 /*yield*/, fetch("".concat((0, getState_1.default)().api.endpoint, "/debts/").concat(this.props.debt.id, "/supporting_documents"), {
                                method: 'POST',
                                headers: {
                                    Authorization: Cookie.get('sessionId'),
                                },
                                body: data,
                            }).then(function (res) {
                                return res
                                    .json()
                                    .then(function (response) { return (0, core_1.ToastNotification)({ type: 'success', message: response.message }); })
                                    .then(function () { return _this.getData(); })
                                    .catch(function (error) { return (0, core_1.ToastNotification)({ type: 'error', message: error.message }); });
                            })];
                    case 1:
                        _a.sent();
                        this.setState(function (prevState) {
                            var newArray = prevState.supporting_documents;
                            newArray.shift();
                            return { supporting_documents: newArray };
                        });
                        if (this.state.supporting_documents.length > 0) {
                            this.submitDocuments();
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this.componentDidMount = function () {
            _this.getData();
        };
        _this.componentDidUpdate = function () {
            _this.handleUpdate();
        };
        _this.handleUpdate = function () {
            if (!_this.state.hasData && _this.props.debt.id !== null && !_this.state.debtNotFound) {
                _this.getData();
                _this.setState({ hasData: true });
            }
            window.onfocus = function () {
                _this.getData();
            };
        };
        _this.demandStageHandler = function () {
            if (_this.props.debt.status === 'submitted') {
                return '1';
            }
            if (_this.props.debt.status === 'stage_1') {
                return '2';
            }
        };
        _this.handleCustomerAction = function (action) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                (0, recoverInterface_1.default)()
                    .debts()
                    .access(this.props.debt.id)
                    .actions()
                    .customer(action)
                    .then(function (response) { return (0, core_1.ToastNotification)({ type: 'success', message: response.message }); })
                    .then(function () { return _this.getData(); })
                    .catch(function (error) { return (0, core_1.ToastNotification)({ type: 'error', message: error.message }); });
                return [2 /*return*/];
            });
        }); };
        _this.handleAdminAction = function (action) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                (0, recoverInterface_1.default)()
                    .debts()
                    .access(this.props.debt.id)
                    .actions()
                    .admin(action, { reason: '' })
                    .then(function (response) { return (0, core_1.ToastNotification)({ type: 'success', message: response.message }); })
                    .then(function () { return _this.getData(); })
                    .catch(function (error) { return (0, core_1.ToastNotification)({ type: 'error', message: error.message }); });
                return [2 /*return*/];
            });
        }); };
        _this.render = function () {
            var isAllowedExtraTime = _this.props.debt.debtor.entity_type === 'individual' &&
                _this.props.debt.status === 'stage_1' &&
                _this.props.debt.jurisdiction === 'england_wales';
            if (!_this.props.debtNotFound) {
                return (React.createElement("div", { className: "container" },
                    React.createElement(DebtStatus_1.default, { debtStatus: _this.props.debt.status }),
                    React.createElement("section", { className: "debt-flex-container" },
                        React.createElement("section", { className: "debt-flex-information" },
                            React.createElement(DebtAmount_1.default, { amount: _this.props.debt.original_amount, reference: _this.props.debt.reference, status: _this.props.debt.status }),
                            React.createElement(DebtInformation_1.default, { debt: _this.props.debt })),
                        React.createElement("section", { className: "actions-section debt-flex-actions flex-column" },
                            React.createElement(DebtNeedAction_1.default, { status: _this.props.debt.status, goToDemands: function () {
                                    window.location.href = "/admin/debts/".concat(_this.props.debt.id, "/upcoming/").concat(_this.props.debt.status === 'submitted' ? 1 : 2);
                                }, debt: _this.props.debt }),
                            React.createElement(ExistingDemands_1.default, { notes: _this.state.notes, handleLoadingComponent: _this.handleLoadingComponent, reference: _this.props.debt.reference }, _this.props.debt.status === 'in_litigation' ? React.createElement("h1", null, _this.props.debt.proclaim_matter_id) : null),
                            React.createElement(DebtActionButtonContainer_1.default, { status: _this.props.debt.status, handleExtensionModal: _this.handleExtensionModal, handleRevertModal: _this.handleRevertModal, handleDebtDeclineModal: _this.handleDeclineModal, handleRequestLitigationModal: _this.handleRequestModal, handleDebtAdvisoryCommentModal: _this.handleCommentModal, handleCloseModal: _this.handleCloseModal, handleAcceptLitigationModal: _this.handleAcceptLitigationModal, handleAdviseAgainstModal: _this.handleAdviseAgainstModal, goToDemands: function () {
                                    window.location.href = "/admin/debts/".concat(_this.props.debt.reference, "/upcoming/").concat(_this.props.debt.status === 'submitted' ? 1 : 2);
                                }, handleCustomerAction: _this.handleCustomerAction, handleAdminAction: _this.handleAdminAction, jurisdiction: _this.props.debt.jurisdiction, isAllowedExtraTime: isAllowedExtraTime, canExtend: _this.props.debt.can_extend, reference: _this.props.debt.reference }),
                            React.createElement(DebtsWhatNextContainer_1.default, { debt: _this.props.debt, notes: _this.state.notes, isAllowedExtraTime: isAllowedExtraTime }))),
                    React.createElement(DebtExtensionModal_1.default, { showModal: _this.state.showExtensionModal, handleExtensionModal: _this.handleExtensionModal, debt_id: _this.props.debt.id, actions: _this.state.notes, getData: _this.getData }),
                    React.createElement(DebtRevertModal_1.default, { showModal: _this.state.showRevertModal, handleRevertModal: _this.handleRevertModal, debt_id: _this.props.debt.id, getData: _this.getData }),
                    React.createElement(DebtDeclineModal_1.default, { showModal: _this.state.showDeclineModal, handleDebtDeclineModal: _this.handleDeclineModal, debt_id: _this.props.debt.id, getData: _this.getData }),
                    React.createElement(DebtRequestLitigationModal_1.default, { showModal: _this.state.showRequestModal, handleRequestLitigation: _this.handleRequestModal, debt_id: _this.props.debt.id, getData: _this.getData }),
                    React.createElement(DebtAdvisoryCommentModal_1.default, { showModal: _this.state.showCommentModal, handleDebtAdvisoryCommentModal: _this.handleCommentModal, debt_id: _this.props.debt.id, getData: _this.getData }),
                    React.createElement(DebtCloseModal_1.default, { showModal: _this.state.showCloseModal, handleCloseModal: _this.handleCloseModal, debt_id: _this.props.debt.id, getData: _this.getData }),
                    React.createElement(DebtAcceptLitigation_1.default, { showModal: _this.state.showAcceptModal, handleAcceptLitigationModal: _this.handleAcceptLitigationModal, debt_id: _this.props.debt.id, getData: _this.getData }),
                    React.createElement(DebtAdviseAgainstModal_1.default, { showModal: _this.state.showAdviseAgainstModal, handleAdviseAgainstModal: _this.handleAdviseAgainstModal, debt_id: _this.props.debt.id, getData: _this.getData }),
                    _this.state.isLoading === true ? React.createElement(LoadingSpinner_1.default, null) : null));
            }
        };
        return _this;
    }
    return ViewDebt;
}(React.Component));
exports.default = ViewDebt;
