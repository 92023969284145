"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var TrackOwed_1 = require("./TrackOwed");
var TrackLimit_1 = require("./TrackLimit");
var Trackingbox = function (props) {
    if (props.bank_details === false) {
        return (React.createElement("div", { className: "trackingbox-container trackingbox-container flex-row" },
            React.createElement("div", { style: { backgroundColor: '#1298a4' }, className: "track-owed no-bank-details flex-column" },
                React.createElement("div", { className: "bank-warning" }, "Warning!"),
                React.createElement("p", null, "Your company currently has no bank details attached"),
                React.createElement("button", { type: "button", className: "button white-button createDebt", onClick: function () {
                        window.location.href = '/dashboard/my_company';
                    } }, "Add Your Details"),
                React.createElement("p", null, "If you don't do this, you will not be able to submit any debts"))));
    }
    else
        return (React.createElement("div", { className: "trackingbox-container flex-row-wrap" },
            React.createElement(TrackLimit_1.default, { value1: props.activeDebts, value2: props.creatableDebts, value3: props.periodEnd }),
            React.createElement(TrackOwed_1.default, { value: props.totalValue, total_amount_recovered: props.total_amount_recovered, join_date: props.join_date, breakdown: props.breakdown })));
};
exports.default = Trackingbox;
