"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Cookie = require("js-cookie");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var core_1 = require("@rradar/core");
var getState_1 = require("../../../../../../utils/getState");
var downloadFile_1 = require("../../../../../../utils/downloadFile");
var formatDebtStatus_1 = require("../../../../../../utils/formatDebtStatus");
var dateTimeAgo_1 = require("../../../../../../utils/dateTimeAgo");
var NoteTile_1 = require("./components/NoteTile");
var requestPDF = function (id, props) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                props.props.handleLoadingComponent(true);
                return [4 /*yield*/, fetch("".concat((0, getState_1.default)().api.endpoint, "/demands/").concat(id, "/pdf"), {
                        method: 'GET',
                        headers: {
                            Authorization: Cookie.get('sessionId'),
                        },
                    })
                        .then(function (pdfBlob) { return __awaiter(void 0, void 0, void 0, function () {
                        var headers, filename;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    headers = pdfBlob.headers.get('Content-Disposition');
                                    filename = headers.match(/\"(.*?)\"/);
                                    return [4 /*yield*/, pdfBlob.blob().then(function (pdf) {
                                            (0, downloadFile_1.default)(pdf, filename[1]);
                                            props.props.handleLoadingComponent(false);
                                            return (0, core_1.ToastNotification)({ type: 'success', message: 'Download Successful' });
                                        })];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); })
                        .catch(function (error) {
                        props.props.handleLoadingComponent(false);
                        return (0, core_1.ToastNotification)({ type: 'error', message: error.message });
                    })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
var DemandTile = function (props) {
    return (React.createElement("div", { className: "demand-list-item", key: props.note.data.demand_id },
        React.createElement("div", { className: "flex-row" },
            React.createElement("div", { className: "demand-list-icon" },
                React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'file-pdf'] })),
            React.createElement("div", { className: "demand-list-item-info" },
                React.createElement("h3", null,
                    (0, formatDebtStatus_1.default)(props.note.action_type),
                    " issued"),
                React.createElement("i", { className: "demand-list-item-timeago" },
                    new Date(props.note.created_at).toLocaleDateString('en-GB', {
                        timeZone: 'UTC',
                    }),
                    ' - ',
                    (0, dateTimeAgo_1.default)(props.note.created_at),
                    " ago")),
            React.createElement("div", { className: "demand-list-item--action" },
                React.createElement("div", { onClick: function () { return requestPDF(props.note.data.demand_id, props); } }, "Download")))));
};
var DebtDecline = function (note) {
    return (React.createElement(NoteTile_1.default, { description: 'Recovery marked as incompatible', note: note, action: 'decline', icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'ban'] }) }));
};
var DebtReverted = function (note) {
    return (React.createElement(NoteTile_1.default, { description: 'Debt reverted', note: note, action: 'revert', icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'backward'] }) }));
};
var DebtReconfirm = function (note) {
    return (React.createElement(NoteTile_1.default, { description: "Second demand authorised by ".concat(note.note.author.user_email), note: note, action: 'reconfirm', icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'forward'] }) }));
};
var DebtSubmit = function (note) {
    return (React.createElement(NoteTile_1.default, { description: "Submitted for recovery by ".concat(note.note.author.user_email), note: note, action: 'submit', icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'paper-plane'] }) }));
};
var DebtClosed = function (note) {
    var text = note.note.author !== null && note.note.author !== undefined ? "by ".concat(note.note.author.user_email) : 'automatically';
    return (React.createElement(NoteTile_1.default, { description: "Recovery discontinued ".concat(text, " "), note: note, action: 'close', icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'trash-alt'] }) }));
};
var DebtRecovered = function (note) {
    return (React.createElement(NoteTile_1.default, { description: "Recovery marked as successful by ".concat(note.note.author.user_email), note: note, action: 'resolve', icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'handshake'] }) }));
};
var DebtLitigationRequest = function (note) {
    return (React.createElement(NoteTile_1.default, { description: 'In consideration for litigation', note: note, action: 'request_litigation', icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'forward'] }) }));
};
var DebtAdvice = function (note) {
    return (React.createElement(NoteTile_1.default, { description: note.note.author.role === 'customer'
            ? "User comment by ".concat(note.note.author.user_email)
            : "Advisory comment by rradar legal advisor", note: note, action: 'advisory', icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'comments'] }) }));
};
var DebtReminder = function (note) {
    return (React.createElement(NoteTile_1.default, { description: 'Reminder sent', note: note, action: 'reminder', icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'bell'] }) }));
};
var DebtAcceptLitigation = function (note) {
    return (React.createElement(NoteTile_1.default, { description: "The debt has been accepted for litigation", note: note, action: 'advisory', icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'comments'] }) }));
};
var DebtAdviseAgainstLitigation = function (note) {
    return (React.createElement(NoteTile_1.default, { description: "rradar have advised against litigation", note: note, action: 'advisory', icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'comments'] }) }));
};
var DebtExtend = function (note) {
    return (React.createElement(NoteTile_1.default, { description: "First response period extended until ".concat(new Date(note.note.expiry_date).toLocaleDateString('en-GB', {
            timeZone: 'UTC',
        })), note: note, action: 'extend', icon: React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'arrow-to-right'] }) }));
};
var DebtNotes = function (props) {
    var notes = __spreadArray([], __read(props.notes), false).reverse();
    if (props.notes.length > 0) {
        return (React.createElement("div", { className: "notes-container" }, notes.map(function (note) {
            switch (note.action_type) {
                case 'decline': {
                    return React.createElement(DebtDecline, { note: note, key: note.id });
                }
                case 'submit': {
                    return React.createElement(DebtSubmit, { note: note, key: note.id });
                }
                case 'reconfirm': {
                    return React.createElement(DebtReconfirm, { note: note, key: note.id });
                }
                case 'close': {
                    return React.createElement(DebtClosed, { note: note, key: note.id });
                }
                case 'resolve': {
                    return React.createElement(DebtRecovered, { note: note, key: note.id });
                }
                case 'request_litigation': {
                    return React.createElement(DebtLitigationRequest, { note: note, key: note.id });
                }
                case 'advisory': {
                    return React.createElement(DebtAdvice, { note: note, key: note.id });
                }
                case 'stage_1':
                case 'stage_2': {
                    return React.createElement(DemandTile, { note: note, key: note.id, props: props });
                }
                case 'reminder': {
                    return React.createElement(DebtReminder, { note: note, key: note.id });
                }
                case 'revert': {
                    return React.createElement(DebtReverted, { note: note, key: note.id });
                }
                case 'extend': {
                    return React.createElement(DebtExtend, { note: note, key: note.id });
                }
                case 'confirm_litigation': {
                    return React.createElement(DebtAcceptLitigation, { note: note, key: note.id });
                }
                case 'disadvise_litigation': {
                    return React.createElement(DebtAdviseAgainstLitigation, { note: note, key: note.id });
                }
                default: {
                    return;
                }
            }
        })));
    }
    return null;
};
exports.default = DebtNotes;
