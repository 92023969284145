"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Cookie = require("js-cookie");
var lister_1 = require("@rradar/lister");
var getState_1 = require("../../../../../utils/getState");
var index_1 = require("../../../../Tables/ListingsTable/index");
var returnTableByKeyType_1 = require("../../../../../utils/returnTableByKeyType");
var DebtorTable = function () {
    var listerRef = React.createRef();
    return (React.createElement("div", { className: "" },
        React.createElement(lister_1.default, { ref: listerRef, api: {
                url: (0, getState_1.default)().api.endpoint,
                headers: new Headers({
                    Authorization: Cookie.get('sessionId'),
                }),
                resource: 'debtors',
                endpoint: "/debtors",
            }, urlParams: ['page'], singleSort: true, render: function (lister) {
                return React.createElement(index_1.default, { lister: lister, selecting: false, keys: (0, returnTableByKeyType_1.default)('debtors') });
            } })));
};
exports.default = DebtorTable;
