"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var lodash_1 = require("lodash");
var react_confirm_alert_1 = require("react-confirm-alert");
var core_1 = require("@rradar/core");
var index_1 = require("../StageHandler/index");
var AddDebtWizardRouter_1 = require("./AddDebtWizardRouter");
var WizardValidator_1 = require("../../../../validators/WizardValidator");
var splitPathGetPosition_1 = require("../../../../utils/splitPathGetPosition");
var recoverInterface_1 = require("../../../../utils/recoverInterface");
var handleFileSubmit_1 = require("../../../../utils/handleFileSubmit");
var getState_1 = require("../../../../utils/getState");
var debtUtils_1 = require("../../../../utils/debtUtils");
var AddDebtWizard = /** @class */ (function (_super) {
    __extends(AddDebtWizard, _super);
    function AddDebtWizard() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            currentPath: 'page1',
            search_text: '',
            debtors: [],
            submitted: false,
            my_company: null,
        };
        _this.searchDebtor = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                (0, recoverInterface_1.default)()
                    .debtors()
                    .search(this.state.search_text)
                    .then(function (response) { return _this.setState({ debtors: response.debtors }); });
                return [2 /*return*/];
            });
        }); };
        _this.setSearchTerm = function (search_text) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.setState({ search_text: search_text }, function () {
                            if (search_text === '') {
                                _this.setState({ debtors: [] });
                            }
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.searchAndPopulateDebtors = function (search_text, setFieldValue, values) {
            _this.setSearchTerm(search_text, setFieldValue, values).then(function () {
                if (_this.state.search_text !== '') {
                    _this.searchDebtor();
                }
            });
        };
        _this.setDebtor = function (setFieldValue, debtor, values) {
            if (debtor.id === values.debtor_id || debtor.id === null) {
                setFieldValue('trading_name', '');
                setFieldValue('companies_house_number', '');
                setFieldValue('email', '');
                setFieldValue('address_1', '');
                setFieldValue('address_2', '');
                setFieldValue('city', '');
                setFieldValue('country', '');
                setFieldValue('post_code', '');
                setFieldValue('merge_variables', {});
                setFieldValue('debtor_id', null);
                if (debtor.protocol_id === 1) {
                    setFieldValue('first_name', '');
                    setFieldValue('middle_name', '');
                    setFieldValue('last_name', '');
                }
                else if (debtor.protocol_id === 2) {
                    setFieldValue('company_name', '');
                }
                if (values.merge_variables.representative_name || values.merge_variables.representative_name !== '') {
                    setFieldValue('representative_name', '');
                }
            }
            else {
                setFieldValue('trading_name', debtor.trading_name);
                setFieldValue('companies_house_number', debtor.companies_house_number);
                setFieldValue('email', debtor.email);
                setFieldValue('address_1', debtor.address_1);
                setFieldValue('address_2', debtor.address_2);
                setFieldValue('city', debtor.city);
                setFieldValue('post_code', debtor.post_code);
                setFieldValue('protocol_id', debtor.protocol_id);
                setFieldValue('merge_variables', debtor.merge_variables);
                setFieldValue('debtor_id', debtor.id);
                if (debtor.protocol_id === 1) {
                    setFieldValue('interest_rate', 8.0);
                    setFieldValue('first_name', debtor.first_name);
                    setFieldValue('middle_name', debtor.middle_name);
                    setFieldValue('last_name', debtor.last_name);
                }
                else if (debtor.protocol_id === 2) {
                    setFieldValue('interest_rate', 8 + (0, getState_1.default)().interest_rate_boe);
                    setFieldValue('company_name', debtor.company_name);
                }
                if (!(0, lodash_1.isEmpty)(debtor.merge_variables)) {
                    setFieldValue('representative_name', debtor.merge_variables.representative_name);
                }
            }
        };
        _this.setFileUpload = function (setFieldValue, file) {
            setFieldValue('supporting_documents', file);
        };
        _this.handleSubmit = function (values, errors, submitting) { return __awaiter(_this, void 0, void 0, function () {
            var merge_variables, name, debtor, debt, body, debtId_1, reference_1;
            var _this = this;
            return __generator(this, function (_a) {
                merge_variables = {
                    representative_name: values.representative_name,
                };
                name = {};
                if (values.protocol_id === 1) {
                    name = {
                        first_name: values.first_name,
                        middle_name: values.middle_name,
                        last_name: values.last_name,
                    };
                }
                else {
                    name = { company_name: values.company_name };
                }
                debtor = __assign(__assign({}, name), { trading_name: values.trading_name, companies_house_number: values.companies_house_number, email: values.email, address_1: values.address_1, address_2: values.address_2, city: values.city, country: values.country, post_code: values.post_code, protocol_id: values.protocol_id, merge_variables: merge_variables });
                debt = {
                    original_amount: values.original_amount,
                    interest_rate: values.interest_rate,
                    invoice_due_date: (0, debtUtils_1.setDebtDate)(values.invoice_due_date),
                    invoice_sent_date: (0, debtUtils_1.setDebtDate)(values.invoice_sent_date),
                    invoice_reference: values.invoice_reference,
                    purchase_order_number: values.purchase_order_number,
                    reminder_sent_date: (0, debtUtils_1.setDebtDate)(values.reminder_sent_date),
                    purchase_order_date: (0, debtUtils_1.setDebtDate)(values.purchase_order_date),
                    goods_description: values.goods_description,
                    services_description: values.services_description,
                    jurisdiction: values.jurisdiction,
                };
                body = {
                    debt: debt,
                };
                if ((0, lodash_1.isEmpty)(errors)) {
                    if (values.debtor_id !== null) {
                        body.debtor_id = values.debtor_id;
                        // body['debtor'] = debtor;
                    }
                    else {
                        body.debtor = debtor;
                    }
                    (0, react_confirm_alert_1.confirmAlert)({
                        title: submitting ? 'Save and Submit' : 'Save',
                        message: submitting
                            ? 'Do you want to save this debt and submit it for recovery?'
                            : 'Do you want to save this debt?',
                        buttons: [
                            {
                                label: 'Cancel',
                                onClick: function () {
                                    return null;
                                },
                            },
                            {
                                label: 'Confirm',
                                onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                                    var _this = this;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, (0, recoverInterface_1.default)()
                                                    .debts()
                                                    .create(body)
                                                    .then(function (response) {
                                                    debtId_1 = response.debt.debt.id;
                                                    reference_1 = response.debt.debt.reference;
                                                    (0, core_1.ToastNotification)({ type: 'success', message: response.message });
                                                })
                                                    .then(function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                                    return [2 /*return*/, function (response) { return (0, core_1.ToastNotification)({ type: 'success', message: response.message }); }];
                                                }); }); })
                                                    .catch(function (error) { return (0, core_1.ToastNotification)({ type: 'error', message: error.message }); })];
                                            case 1:
                                                _a.sent();
                                                if (!(values.supporting_documents !== '' &&
                                                    values.supporting_documents !== undefined &&
                                                    values.supporting_documents !== null)) return [3 /*break*/, 3];
                                                return [4 /*yield*/, (0, handleFileSubmit_1.default)(values, debtId_1, 'POST')
                                                        .then(function () { return (0, core_1.ToastNotification)({ type: 'success', message: 'Submitted Documents' }); })
                                                        .catch(function () { return (0, core_1.ToastNotification)({ type: 'error', message: 'Documents not submitted' }); })];
                                            case 2:
                                                _a.sent();
                                                _a.label = 3;
                                            case 3:
                                                if (!(submitting === true)) return [3 /*break*/, 5];
                                                return [4 /*yield*/, (0, recoverInterface_1.default)()
                                                        .debts()
                                                        .access(debtId_1)
                                                        .actions()
                                                        .customer('submit')
                                                        .then(function (response) { return (0, core_1.ToastNotification)({ type: 'success', message: response.message }); })
                                                        .catch(function (error) { return (0, core_1.ToastNotification)({ type: 'error', message: error.message }); })];
                                            case 4:
                                                _a.sent();
                                                _a.label = 5;
                                            case 5:
                                                if (debtId_1 !== undefined) {
                                                    window.location.href = "/admin/debts/".concat(reference_1);
                                                }
                                                return [2 /*return*/];
                                        }
                                    });
                                }); },
                            },
                        ],
                    });
                }
                else {
                    (0, core_1.ToastNotification)({ type: 'error', message: 'You have not provided all the required fields.' });
                }
                return [2 /*return*/];
            });
        }); };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, (0, recoverInterface_1.default)()
                            .companies()
                            .my_company()
                            .get()
                            .then(function (response) { return _this.setState({ my_company: response.my_company }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.componentDidUpdate = function () {
            _this.handleUpdate();
        };
        _this.handleUpdate = function () {
            var updatedPath = (0, splitPathGetPosition_1.default)(window.location.pathname, 3);
            if (_this.state.currentPath !== updatedPath) {
                _this.setState({
                    currentPath: updatedPath,
                });
            }
        };
        _this.render = function () {
            return (React.createElement("div", { className: "add-debt-wizard-container" },
                React.createElement("div", { className: "header-stage-container" },
                    React.createElement(index_1.default, { stage: _this.state.currentPath })),
                React.createElement(formik_1.Formik, { initialValues: {
                        first_name: '',
                        middle_name: '',
                        last_name: '',
                        company_name: '',
                        jurisdiction: 'england_wales',
                        email: '',
                        address_1: '',
                        address_2: '',
                        city: '',
                        country: 'United Kingdom',
                        post_code: '',
                        protocol_id: 1,
                        original_amount: 0,
                        interest_rate: 8.0,
                        invoice_due_date: '',
                        invoice_sent_date: '',
                        invoice_reference: '',
                        purchase_order_number: '',
                        reminder_sent_date: '',
                        supporting_documents: null,
                        purchase_order_date: '',
                        debtor_id: null,
                        isSubmitting: false,
                        goods_description: '',
                        services_description: '',
                        merge_variables: {
                            representative_name: '',
                        },
                    }, validationSchema: WizardValidator_1.default, validateOnBlur: true, validateOnChange: true, onSubmit: function () {
                        // this.handleSubmit(values);
                    }, render: function (props) { return (React.createElement(AddDebtWizardRouter_1.default, { values: props.values, errors: props.errors, validator: WizardValidator_1.default, setFieldValue: props.setFieldValue, searchAndPopulateDebtors: _this.searchAndPopulateDebtors, debtors: _this.state.debtors, setDebtor: _this.setDebtor, setTCModal: _this.props.setTCModal, setFileUpload: _this.setFileUpload, handleSubmit: _this.handleSubmit, search_text: _this.state.search_text, my_company: _this.state.my_company, disabled: !(props.isValid && props.dirty && !props.isSubmitting) })); } })));
        };
        return _this;
    }
    return AddDebtWizard;
}(React.Component));
exports.default = AddDebtWizard;
