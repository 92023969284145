"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var AddDebtWizardPage9Debtor_1 = require("./AddDebtWizardPage9/AddDebtWizardPage9Debtor");
var AddDebtWizardPage9DebtType_1 = require("./AddDebtWizardPage9/AddDebtWizardPage9DebtType");
var AddDebtWizardPage9Debt_1 = require("./AddDebtWizardPage9/AddDebtWizardPage9Debt");
var AddDebtWizardPage9Invoice_1 = require("./AddDebtWizardPage9/AddDebtWizardPage9Invoice");
var scrollToTop_1 = require("../../../../../utils/scrollToTop");
var AddDebtWizardPage8 = function (props) {
    (0, scrollToTop_1.default)();
    return (React.createElement("div", { className: "add-debt-wizard-page flex-column" },
        React.createElement("div", { className: "add-debt-wizard-form-contents" },
            React.createElement("div", { className: "wizard-form-description" },
                React.createElement("h2", null, "Confirmation"),
                React.createElement("p", null, "Confirm that the data you are submitting is correct. Errors will slow down the process and you may be required to edit the debt at a later date before we can begin to recover your debt.")),
            React.createElement("div", { className: "flex-row-wrap", style: { justifyContent: 'center' } },
                React.createElement("div", { className: "flex-row-wrap" },
                    React.createElement(AddDebtWizardPage9Debtor_1.default, { values: props.values, setFieldValue: props.setFieldValue, validator: props.validator }),
                    React.createElement(AddDebtWizardPage9DebtType_1.default, { values: props.values, setFieldValue: props.setFieldValue, validator: props.validator })),
                React.createElement("div", { className: "flex-row-wrap" },
                    React.createElement(AddDebtWizardPage9Debt_1.default, { values: props.values, setFieldValue: props.setFieldValue, validator: props.validator, pounds: props.pounds, setPounds: props.setPounds, pence: props.pence, setPence: props.setPence }),
                    React.createElement(AddDebtWizardPage9Invoice_1.default, { values: props.values, setFieldValue: props.setFieldValue, validator: props.validator, setFileUpload: props.setFileUpload })))),
        React.createElement("div", { className: "link_navigation flex-row" },
            React.createElement(react_router_dom_1.Link, { to: "page8", className: "button nav-button-grey" }, "Previous"),
            React.createElement("button", { type: "button", onClick: function () {
                    props.handleSubmit(props.values, props.errors, false);
                }, disabled: props.disabled, className: "button red-button", id: 'active' }, "Save as draft"),
            React.createElement("button", { type: "button", onClick: function () {
                    props.handleSubmit(props.values, props.errors, true);
                }, disabled: props.disabled || props.debts_remaining === 0, className: "button red-button", id: 'active' }, "Save and Submit"))));
};
exports.default = AddDebtWizardPage8;
