"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var getState_1 = require("../../../../../../utils/getState");
var ProtocolTile = function (props) {
    return (React.createElement("div", { className: "protocol-tile flex-column ".concat(props.value === props.id ? '' : 'protocol-tile-unselected'), onClick: function () {
            props.setFieldValue('protocol_id', props.id);
            if (props.id === 1) {
                props.setFieldValue('interest_rate', 8.0);
            }
            else if (props.id === 2) {
                props.setFieldValue('interest_rate', 8 + (0, getState_1.default)().interest_rate_boe);
            }
        } },
        React.createElement("div", { className: "protocol-tile-image ".concat(props.value === props.id ? 'protocol-tile-image-selected' : 'protocol-tile-image-unselected') },
            React.createElement("i", { className: props.fontAwesomeClassName })),
        React.createElement("div", { className: "protocol-tile-text" },
            React.createElement("div", null, props.text))));
};
exports.default = ProtocolTile;
