"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var recoverInterface_1 = require("../../../../../../../../utils/recoverInterface");
var CheckBox_1 = require("../../../../../../AddDebtWizard/addDebt/CheckBox");
var DebtRequestSchema_1 = require("../../../../../../../../validators/DebtActionValidators/DebtRequestSchema");
var ButtonContainer_1 = require("../../../../../../../common/Buttons/ButtonContainer");
var DebtRequestLitigationForm = function (props) {
    var handleSuccess = function (response, actions) {
        actions.resetForm();
        props.setLoading(false);
        props.getData();
        props.showModal && props.handleRequestLitigation();
    };
    var handleFailure = function (err, actions) {
        actions.resetForm();
        props.setLoading(false);
    };
    return (React.createElement(formik_1.Formik, { initialValues: { reason: '', accepted: false }, onSubmit: function (values, actions) { return __awaiter(void 0, void 0, void 0, function () {
            var response, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        props.setLoading(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, (0, recoverInterface_1.default)()
                                .debts()
                                .access(props.debt_id)
                                .actions()
                                // @ts-ignore
                                .customer('request_litigation', { reason: values.reason })];
                    case 2:
                        response = _a.sent();
                        handleSuccess(response, actions);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        handleFailure(err_1, actions);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); }, validationSchema: DebtRequestSchema_1.default, validateOnBlur: true, render: function (config) { return (React.createElement("div", null,
            React.createElement("section", null,
                React.createElement("label", { htmlFor: "start_date" },
                    React.createElement("strong", null, "Please provide details, such as what options you are considering.")),
                React.createElement(formik_1.Field, { component: "textarea", name: "reason", className: "modal-textarea" })),
            React.createElement("section", null,
                React.createElement(CheckBox_1.default, { id: 'accepted', name: 'accepted', className: '', label: 'I have read the ADR and Legal Action Outlook and understand my options.', validator: DebtRequestSchema_1.default })),
            React.createElement(ButtonContainer_1.default, { primaryFunction: config.submitForm, primaryText: 'Advance Recovery', primaryDisabled: !(config.isValid && config.dirty && !config.isSubmitting), cancelFunction: props.handleRequestLitigation }))); } }));
};
exports.default = DebtRequestLitigationForm;
