"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classnames_1 = require("classnames");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var QuickActions_1 = require("../buttonGroups/QuickActions");
var NavigationDrawer = function (_a) {
    var menuOpen = _a.menuOpen, toggleMenu = _a.toggleMenu;
    return (React.createElement("div", { className: (0, classnames_1.default)({
            'navigation-drawer': true,
            'navigation-drawer-open': menuOpen,
        }) },
        React.createElement("div", { onClick: function () { return toggleMenu(); } },
            React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'times'] })),
        React.createElement(QuickActions_1.default, { toggleMobileMenu: toggleMenu })));
};
exports.default = NavigationDrawer;
