"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var react_datepicker_1 = require("react-datepicker");
var EditDebtInvoice = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "form-input" },
            React.createElement("label", { htmlFor: "invoice_reference" }, "Invoice number"),
            React.createElement(formik_1.Field, { type: "text", name: "invoice_reference", placeholder: "Invoice Ref", disabled: props.disabled })),
        React.createElement(formik_1.ErrorMessage, { name: "invoice_reference" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
        React.createElement("div", { className: "form-input" },
            React.createElement("label", { htmlFor: "invoice_sent_date" }, "Invoice sent date"),
            React.createElement(formik_1.Field, { disabled: props.disabled, render: function (_a) {
                    var field = _a.field;
                    return (React.createElement(react_datepicker_1.default, __assign({}, field, { selected: Date.parse(props.values.invoice_sent_date), onChange: function (value) { return props.setFieldValue('invoice_sent_date', value); }, popperPlacement: "top-end", popperModifiers: {
                            offset: {
                                enabled: true,
                                offset: '5px, 10px',
                            },
                            preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: 'viewport',
                            },
                        }, dateFormat: "dd/MM/yyyy", todayButton: 'Today', maxDate: new Date() })));
                }, name: "invoice_sent_date" })),
        React.createElement(formik_1.ErrorMessage, { name: "invoice_sent_date" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
        React.createElement("div", { className: "form-input" },
            React.createElement("label", { htmlFor: "invoice_due_date" }, "Invoice due date"),
            React.createElement(formik_1.Field, { disabled: props.disabled, render: function (_a) {
                    var field = _a.field;
                    return (React.createElement(react_datepicker_1.default, __assign({}, field, { selected: Date.parse(props.values.invoice_due_date), onChange: function (value) { return props.setFieldValue('invoice_due_date', value); }, popperPlacement: "top-end", popperModifiers: {
                            offset: {
                                enabled: true,
                                offset: '5px, 10px',
                            },
                            preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: 'viewport',
                            },
                        }, dateFormat: "dd/MM/yyyy", placeholderText: "Click to select a date", todayButton: 'Today', minDate: new Date(props.values.invoice_sent_date), maxDate: new Date() })));
                }, name: "invoice_due_date" })),
        React.createElement(formik_1.ErrorMessage, { name: "invoice_due_date" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
        React.createElement("div", { className: "form-input" },
            React.createElement("label", { htmlFor: "reminder_sent_date" }, "Reminder sent date"),
            React.createElement(formik_1.Field, { disabled: props.disabled, render: function (_a) {
                    var field = _a.field;
                    return (React.createElement(react_datepicker_1.default, __assign({}, field, { selected: Date.parse(props.values.reminder_sent_date), onChange: function (value) { return props.setFieldValue('reminder_sent_date', value); }, popperPlacement: "top-end", popperModifiers: {
                            offset: {
                                enabled: true,
                                offset: '5px, 10px',
                            },
                            preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: 'viewport',
                            },
                        }, placeholderText: "Click to select a date", dateFormat: "dd/MM/yyyy", todayButton: 'Today', minDate: new Date(props.values.invoice_sent_date), maxDate: new Date() })));
                }, name: "reminder_sent_date" })),
        React.createElement(formik_1.ErrorMessage, { name: "reminder_sent_date" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); })));
};
exports.default = EditDebtInvoice;
