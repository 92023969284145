"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var EditDebtDebtorName = function (props) {
    return (React.createElement(React.Fragment, null, props.values.protocol_id === 1 ? (React.createElement("div", { className: "flex-column" },
        React.createElement("div", { className: "form-input" },
            React.createElement("label", { htmlFor: "first_name" }, "First Name"),
            React.createElement(formik_1.Field, { type: "text", name: "first_name", value: props.values.first_name, disabled: props.disabled })),
        React.createElement(formik_1.ErrorMessage, { name: "first_name" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
        React.createElement("div", { className: "form-input" },
            React.createElement("label", { htmlFor: "middle_name" }, "Middle Name"),
            React.createElement(formik_1.Field, { type: "text", name: "middle_name", value: props.values.middle_name, disabled: props.disabled })),
        React.createElement(formik_1.ErrorMessage, { name: "middle_name" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
        React.createElement("div", { className: "form-input" },
            React.createElement("label", { htmlFor: "last_name" }, "Last Name"),
            React.createElement(formik_1.Field, { type: "text", name: "last_name", value: props.values.last_name, disabled: props.disabled })),
        React.createElement(formik_1.ErrorMessage, { name: "last_name" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }))) : (React.createElement("div", { className: "flex-column" },
        React.createElement("div", { className: "form-input" },
            React.createElement("label", { htmlFor: "company_name" }, "Company Name"),
            React.createElement(formik_1.Field, { type: "text", name: "company_name", value: props.values.company_name, disabled: props.disabled })),
        React.createElement(formik_1.ErrorMessage, { name: "company_name" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
        React.createElement("div", { className: "form-input" },
            React.createElement("label", null, "Representative Name"),
            React.createElement(formik_1.Field, { type: "text", name: "representative_name", value: Object.prototype.hasOwnProperty.call(props.values, 'merge_variables')
                    ? Object.prototype.hasOwnProperty.call(props.values, 'representative_name')
                        ? props.values.merge_variables.representative_name
                        : null
                    : null, disabled: props.disabled, onChange: function (e) {
                    props.setFieldValue('merge_variables', {
                        representative_name: e.target.value,
                    });
                } })),
        React.createElement(formik_1.ErrorMessage, { name: "representative_name" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); })))));
};
exports.default = EditDebtDebtorName;
