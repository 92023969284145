"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Cookie = require("js-cookie");
var lister_1 = require("@rradar/lister");
var getState_1 = require("../../../../utils/getState");
var ListingsTable_1 = require("../../../Tables/ListingsTable");
var returnTableByKeyType_1 = require("../../../../utils/returnTableByKeyType");
var core_1 = require("@rradar/core");
var AdminCompanies = /** @class */ (function (_super) {
    __extends(AdminCompanies, _super);
    function AdminCompanies() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.listerRef = React.createRef();
        _this.render = function () {
            return (React.createElement("div", { className: "container" },
                React.createElement("div", { className: "dashboard-body" },
                    React.createElement("div", { className: "dashboard-header" },
                        React.createElement(core_1.Heading1, { title: "Companies" })),
                    React.createElement(lister_1.default, { ref: _this.listerRef, api: {
                            url: (0, getState_1.default)().api.endpoint,
                            headers: new Headers({
                                Authorization: Cookie.get('sessionId'),
                            }),
                            resource: 'companies',
                            endpoint: "/companies",
                        }, urlParams: ['page'], render: function (lister) {
                            return React.createElement(ListingsTable_1.default, { lister: lister, selecting: false, keys: (0, returnTableByKeyType_1.default)('companies') });
                        } }))));
        };
        return _this;
    }
    return AdminCompanies;
}(React.Component));
exports.default = AdminCompanies;
