"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var getState_1 = require("../../../../../../utils/getState");
var DebtNeedAction = /** @class */ (function (_super) {
    __extends(DebtNeedAction, _super);
    function DebtNeedAction() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderBanner = function (action, explanation, icon) {
            return (React.createElement("div", { className: "banner-container banner-orange", key: action },
                React.createElement("div", { className: "banner-content flex-row" },
                    React.createElement("div", { className: "banner-left-icon" }, icon),
                    React.createElement("div", { className: "banner-text flex-column" },
                        React.createElement("p", null,
                            React.createElement("strong", null, action),
                            explanation)))));
        };
        _this.bannerScenario = function () {
            switch ((0, getState_1.default)().user.role) {
                case 'admin':
                case 'legal': {
                    switch (_this.props.status) {
                        case 'submitted': {
                            return _this.renderBanner('This debt needs its first demand letter', 'Please use the demand action to continue.', React.createElement("i", { className: "fa fa-eye" }));
                        }
                        case 'stage_2_authorised': {
                            return _this.renderBanner('This debt needs its second demand letter', 'Please use the demand action to continue.', React.createElement("i", { className: "fa fa-eye" }));
                        }
                        // case "in_litigation": {
                        //   return this.props.debt.proclaim_matter_id === null
                        //     ? this.renderBanner(
                        //       "This debt has been accepted for litigation!",
                        //       "Please click here to add a proclaim id",
                        //       () => {
                        //         this.props.handleOpenEditModal();
                        //       },
                        //       <i className="fas fa-exclamation-triangle" />
                        //     )
                        //     : null;
                        // }
                        case 'litigation_requested': {
                            return _this.props.debt.proclaim_matter_id === null
                                ? _this.renderBanner('This debt has a request for litigation', 'Please use either the "Accept" or "Disadvise" actions', React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'exclamation-triangle'] }))
                                : null;
                        }
                        default: {
                            break;
                        }
                    }
                    break;
                }
                case 'customer': {
                    switch (_this.props.status) {
                        case 'unsubmitted': {
                            return (React.createElement("div", null, _this.renderBanner('IMPORTANT ACTION REQUIRED: ', 'This debt is in draft status. To begin the debt recovery process please click on the submit action box below.', React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'pencil-alt'] }))));
                        }
                        case 'reverted': {
                            return (React.createElement("div", null, _this.renderBanner('IMPORTANT ACTION REQUIRED: ', 'Please confirm the current status of the debt by clicking on the relevant action box below.', React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'pencil-alt'] }))));
                        }
                        case 'stage_1_ended': {
                            return _this.renderBanner('IMPORTANT ACTION REQUIRED:', 'Please use the relevant actions to advance, close or mark this debt as recovered', React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'check-double'] }));
                        }
                        case 'overdue': {
                            return (React.createElement("div", null, _this.renderBanner('IMPORTANT ACTION REQUIRED:', 'The second demand deadline has passed. Please use the relevant action to confirm that this debt is to progress beyond rradar recover, or if the debt has been resolved or closed', React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'exclamation-triangle'] }))));
                        }
                        default:
                            break;
                    }
                    break;
                }
            }
        };
        _this.render = function () {
            return React.createElement("div", null, _this.bannerScenario());
        };
        return _this;
    }
    return DebtNeedAction;
}(React.Component));
exports.default = DebtNeedAction;
