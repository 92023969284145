"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var listings_table_1 = require("@rradar/listings-table");
// import ListingsTable from '../../../../../../Documents/listings-table';
var React = require("react");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
var ButtonGroup_1 = require("../buttons/ButtonGroup");
var GenericCheckbox_1 = require("./GenericCheckbox");
exports.default = (function (_a) {
    var lister = _a.lister, keys = _a.keys, selecting = _a.selecting;
    return (React.createElement(listings_table_1.default, { lister: lister, className: "table listings-table", selecting: selecting == null ? true : selecting, components: {
            Checkbox: function (props) { return React.createElement(GenericCheckbox_1.default, __assign({}, props)); },
            Filter: function () { return React.createElement("i", { className: "fa fa-filter" }); },
            Warning: function () { return React.createElement("span", { className: "icon" }); },
            sorts: {
                Unsorted: function () { return React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faSort }); },
                Asc: function () { return React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faSortUp }); },
                Desc: function () { return React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faSortDown }); },
            },
            Pagination: function () { return (React.createElement(ButtonGroup_1.default, { style: {
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    position: 'relative',
                    width: '100%',
                    listStyleType: 'none',
                    margin: 0,
                    padding: '1.2rem 0rem',
                    background: '#ffffff',
                    fontSize: '0.8rem',
                }, buttons: [
                    {
                        disabled: function () { return lister.state.page === 1; },
                        label: 'Previous',
                        method: lister.navigate.backwards,
                    },
                    {
                        disabled: function () { return lister.state.page === lister.state.lastPage; },
                        label: 'Next',
                        method: lister.navigate.forwards,
                    },
                ] })); },
        }, keys: keys }));
});
