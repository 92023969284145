"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var DebtWhatsNext_1 = require("./DebtWhatsNext");
var permissions_1 = require("../../../../../../../app/utils/permissions");
var getState_1 = require("../../../../../../utils/getState");
var DebtsWhatsNextContainer = function (props) {
    return (React.createElement(React.Fragment, null,
        ' ',
        (0, permissions_1.hasPermission)((0, getState_1.default)().user, ['customer']) &&
            props.debt.status !== 'recovered' &&
            props.debt.status !== 'closed' &&
            props.debt.status !== 'litigation_requested' &&
            props.debt.status !== 'in_litigation' &&
            props.debt.status !== 'declined' ? (React.createElement("div", { className: "demand-list" },
            React.createElement("header", null,
                React.createElement("h2", null, "Next steps")),
            React.createElement("div", { className: "next-steps-list" },
                React.createElement(DebtWhatsNext_1.default, { status: props.debt.status, notInProgress: ['unsubmitted', 'reverted'], isInProgress: ['submitted'], header: "In Review" },
                    React.createElement("p", null, "Before generating the initial demand letter, rradarrecover managers review the details submitted to make sure everything is in good order. If any errors or omissions are apparent, the recovery will be reverted to you for corrections. If this happens you will receive a notification by email explaining the issue, and you can then open the recovery on your rradarrecover dashboard, make any necessary corrections, and re-submit.")),
                React.createElement(DebtWhatsNext_1.default, { status: props.debt.status, notInProgress: ['unsubmitted', 'reverted', 'submitted'], isInProgress: ['stage_1'], header: "Stage One" },
                    React.createElement("p", null,
                        "Once your submission is accepted, an initial demand email and letter are sent to the debtor, and they are given time to respond.",
                        ' ',
                        props.debt.jurisdiction === 'england_wales' && props.debt.debtor.protocol_id === 1
                            ? 'Since the debtor is an individual/sole trader, the period allowed is 30 days.'
                            : 'The period allowed is 14 days.'),
                    React.createElement("strong", null, "Communication with the Debtor"),
                    React.createElement("p", null, "The debtor should communicate directly with you. If you receive payment, use the Resolve button to mark the debt as paid."),
                    props.isAllowedExtraTime && (React.createElement(React.Fragment, null,
                        React.createElement("strong", null, "Extra time"),
                        React.createElement("p", null, "Because the debtor is an individual or sole trader, they may contact you to ask for more information, or just for more time. In this case, you are obliged to provide any information requested, and then give them a further 30 days to pay the debt. If they have asked for more information, you must make sure they have received it, and then click the Extend button to extend the response period by 30 days. If the debtor just asked for more time without requesting more information, click the Extend button as soon as you receive the request.")))),
                React.createElement(DebtWhatsNext_1.default, { status: props.debt.status, notInProgress: ['unsubmitted', 'reverted', 'submitted', 'stage_1', 'stage_1_ended', 'stage_2_authorised'], isInProgress: ['stage_2'], header: "Stage Two" },
                    React.createElement("p", null, "Should the first response period come to an end with no communication from the debtor, it will be time to click the Advance button to move the recovery to the next stage."),
                    React.createElement("p", null, "At this time a second and final demand is issued to the debtor, in written and email form, accompanied by a copy of draft court documents. These are the documents that would be used if the claim were pursued in court. The debtor is given another 14 days to respond.")),
                props.debt.status !== 'recovered' && props.debt.status !== 'closed' ? (React.createElement(DebtWhatsNext_1.default, { status: props.debt.status, notInProgress: [
                        'unsubmitted',
                        'reverted',
                        'submitted',
                        'stage_1',
                        'stage_1_ended',
                        'stage_2_authorised',
                        'stage_2',
                        'overdue',
                        'closed',
                        'declined',
                        'recovered',
                    ], isInProgress: ['in_litigation'], header: "Beyond rradarrecover\n" },
                    React.createElement("p", null,
                        "If having followed Stages One and Two you need more support to achieve payment of the debt, make sure to read rradar\u2019s guide to",
                        ' ',
                        React.createElement("a", { href: "/assets/documents/Alternative_Dispute_Resolution.pdf", target: "_blank" }, "Alternative Dispute Resolution (ADR)"),
                        ' ',
                        "and your ",
                        React.createElement("a", { href: "/dashboard/debts/".concat(props.debt.reference, "/action-outlook") }, "Legal Action Outlook"),
                        ' ',
                        "to get a sense of the best way forward from here. Whether you want to consider a method such as mediation or negotiation, or prefer the option of Court proceedings, click the Advance button to arrange for a no-obligation consultation. Please note that the cost of any further guidance, support or advice are not covered as part of the rradarrecover service.",
                        ' ',
                        React.createElement("strong", null, "Please note that if no action is taken on a recovery after Stage Two has ended, the recovery will be automatically closed at the end of six weeks.")))) : null))) : null));
};
exports.default = DebtsWhatsNextContainer;
