"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Yup = require("yup");
exports.default = Yup.object().shape({
    email: Yup.string()
        .trim()
        .email()
        .nullable()
        .max(50, 'Email cannot be more than 50 characters'),
    address_1: Yup.string()
        .trim()
        .max(100, 'Address Line 1 cannot be more than 100 characters')
        .nullable()
        .required('You must provide address line one.'),
    address_2: Yup.string()
        .trim()
        .max(100, 'Address Line 2 cannot be more than 100 characters')
        .nullable(),
    city: Yup.string()
        .trim()
        .max(20, 'City cannot be more than 20 characters')
        .nullable()
        .required(),
    post_code: Yup.string()
        .trim()
        .required('Postcode is a required field')
        .max(8, 'Postcode cannot exceed 8 characters')
        .nullable()
        .matches(/[A-Za-z][A-Ha-hK-Yk-y]?[0-9][A-Za-z0-9]? [0-9][A-Za-z]{2}|[Gg][Ii][Rr] 0[Aa]{2}/, 'Invalid Postcode Format'),
    protocol_id: Yup.number().required('Required'),
    representative_name: Yup.string()
        .trim()
        .when('protocol_id', {
        is: function (protocol_id) { return protocol_id === 2; },
        then: Yup.string().required('Required'),
    })
        .nullable(),
    trading_name: Yup.string()
        .trim()
        .max(100, 'Trading name cannot be more than 100 characters'),
    companies_house_number: Yup.string()
        .trim()
        .max(20, 'Companies house number can not be more than 20 characters'),
    first_name: Yup.string()
        .trim()
        .max(100, 'First name cannot be longer than 20 characters')
        .nullable()
        .test('Name', 'When the debtor is an individual, you must provide their first name.', function (value) {
        var protocol_id = this.parent.protocol_id;
        if (protocol_id === 1) {
            return value != null;
        }
        return true;
    }),
    middle_name: Yup.string()
        .trim()
        .max(100, 'Name cannot be longer than 20 characters')
        .nullable(),
    last_name: Yup.string()
        .trim()
        .max(100, 'Name cannot be longer than 20 characters')
        .nullable()
        .test('Name', 'When the debtor is an individual, you must provide their surname.', function (value) {
        var protocol_id = this.parent.protocol_id;
        if (protocol_id === 1) {
            return value != null;
        }
        return true;
    }),
    company_name: Yup.string()
        .trim()
        .max(100, 'Company name cannot be longer than 100 characters')
        .nullable()
        .test('Name', 'If protocol is business company name must be filled', function (value) {
        var protocol_id = this.parent.protocol_id;
        if (protocol_id === 2) {
            return value != null;
        }
        return true;
    }),
});
