"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var GuidanceTemplates = function () {
    return (React.createElement("div", null,
        React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("th", null, "File"),
                React.createElement("th", null, "Jurisdiction"),
                React.createElement("th", null, "Download")),
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", null, 'Invoice Template'),
                    React.createElement("td", null, "England and Wales/Scotland"),
                    React.createElement("td", null,
                        React.createElement("a", { href: '/assets/documents/Invoice Template.dotx', download: true }, "Download"))),
                React.createElement("tr", null,
                    React.createElement("td", null, 'Delivery Note Template'),
                    React.createElement("td", null, "England and Wales/Scotland"),
                    React.createElement("td", null,
                        React.createElement("a", { href: '/assets/documents/Delivery Note Template.dotx', download: true }, "Download"))),
                React.createElement("tr", null,
                    React.createElement("td", null, 'Order Acknowledgment Form Template'),
                    React.createElement("td", null, "England and Wales/Scotland"),
                    React.createElement("td", null,
                        React.createElement("a", { href: '/assets/documents/Order Acknowledgment Form Template.dotx', download: true }, "Download")))))));
};
exports.default = GuidanceTemplates;
