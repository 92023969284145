"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classnames_1 = require("classnames");
var withRouter_1 = require("../../../../router/hooks/withRouter");
var StageHandler = /** @class */ (function (_super) {
    __extends(StageHandler, _super);
    function StageHandler() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            stages: [
                { path: 'page1', title: 'Terms' },
                { path: 'page2', title: 'Debtor Search' },
                { path: 'page3', title: 'Jurisdiction' },
                { path: 'page4', title: 'Debt Type' },
                { path: 'page5', title: 'Debtor' },
                { path: 'page6', title: 'Debt Details' },
                { path: 'page7', title: 'Invoice Details' },
                { path: 'page8', title: 'Invoice Upload' },
                { path: 'page9', title: 'Confirmation' },
            ],
        };
        _this.renderStageListItems = function () {
            return _this.state.stages.map(function (stage, index) { return (React.createElement("div", { className: (0, classnames_1.default)({
                    steps__step: true,
                    'steps__step--last': index === _this.state.stages.length - 1,
                }), key: stage.path },
                React.createElement("div", { className: "steps__shape", onClick: function () { return _this.props.router.navigate("/dashboard/debtwizard/".concat(stage.path)); } },
                    React.createElement("svg", { viewBox: "0 0 200 200", xmlns: "http://www.w3.org/2000/svg" },
                        React.createElement("circle", { cx: "100", cy: "100", r: "90", stroke: "#cc0000", 
                            //@ts-ignore - No idea why, but router.params.stage is undefined, is a *
                            style: _this.activeStyle(_this.props.router.params['*'] === stage.path), strokeWidth: 22, className: "steps__circle" }))),
                React.createElement("div", { className: "steps__line-container" },
                    index !== _this.state.stages.length - 1 ? (React.createElement("svg", { className: "steps__line", preserveAspectRatio: "none", xmlns: "http://www.w3.org/2000/svg" },
                        React.createElement("line", { x1: "0", y1: "0", y2: "0", x2: index === _this.state.stages.length - 1 ? '50%' : '100%', stroke: '#cc0000', strokeWidth: "4" }))) : null,
                    React.createElement("div", { className: (0, classnames_1.default)({
                            steps__description: true,
                            //@ts-ignore - No idea why, but router.params.stage is undefined, is a *
                            'steps__description--active': _this.props.router.params['*'] === stage.path,
                        }) }, stage.title)))); });
        };
        return _this;
    }
    StageHandler.prototype.activeStyle = function (active) {
        return active ? { fill: '#cc0000' } : {};
    };
    StageHandler.prototype.render = function () {
        return (React.createElement("div", { className: "steps-container" },
            React.createElement("div", { className: "steps" }, this.renderStageListItems())));
    };
    return StageHandler;
}(React.Component));
exports.default = (0, withRouter_1.default)(StageHandler);
