"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var scrollToTop_1 = require("../../../../../utils/scrollToTop");
var JurisdictionPicker_1 = require("../JurisdictionPicker/JurisdictionPicker");
var AddDebtWizardPage4 = function (props) {
    (0, scrollToTop_1.default)();
    return (React.createElement("div", { className: "add-debt-wizard-page flex-column" },
        React.createElement("div", { className: "add-debt-wizard-form-contents flex-column" },
            React.createElement("div", { className: "wizard-form-description" },
                React.createElement("h2", null, "Debt Jurisdiction"),
                React.createElement("p", null, "Choose which legal jurisdiction you will use to enforce your debt claim.")),
            React.createElement("div", { className: "flex-row-wrap" },
                React.createElement("div", { className: "form-panel" },
                    React.createElement(JurisdictionPicker_1.default, { setFieldValue: props.setFieldValue, values: props.values, validator: props.validator })))),
        React.createElement("div", { className: "link_navigation flex-row" },
            React.createElement(react_router_dom_1.Link, { to: "/dashboard/debtwizard/page2", className: "button nav-button-grey" }, "Previous"),
            React.createElement(react_router_dom_1.Link, { to: "/dashboard/debtwizard/page4", className: "button nav-button-grey", id: 'active' }, "Next"))));
};
exports.default = AddDebtWizardPage4;
