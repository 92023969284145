"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasPermission = exports.throwNewPermissionError = void 0;
var throwNewPermissionError = function (res) {
    res.status(500);
    throw new Error("You do not have permission to view this page");
};
exports.throwNewPermissionError = throwNewPermissionError;
var hasPermission = function (user, permissionArray) {
    if (user === null) {
        return false;
    }
    if (user) {
        var resultofpermission = permissionArray.includes(user.role);
        return resultofpermission;
    }
    if ((user !== null && user.role === "admin") ||
        user.role === "customer" ||
        user.role === "legal") {
        return true;
    }
    return false;
};
exports.hasPermission = hasPermission;
