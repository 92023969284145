"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var lodash_1 = require("lodash");
var ActiveAmountsBreakdown_1 = require("../ActiveAmountsBreakdown");
var RadioButton_1 = require("../../../../common/RadioButton");
var TrackOwed = /** @class */ (function (_super) {
    __extends(TrackOwed, _super);
    function TrackOwed() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            selectedPanel: 0,
            dataHasUpdated: false,
        };
        _this.debtValueBreakdown = [];
        _this.endStatusBreakdown = [];
        _this.panelArray = [];
        _this.arrayCounter = 0;
        _this.componentDidUpdate = function () {
            _this.handleDidUpdate();
        };
        _this.handleDidUpdate = function () {
            if (_this.panelArray.length > 1) {
                clearInterval(_this.interval);
                _this.interval = setInterval(function () {
                    if (_this.arrayCounter + 1 === _this.panelArray.length) {
                        _this.setState({ selectedPanel: 0 });
                        _this.arrayCounter = 0;
                    }
                    else {
                        _this.arrayCounter += 1;
                        var panelNumber = _this.panelArray[_this.arrayCounter];
                        _this.setState({ selectedPanel: panelNumber });
                    }
                }, 10000);
            }
            if (!(0, lodash_1.isEmpty)(_this.props.breakdown.counts) && _this.state.dataHasUpdated === false) {
                if (_this.props.breakdown.counts.recovered) {
                    _this.endStatusBreakdown.push({
                        id: 'recovered',
                        label: 'Recovered',
                        value: _this.props.breakdown.counts.recovered,
                    });
                }
                if (_this.props.breakdown.counts.closed) {
                    _this.endStatusBreakdown.push({
                        id: 'closed',
                        label: 'Closed',
                        value: _this.props.breakdown.counts.closed,
                    });
                }
                if (_this.props.breakdown.counts.declined) {
                    _this.endStatusBreakdown.push({
                        id: 'declined',
                        label: 'Declined',
                        value: _this.props.breakdown.counts.declined,
                    });
                }
                _this.setState({ dataHasUpdated: true });
            }
        };
        _this.recoveredSince = function (_a) {
            var total_amount_recovered = _a.total_amount_recovered, join_date = _a.join_date;
            return parseFloat(total_amount_recovered) > 0 ? (React.createElement("p", { className: "track-owed-recovered" }, "You have recovered \u00A3".concat(total_amount_recovered, " since joining recover on").concat(' ', "\n        ").concat(new Date(join_date).toLocaleDateString('en-GB', {
                timeZone: 'UTC',
            })))) : null;
        };
        _this.panels = function () {
            var panel1 = function () {
                if (!_this.panelArray.includes(0)) {
                    _this.panelArray.push(0);
                }
                return (React.createElement("div", { className: "".concat(_this.state.selectedPanel === 0 ? 'Active' : 'Inactive') },
                    React.createElement("span", { className: "limit-sub-title" }, "You are currently recovering: "),
                    React.createElement("h3", null,
                        "\u00A3",
                        _this.props.value),
                    _this.recoveredSince(_this.props)));
            };
            var panel2 = function () {
                if (!_this.panelArray.includes(1)) {
                    _this.panelArray.push(1);
                }
                return (React.createElement("div", { className: "".concat(_this.state.selectedPanel === 1 ? 'Active' : 'Inactive') },
                    React.createElement("span", { className: "limit-sub-title" }, "Active Debt Amounts By Status"),
                    React.createElement("div", null, _this.state.dataHasUpdated === true ? (React.createElement(ActiveAmountsBreakdown_1.default, { amounts: _this.props.breakdown.amounts })) : null)));
            };
            return (React.createElement("div", { className: "track-owed-slider" },
                panel1(),
                _this.props.breakdown.counts.submitted > 0 ||
                    _this.props.breakdown.counts.stage_1 > 0 ||
                    _this.props.breakdown.counts.stage_2_authorised > 0
                    ? panel2()
                    : null));
        };
        _this.render = function () {
            return (React.createElement("div", { className: "track-owed flex-column" },
                _this.props.breakdown !== undefined ? _this.panels() : null,
                _this.panelArray.length > 1 ? (React.createElement("div", { className: "radioSelect" },
                    React.createElement(RadioButton_1.default, { name: 'panel0', checked: _this.state.selectedPanel === 0, value: 0, handleRadioChange: function () {
                            _this.setState({ selectedPanel: 0 });
                            _this.arrayCounter = 0;
                            clearInterval(_this.interval);
                        } }),
                    _this.props.breakdown.counts.submitted > 0 ||
                        _this.props.breakdown.counts.stage_1 > 0 ||
                        _this.props.breakdown.counts.stage_2_authorised > 0 ? (React.createElement(RadioButton_1.default, { name: 'panel1', checked: _this.state.selectedPanel === 1, value: 1, handleRadioChange: function () {
                            _this.setState({ selectedPanel: 1 });
                            _this.arrayCounter = 1;
                            clearInterval(_this.interval);
                        } })) : null)) : null));
        };
        return _this;
    }
    return TrackOwed;
}(React.Component));
exports.default = TrackOwed;
