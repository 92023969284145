"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var EditDebtDebtorName_1 = require("./EditDebtDebtorName/EditDebtDebtorName");
var EditDebtDebtor = function (props) {
    return (React.createElement("div", { className: "flex-column" },
        React.createElement(EditDebtDebtorName_1.default, { values: props.values, setFieldValue: props.setFieldValue, disabled: props.disabled }),
        React.createElement("div", { className: "form-input" },
            React.createElement("label", null, "Debtor Email"),
            React.createElement(formik_1.Field, { type: "text", name: "email", value: props.values.email, disabled: props.disabled })),
        React.createElement(formik_1.ErrorMessage, { name: "email" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
        React.createElement("div", { className: "form-input" },
            React.createElement("label", null, "Trading Name"),
            React.createElement(formik_1.Field, { type: "text", name: "trading_name", value: props.values.trading_name, disabled: props.disabled })),
        React.createElement(formik_1.ErrorMessage, { name: "trading_name" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
        React.createElement("div", { className: "form-input" },
            React.createElement("label", null, "Company Number"),
            React.createElement(formik_1.Field, { type: "text", name: "companies_house_number", value: props.values.companies_house_number, disabled: props.disabled })),
        React.createElement(formik_1.ErrorMessage, { name: "companies_house_number" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
        React.createElement("div", { className: "flex-column form-panel-coloured" },
            React.createElement("div", { className: "form-input" },
                React.createElement("label", null, "Address Line 1"),
                React.createElement(formik_1.Field, { type: "text", name: "address_1", placeholder: "Address Line 1", value: props.values.address_1, disabled: props.disabled })),
            React.createElement("div", { className: "form-input" },
                React.createElement("label", null, "Address Line 2"),
                React.createElement(formik_1.Field, { type: "text", name: "address_2", placeholder: "Address Line 2", value: props.values.address_2, disabled: props.disabled })),
            React.createElement("div", { className: "form-input" },
                React.createElement("label", null, "City"),
                React.createElement(formik_1.Field, { type: "text", name: "city", placeholder: "City", value: props.values.city, disabled: props.disabled })),
            React.createElement("div", { className: "form-input" },
                React.createElement("label", null, "Postcode"),
                React.createElement(formik_1.Field, { type: "text", name: "post_code", placeholder: "Postcode", value: props.values.post_code, disabled: props.disabled })),
            React.createElement(formik_1.ErrorMessage, { name: "address_1" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
            React.createElement(formik_1.ErrorMessage, { name: "address_2" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
            React.createElement(formik_1.ErrorMessage, { name: "city" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
            React.createElement(formik_1.ErrorMessage, { name: "post_code" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }))));
};
exports.default = EditDebtDebtor;
