"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var InformationTip_1 = require("../../../../../../../common/InformationTip");
var LoadingContainer_1 = require("../../../../../../../common/LoadingContainer");
var DebtRevertModalForm_1 = require("./DebtRevertModalForm");
var DebtRevertModalContent = function (props) {
    return (React.createElement("section", null,
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "modal-header flex-row" },
                React.createElement("h3", null, "Revert Debt"),
                React.createElement("div", { className: "flex-row" },
                    React.createElement("div", { className: "isPointer", onClick: function () {
                            props.handleRevertModal();
                        } },
                        React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'times'] }))))),
        React.createElement("div", { className: "container" },
            React.createElement("p", null, "If this debt appears to contain errors that may harm the overall\n          success of the recover process, you can revert this debt back to\n          unsubmitted. This will allow the user to make any relevant changes."),
            React.createElement(InformationTip_1.default, null,
                React.createElement("div", null, "If this debt has elements that may make it impossible to recover or\n            conflicts with rradar's interests, use the decline action instead.")),
            React.createElement(LoadingContainer_1.default, { render: function (config) {
                    return React.createElement(DebtRevertModalForm_1.default, __assign({}, config, props));
                } }))));
};
exports.default = DebtRevertModalContent;
