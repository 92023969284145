"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var EditDebt_1 = require("../../../components/Pages/ViewDebt/EditDebt");
var debtTemplate_1 = require("../../../utils/debtTemplate");
var withRouter_1 = require("../../hooks/withRouter");
// export interface IDebtorNewDebtProps {
//   match: { params: { id: number } };
// }
var DebtorNewDebt = function (props) {
    var debt = (0, debtTemplate_1.default)();
    return React.createElement(EditDebt_1.default, { file: { filename: '' }, debt: debt, debtor_id: props.router.params.id });
};
exports.default = (0, withRouter_1.default)(DebtorNewDebt);
