"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function () {
    var debtTemplate = {
        commercial_recovery_cost: null,
        court_fee: null,
        purchase_order_date: '',
        interest_charged: null,
        interest_rate: null,
        proclaim_matter_id: null,
        debtor: {
            name: '',
            email: '',
            address_1: '',
            address_2: '',
            city: '',
            company_id: null,
            country: '',
            created_at: '',
            id: null,
            post_code: '',
            protocol_id: 1,
            updated_at: '',
        },
        company: {
            name: '',
        },
        created_at: '',
        debtor_id: null,
        demand_template_version: null,
        description: '',
        id: null,
        source_type: '',
        invoice_due_date: '',
        invoice_reference: '',
        invoice_sent_date: '',
        purchase_order_number: '',
        reference: '',
        original_amount: null,
        reminder_sent_date: '',
        status: null,
        updated_at: '',
    };
    return debtTemplate;
});
