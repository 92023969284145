"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var PurchaseOrderNumber_1 = require("./PurchaseOrderNumber");
var PurchaseOrderDate_1 = require("./PurchaseOrderDate");
var PurchaseOrderArea = function (props) {
    return (React.createElement("div", null,
        React.createElement(PurchaseOrderNumber_1.default, { validator: props.validator, value: props.values.purchase_order_number }),
        React.createElement(formik_1.ErrorMessage, { name: "purchase_order_number" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
        React.createElement(PurchaseOrderDate_1.default, { validator: props.validator, setFieldValue: props.setFieldValue, values: props.values })));
};
exports.default = PurchaseOrderArea;
