"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Yup = require("yup");
exports.default = Yup.object().shape({
    bank_name: Yup.string().required("Required"),
    bank_sort_code: Yup.string()
        .length(6)
        .required("Required"),
    bank_account_number: Yup.string()
        .length(8)
        .required("Required"),
    bank_account_holder: Yup.string().required("Required")
});
