"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var InformationTip = function (props) {
    return (React.createElement("div", { className: "banner-container banner-blue" },
        React.createElement("div", { className: "banner-content flex-row" },
            React.createElement("div", { className: "banner-left-icon" },
                React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: ['fal', 'magic'] })),
            React.createElement("div", { className: "banner-text flex-column" }, props.children))));
};
exports.default = InformationTip;
