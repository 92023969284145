"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var HeadedTextareaInput_1 = require("./HeadedTextareaInput");
var HeadedTextareaInputContainer = function (props) {
    return (React.createElement("div", { className: "headed-textarea-input-container flex-row-wrap", style: { marginTop: '2.5em' } },
        React.createElement(HeadedTextareaInput_1.default, { icon: ['fal', 'people-carry'], name: 'goods_description', value: props.values.goods_description, setFieldValue: props.setFieldValue, placeholder: 'Goods' }),
        React.createElement(formik_1.ErrorMessage, { name: "goods_description" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
        React.createElement(HeadedTextareaInput_1.default, { icon: ['fal', 'handshake'], name: 'services_description', value: props.values.services_description, setFieldValue: props.setFieldValue, placeholder: 'Services' }),
        React.createElement(formik_1.ErrorMessage, { name: "services_description" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); })));
};
exports.default = HeadedTextareaInputContainer;
