"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var PurchaseOrderArea_1 = require("../PurchaseOrderInput/PurchaseOrderArea");
var InterestRate_1 = require("../../InterestRate/InterestRate");
var OriginalAmountFields_1 = require("../OriginalAmount/OriginalAmountFields");
var AddDebtWizardPage8Debt = function (props) {
    return (React.createElement("div", { className: "form-panel flex-column" },
        React.createElement("label", { className: "required" }, "Amount"),
        React.createElement(OriginalAmountFields_1.default, { values: props.values, validator: props.validator, setFieldValue: props.setFieldValue, pounds: props.pounds, setPounds: props.setPounds, pence: props.pence, setPence: props.setPence }),
        React.createElement(formik_1.ErrorMessage, { name: "original_amount" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
        React.createElement(PurchaseOrderArea_1.default, { validator: props.validator, values: props.values, setFieldValue: props.setFieldValue }),
        React.createElement(InterestRate_1.default, { value: props.values.interest_rate, setFieldValue: props.setFieldValue, validator: props.validator, protocol: props.values.protocol_id })));
};
exports.default = AddDebtWizardPage8Debt;
