"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getState_1 = require("./getState");
var lodash_1 = require("lodash");
exports.default = (function (noCustomerRedirect) {
    var user = (0, getState_1.default)().user;
    if (!(0, lodash_1.isEmpty)(user)) {
        if (user.role === 'legal') {
            window.location.href = '/admin';
        }
        else if (user.role === 'admin') {
            window.location.href = '/admin/debts';
        }
        else if (!noCustomerRedirect) {
            window.location.href = '/dashboard';
        }
    }
});
