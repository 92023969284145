"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var CommonButtons = function (props) {
    return (React.createElement("div", { className: "Common-Buttons" },
        React.createElement("button", { type: "button", className: "button red-button", disabled: props.disableMethodButtons !== undefined ? props.disableMethodButtons === true : false, onClick: function () {
                props.saveMethod();
            } }, props.saveButtonText === undefined ? 'Save' : props.saveButtonText),
        React.createElement("button", { type: "button", className: "button red-button", disabled: props.disableMethodButtons !== undefined ? props.disableMethodButtons === true : false, onClick: function () {
                props.saveAndExitMethod();
            } }, props.saveAndExitButtonText === undefined ? 'Save and Exit' : props.saveAndExitButtonText),
        React.createElement("button", { type: "button", className: "button red-button", onClick: function () {
                props.cancelMethod();
            } }, props.cancelButtonText === undefined ? 'Cancel' : props.cancelButtonText)));
};
exports.default = CommonButtons;
