"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var GuidanceGuides_1 = require("./GuidanceCategories/GuidanceGuides");
var GuidanceChecklists_1 = require("./GuidanceCategories/GuidanceChecklists");
var GuidanceTemplates_1 = require("./GuidanceCategories/GuidanceTemplates");
var core_1 = require("@rradar/core");
var Guidance = function () {
    var _a = __read(React.useState('guides'), 2), tab = _a[0], setTab = _a[1];
    var renderTabButtons = function () {
        return (React.createElement("div", { className: "tab-buttons flex-row", style: { marginBottom: '1em' } },
            React.createElement("button", { type: "button", className: "tab-button ".concat(tab === 'guides' ? 'active_tab' : 'inactive_tab'), onClick: function () {
                    setTab('guides');
                } }, "Guides"),
            React.createElement("button", { type: "button", className: "tab-button ".concat(tab === 'checklists' ? 'active_tab' : 'inactive_tab'), onClick: function () {
                    setTab('checklists');
                } }, "Checklists"),
            React.createElement("button", { type: "button", className: "tab-button ".concat(tab === 'templates' ? 'active_tab' : 'inactive_tab'), onClick: function () {
                    setTab('templates');
                } }, "Templates")));
    };
    var renderTabContents = function () {
        switch (tab) {
            case 'guides':
                return React.createElement(GuidanceGuides_1.default, null);
            case 'checklists':
                return React.createElement(GuidanceChecklists_1.default, null);
            case 'templates':
                return React.createElement(GuidanceTemplates_1.default, null);
            default:
                return;
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "dashboard-body" },
                React.createElement("div", { className: "dashboard-header flex-column" },
                    React.createElement(core_1.Heading1, { title: "rradarrecover debt recovery toolkit: Guidance on good credit management" }),
                    React.createElement("p", null,
                        "As part of the rradarrecover subscription, you have access to a range of debt recovery information which is written and kept up to date with the current legislation and best practice by our debt recovery team.",
                        ' '),
                    React.createElement("p", null, "These offer insights and share our knowledge on how best to avoid losses in your business and provide guidance on ways of addressing late payments for your goods and services. To view or download and keep, simply click on the documents.")),
                renderTabButtons(),
                renderTabContents()))));
};
exports.default = Guidance;
