"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Yup = require("yup");
exports.default = Yup.object().shape({
    // .required("Required"),
    original_amount: Yup.number()
        .typeError('The amount needs to be a number')
        .min(25, 'Minimum value is £25')
        .max(20000, 'Maximum value is £20,000')
        .positive('Number must be positive')
        .required('Required'),
    invoice_reference: Yup.string()
        .trim()
        .max(20, 'Invoice reference must be 20 characters or less')
        .required('Required'),
    invoice_due_date: Yup.lazy(function (value) {
        switch (typeof value) {
            case 'string':
                return Yup.string();
            default:
                Yup.date().required('Required');
        }
    }),
    invoice_sent_date: Yup.lazy(function (value) {
        switch (typeof value) {
            case 'string':
                return Yup.string();
            default:
                Yup.date().required('Required');
        }
    }),
    reminder_sent_date: Yup.lazy(function (value) {
        switch (typeof value) {
            case 'string':
                return Yup.string();
            default:
                Yup.date().required('Required');
        }
    }),
    protocol_id: Yup.number().required('Required'),
    representative_name: Yup.string()
        .trim()
        .when('protocol_id', {
        is: function (protocol_id) { return protocol_id === 2; },
        then: Yup.string().required('Required'),
    })
        .nullable(),
    interest_rate: Yup.number()
        .moreThan(-1)
        .max(8000, 'The interest rate can be a maximum of 8000%'),
    purchase_order_number: Yup.string()
        .trim()
        .max(20, 'Purchase order number must be 20 characters or less'),
    //This is an example of conditional validation without cyclic dependency errors using yup.
    //No arrow functions, YUP will make formik throw an error.  Not sure if it would be fine outside of formik, 'tis a test for another day.
    goods_description: Yup.string()
        .trim()
        .max(250, 'Good description must be 250 characters or less')
        .test('goods or services', 'If a description of services is not given, then a description of goods provided to the debtor is required', function (value) {
        var services_description = this.parent.services_description;
        if (!services_description) {
            return value != null;
        }
        return true;
    }),
    services_description: Yup.string()
        .trim()
        .max(250, 'Services description must be 250 characters or less')
        .test('goods or services', 'If a description of goods is not given, then a description of services provided to the debtor is required', function (value) {
        var goods_description = this.parent.goods_description;
        if (!goods_description) {
            return value != null;
        }
        return true;
    }),
});
