"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var EditDebtGoodsAndServices = function (props) {
    return (React.createElement("div", { className: "flex-column" },
        React.createElement("div", { className: "form-input-container" },
            React.createElement("div", null,
                React.createElement("div", { className: "form-input-container" },
                    React.createElement("label", { className: "form-input-container-title" }, "Goods Description"),
                    React.createElement(formik_1.Field, { className: "form-input-container-contents", name: "goods_description", component: "textarea", value: props.values.goods_description, placeholder: 'Please describe goods', style: { width: '100%' }, disabled: props.disabled })),
                React.createElement("div", { className: "form-input-container" },
                    React.createElement("label", { className: "form-input-container-title" }, "Services Description"),
                    React.createElement(formik_1.Field, { className: "form-input-container-contents", name: "services_description", component: "textarea", value: props.values.services_description, placeholder: 'Please describe services', style: { width: '100%' }, disabled: props.disabled }))),
            React.createElement(formik_1.ErrorMessage, { name: "services_description" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }))));
};
exports.default = EditDebtGoodsAndServices;
