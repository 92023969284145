"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var formik_1 = require("formik");
var PurchaseOrderArea_1 = require("./PurchaseOrderInput/PurchaseOrderArea");
var InterestRate_1 = require("../InterestRate/InterestRate");
var Hint_1 = require("../../../../common/Hint");
var scrollToTop_1 = require("../../../../../utils/scrollToTop");
var OriginalAmountFields_1 = require("./OriginalAmount/OriginalAmountFields");
var AddDebtWizardPage5 = function (props) {
    (0, scrollToTop_1.default)();
    return (React.createElement("div", { className: "add-debt-wizard-page flex-column" },
        React.createElement("div", { className: "add-debt-wizard-form-contents" },
            React.createElement("div", { className: "wizard-form-description" },
                React.createElement("h2", null, "Debt details"),
                React.createElement("p", null, "Please tell us about the debt")),
            React.createElement("div", { className: "flex-row-wrap" },
                React.createElement("div", { className: "form-panel" },
                    React.createElement("label", { className: 'required' }, "What is the value on this debt's invoice? ",
                        React.createElement(Hint_1.default, { id: '' }, "This is the value you charged for your goods and/or services")),
                    React.createElement(OriginalAmountFields_1.default, { values: props.values, validator: props.validator, setFieldValue: props.setFieldValue, pounds: props.pounds, setPounds: props.setPounds, pence: props.pence, setPence: props.setPence }),
                    React.createElement(formik_1.ErrorMessage, { name: "original_amount" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }),
                    React.createElement(PurchaseOrderArea_1.default, { validator: props.validator, values: props.values, setFieldValue: props.setFieldValue })),
                React.createElement("div", { className: "form-panel form-panel-coloured" },
                    React.createElement("label", null,
                        "What is the interest rate you are charging?",
                        React.createElement(InterestRate_1.default, { value: props.values.interest_rate, setFieldValue: props.setFieldValue, validator: props.validator, protocol: props.values.protocol_id }),
                        React.createElement(formik_1.ErrorMessage, { name: "interest_rate" }, function (msg) { return React.createElement("div", { className: "debt-feedback" }, msg); }))))),
        React.createElement("div", { className: "link_navigation flex-row" },
            React.createElement(react_router_dom_1.Link, { to: "/dashboard/debtwizard/page5", className: "button nav-button-grey" }, "Previous"),
            React.createElement(react_router_dom_1.Link, { to: "/dashboard/debtwizard/page7", className: "button nav-button-grey", id: 'active' }, "Next"))));
};
exports.default = AddDebtWizardPage5;
