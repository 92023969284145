"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Cookie = require("js-cookie");
var react_pdf_1 = require("react-pdf");
var react_confirm_alert_1 = require("react-confirm-alert");
// import { PDFDocumentProxy } from 'pdfjs-dist';
var core_1 = require("@rradar/core");
var getState_1 = require("../../../../utils/getState");
var downloadFile_1 = require("../../../../utils/downloadFile");
var LoadingSpinner_1 = require("../../../common/LoadingSpinner/LoadingSpinner");
var recoverInterface_1 = require("../../../../utils/recoverInterface");
var AdminDashboardPageNumbers_1 = require("./components/AdminDashboardPageNumbers");
react_pdf_1.pdfjs.GlobalWorkerOptions.workerSrc = "//cdnjs.cloudflare.com/ajax/libs/pdf.js/".concat(react_pdf_1.pdfjs.version, "/pdf.worker.js");
var AdminViewDemandsPage = /** @class */ (function (_super) {
    __extends(AdminViewDemandsPage, _super);
    function AdminViewDemandsPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            demand: null,
            url: '',
            isLoading: false,
            numPages: null,
            pageNumber: 1,
            indexArray: [],
            confirmed: false,
            debt: null,
            fileName: '',
        };
        _this.buttonRef = React.createRef();
        _this.closeRef = React.createRef();
        _this.controller = new AbortController();
        _this.signal = _this.controller.signal;
        _this.handleIsLoading = function (isLoad) {
            _this.setState({ isLoading: isLoad });
        };
        // Atempt to get PDF twice.
        _this.getDemand = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.handleIsLoading(true);
                        return [4 /*yield*/, fetch("".concat((0, getState_1.default)().api.endpoint, "/debts/ref/").concat(this.props.reference, "/upcoming_demand_pdf"), {
                                method: 'GET',
                                signal: this.signal,
                                headers: {
                                    Authorization: Cookie.get('sessionId'),
                                },
                            })
                                .then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                                var pdfResponse, headers, filename;
                                var _this = this;
                                return __generator(this, function (_a) {
                                    if (response.ok) {
                                        pdfResponse = response;
                                        headers = pdfResponse.headers.get('Content-Disposition');
                                        filename = headers.match(/\"(.*?)\"/);
                                        this.setState({ fileName: filename[1] });
                                        pdfResponse.blob().then(function (blobResponse) {
                                            var newBlob = new Blob([blobResponse], {
                                                type: 'application.pdf',
                                            });
                                            var url = URL.createObjectURL(newBlob);
                                            _this.setState({ demand: newBlob, url: url });
                                            _this.handleIsLoading(false);
                                        });
                                    }
                                    else {
                                        setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                                            var _this = this;
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, fetch("".concat((0, getState_1.default)().api.endpoint, "/debts/ref/").concat(this.props.reference, "/upcoming_demand_pdf"), {
                                                            method: 'GET',
                                                            signal: this.signal,
                                                            headers: {
                                                                Authorization: Cookie.get('sessionId'),
                                                            },
                                                        })
                                                            .then(function (upcomingResponse) {
                                                            var pdfResponse = upcomingResponse;
                                                            var headers = pdfResponse.headers.get('Content-Disposition');
                                                            var filename = headers.match(/\"(.*?)\"/);
                                                            _this.setState({ fileName: filename[1] });
                                                            pdfResponse.blob().then(function (blobResponse) {
                                                                var newBlob = new Blob([blobResponse], {
                                                                    type: 'application.pdf',
                                                                });
                                                                var url = URL.createObjectURL(newBlob);
                                                                _this.setState({ demand: newBlob, url: url });
                                                                _this.handleIsLoading(false);
                                                            });
                                                        })
                                                            .catch(function (error) {
                                                            (0, core_1.ToastNotification)({ type: 'error', message: error.message });
                                                            _this.handleIsLoading(false);
                                                        })];
                                                    case 1:
                                                        _a.sent();
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); }, 10000);
                                    }
                                    return [2 /*return*/];
                                });
                            }); })
                                .catch(function (error) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    (0, core_1.ToastNotification)({ type: 'error', message: error.message });
                                    this.handleIsLoading(false);
                                    return [2 /*return*/];
                                });
                            }); })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, (0, recoverInterface_1.default)()
                                .debts()
                                .getByRef(this.props.reference)
                                .then(function (response) { return _this.setState({ debt: response.debt }); })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.handleDownload = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.handleIsLoading(true);
                        return [4 /*yield*/, (0, downloadFile_1.default)(this.state.demand, this.state.fileName, '')];
                    case 1:
                        _a.sent();
                        this.handleIsLoading(false);
                        return [2 /*return*/, (0, core_1.ToastNotification)({ type: 'success', message: 'Download Successful' })];
                }
            });
        }); };
        _this.handleConfirmation = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.handleIsLoading(true);
                        return [4 /*yield*/, (0, recoverInterface_1.default)()
                                .debts()
                                .access(this.state.debt.id)
                                .demands()
                                .post(this.props.stage)
                                .catch(function (error) {
                                _this.handleIsLoading(false);
                                (0, core_1.ToastNotification)({ type: 'error', message: error.message });
                            })];
                    case 1:
                        _a.sent();
                        this.handleIsLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.componentDidMount = function () {
            _this.getDemand();
        };
        _this.onDocumentLoadSuccess = function (_a) {
            var numPages = _a.numPages;
            _this.setState({ numPages: numPages });
            _this.loopIndex(numPages);
        };
        _this.setPageNumber = function (page) {
            _this.setState({ pageNumber: page });
        };
        _this.loopIndex = function (numPages) {
            var array = [];
            for (var i = 1; i <= numPages; i++) {
                array.push(React.createElement(AdminDashboardPageNumbers_1.default, { key: i, indexKey: i, setPageNumber: _this.setPageNumber }));
            }
            _this.setState({ indexArray: array });
        };
        _this.render = function () {
            return (React.createElement("div", { className: "admin-view-demands-page" },
                React.createElement("div", { className: "pdf-document-container flex-row" },
                    React.createElement("div", { className: "outliner" },
                        React.createElement("div", { className: "outliner-actions flex-row" },
                            React.createElement("button", { type: "button", className: "button red-button prev-button", onClick: function (e) { return __awaiter(_this, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, this.handleDownload()];
                                            case 1:
                                                _a.sent();
                                                return [2 /*return*/];
                                        }
                                    });
                                }); }, disabled: _this.state.confirmed }, 'Download'),
                            React.createElement("button", { type: "button", className: "button red-button prev-button", onClick: function (e) {
                                    if (!_this.state.confirmed) {
                                        (0, react_confirm_alert_1.confirmAlert)({
                                            title: 'Confirm Demand Process',
                                            message: 'Confirm demand generation and postage.  If you download the file and do not confirm the debt, it will not progress to the next stage.  You can redownload the demand from the debt page',
                                            buttons: [
                                                {
                                                    label: 'Cancel',
                                                    onClick: function () {
                                                        return null;
                                                    },
                                                },
                                                {
                                                    label: 'Confirm',
                                                    onClick: function () {
                                                        _this.handleConfirmation();
                                                        _this.setState({ confirmed: true });
                                                    },
                                                },
                                            ],
                                        });
                                    }
                                }, disabled: _this.state.confirmed }, "Posted"),
                            React.createElement("button", { type: "button", className: "button red-button prev-button", onClick: function (e) { return (window.location.href = "/admin/debts/".concat(_this.props.reference)); } }, "To Debt")),
                        React.createElement("div", { className: "outliner-index flex-column" }, _this.state.indexArray),
                        React.createElement("div", { className: "outliner-page-controls flex-row" },
                            React.createElement("button", { type: "button", className: "button red-button prev-button", onClick: function (e) {
                                    _this.setState(function (prevState) {
                                        return { pageNumber: prevState.pageNumber - 1 };
                                    });
                                }, disabled: _this.state.pageNumber <= 1 }, "Previous Page"),
                            React.createElement("p", { style: { color: 'white' } }, _this.state.pageNumber),
                            React.createElement("button", { type: "button", className: "button red-button next-button", onClick: function (e) {
                                    _this.setState(function (prevState) {
                                        return { pageNumber: prevState.pageNumber + 1 };
                                    });
                                }, disabled: _this.state.pageNumber >= _this.state.numPages }, "Next Page"))),
                    React.createElement(react_pdf_1.Document, { className: "document", file: _this.state.url, onLoadSuccess: _this.onDocumentLoadSuccess, renderMode: 'canvas' },
                        React.createElement(react_pdf_1.Page, { pageNumber: _this.state.pageNumber }))),
                _this.state.isLoading === true ? (React.createElement(LoadingSpinner_1.default, { handleIsLoading: _this.handleIsLoading, signal: _this.signal, controller: _this.controller })) : null));
        };
        return _this;
    }
    return AdminViewDemandsPage;
}(React.Component));
exports.default = AdminViewDemandsPage;
