"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var EditDebtButtonArea = function (props) {
    if (window.location.href.indexOf('edit') !== -1) {
        return (React.createElement("div", { className: "flex-column form-panel form-panel-coloured" },
            props.values.status === 'reverted' || props.values.status === 'unsubmitted' ? (React.createElement("button", { type: "button", className: "red-button button", disabled: !(props.isValid && props.dirty && !props.isSubmitting), onClick: function () {
                    props.submitData(props.values, false);
                } }, "Save Changes")) : null,
            props.values.status === 'reverted' || props.values.status === 'unsubmitted' ? (React.createElement("button", { type: "button", className: "red-button button", onClick: function () {
                    props.submitData(props.values, true);
                }, disabled: !(props.isValid && props.dirty && !props.isSubmitting) }, "Save and Submit Debt")) : null,
            React.createElement("button", { type: "button", className: "cancel-button button", onClick: function () {
                    window.location.href = "/dashboard/debts/".concat(props.reference);
                } }, "Return to debt")));
    }
    else
        return (React.createElement("div", { className: "flex-column form-panel form-panel-coloured" },
            React.createElement("button", { type: "button", className: "red-button button", disabled: !(props.isValid && props.dirty && !props.isSubmitting), onClick: function () {
                    props.submitNewDebt(props.values, false);
                } }, "Save Changes"),
            React.createElement("button", { type: "button", className: "red-button button", onClick: function () {
                    props.submitNewDebt(props.values, true);
                }, disabled: !(props.isValid && props.dirty && !props.isSubmitting) }, "Save and Submit Debt"),
            React.createElement("button", { type: "button", className: "cancel-button button", onClick: function () {
                    window.location.href = "/dashboard/debtors/".concat(props.debtor_id);
                } }, "Return to debtor")));
};
exports.default = EditDebtButtonArea;
