"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var react_datepicker_1 = require("react-datepicker");
var RadioButton_1 = require("../../../../common/RadioButton");
var getState_1 = require("../../../../../utils/getState");
var EditDebtGoodsAndServices_1 = require("./EditDebtGoodsAndServices");
var EditDebtOriginalAmount_1 = require("./EditDebtOriginalAmount/EditDebtOriginalAmount");
var toPrecision6_1 = require("../../../../../utils/toPrecision6");
var EditDebtDetails = function (props) {
    var _a = __read(React.useState('stat'), 2), selected = _a[0], setSelected = _a[1];
    var _b = __read(React.useState(false), 2), handledOriginal = _b[0], setHandledOriginal = _b[1];
    var _c = __read(React.useState('25'), 2), pounds = _c[0], setPounds = _c[1];
    var _d = __read(React.useState('00'), 2), pence = _d[0], setPence = _d[1];
    var handleOriginalAmount = function () {
        var amountPounds = pounds.toString();
        var amountPence = pence.toString();
        props.setFieldValue('original_amount', parseFloat("".concat(amountPounds, ".").concat(amountPence)));
    };
    React.useEffect(function () {
        if (!handledOriginal && props.values.original_amount !== null) {
            var amount = props.values.original_amount.toString().split('.');
            setPounds(amount[0]);
            setPence(amount[1]);
            setHandledOriginal(true);
        }
    }, [props.values.original_amount]);
    React.useEffect(function () {
        if (handledOriginal) {
            handleOriginalAmount();
        }
    }, [pounds, pence]);
    React.useEffect(function () {
        if (props.values.interest_rate !== 0 &&
            props.values.interest_rate !== 8 &&
            props.values.interest_rate !== 8.0 &&
            props.values.interest_rate !== 8 + (0, getState_1.default)().interest_rate_boe) {
            setSelected('custom');
        }
        else if (props.values.interest_rate === 0) {
            setSelected('none');
        }
        else if (props.values.protocol_id === 1) {
            setSelected('stat');
        }
        else
            setSelected('stat+boe');
    }, [props.values.interest_rate]);
    var handleRadioChange = function (value, option) {
        props.setFieldValue('interest_rate', parseFloat(value));
        setSelected(option);
    };
    return (React.createElement("div", { className: "form-panel form-panel-coloured flex-column" },
        React.createElement(EditDebtGoodsAndServices_1.default, { values: props.values, setFieldValue: props.setFieldValue, disabled: props.disabled }),
        React.createElement(EditDebtOriginalAmount_1.default, { values: props.values, setFieldValue: props.setFieldValue, pounds: pounds, setPounds: setPounds, pence: pence, setPence: setPence, disabled: props.disabled }),
        React.createElement(formik_1.ErrorMessage, { name: "original_amount" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
        React.createElement("div", { className: "form-input" },
            React.createElement("label", { htmlFor: "purchase_order_number" }, "Personal Reference"),
            React.createElement(formik_1.Field, { type: "text", name: "purchase_order_number", placeholder: "Reference", disabled: props.disabled })),
        React.createElement(formik_1.ErrorMessage, { name: "purchase_order_number" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
        React.createElement("div", { className: "form-input ".concat(props.values.purchase_order_number !== '' && props.values.purchase_order_number !== null ? '' : 'hidden_field') },
            React.createElement("label", { htmlFor: "purchase_order_date" }, "Purchase Order Date"),
            React.createElement(formik_1.Field, { render: function (_a) {
                    var field = _a.field;
                    return (React.createElement(react_datepicker_1.default, __assign({}, field, { selected: Date.parse(props.values.purchase_order_date), onChange: function (value) {
                            props.setFieldValue('purchase_order_date', value);
                        }, popperPlacement: "top-end", popperModifiers: {
                            offset: {
                                enabled: true,
                                offset: '5px, 10px',
                            },
                            preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: 'viewport',
                            },
                        }, dateFormat: "dd/MM/yyyy", placeholderText: "Click to select a date", todayButton: 'Today', maxDate: new Date() })));
                }, name: "purchase_order_date", disabled: props.disabled })),
        React.createElement(formik_1.ErrorMessage, { name: "purchase_order_date" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }),
        React.createElement("div", { className: "flex-column" },
            React.createElement("div", { className: "form-input-container flex-column" },
                React.createElement("label", { className: "form-input-container-title interest-rate-title", htmlFor: "interest_rate" },
                    ' ',
                    "Interest?",
                    ' '),
                React.createElement("div", { className: "form-input-container-contents radio-container ".concat(props.disabled ? 'protocol-tile-container-disabled' : '') },
                    React.createElement("label", { className: "radio flex-row" },
                        React.createElement(RadioButton_1.default, { name: "none", value: 0, checked: props.values.interest_rate === 0, handleRadioChange: function () { return handleRadioChange(0, 'none'); } }),
                        React.createElement("div", { className: "radio-option div-spacing" }, "None")),
                    props.values.protocol_id === 1 ? (React.createElement("label", { className: "radio flex-row" },
                        React.createElement(RadioButton_1.default, { name: "stat", value: 8.0, checked: props.values.interest_rate === 8.0, handleRadioChange: function () { return handleRadioChange(8.0, 'stat'); } }),
                        React.createElement("div", { className: "radio-option div-spacing" }, "Statutory (8%)"))) : (React.createElement("label", { className: "radio flex-row" },
                        React.createElement(RadioButton_1.default, { name: "stat+boe", value: 8 + (0, getState_1.default)().interest_rate_boe, checked: props.values.interest_rate === 8 + (0, getState_1.default)().interest_rate_boe, handleRadioChange: function () { return handleRadioChange(8 + (0, getState_1.default)().interest_rate_boe, 'stat+boe'); } }),
                        React.createElement("div", { className: "radio-option div-spacing" },
                            "Statutory + BOE (",
                            (8 + (0, getState_1.default)().interest_rate_boe).toString(),
                            ")%"))),
                    React.createElement("label", { className: "radio flex-row" },
                        React.createElement(RadioButton_1.default, { name: "custom", value: 0.1, checked: selected === 'custom', handleRadioChange: function () { return handleRadioChange(0.1, 'custom'); } }),
                        React.createElement("div", { className: "radio-option div-spacing" }, "Contractual ")))),
            React.createElement("div", { className: "form-input form-input--with-tag ".concat(selected !== 'custom' ? 'hidden_field' : '') },
                React.createElement("label", null, "Custom interest"),
                React.createElement("input", { style: { padding: '0 0 0 2rem' }, type: "number", name: "interest_rate", id: "other_interest_rate", onChange: function (e) { return props.setFieldValue('interest_rate', parseFloat((0, toPrecision6_1.default)(e.target.value))); }, value: props.values.interest_rate, disabled: props.disabled }),
                React.createElement("div", { className: "form-input-tag" }, "%")),
            React.createElement(formik_1.ErrorMessage, { name: "interest_rate" }, function (msg) { return React.createElement("div", { className: "error-feedback" }, msg); }))));
};
exports.default = EditDebtDetails;
