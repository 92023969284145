"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_trix_1 = require("react-trix");
require("trix/dist/trix");
var CompanyObservations = function (props) {
    var _a = __read(React.useState(false), 2), hasSetObservations = _a[0], setHasSetObservations = _a[1];
    var _b = __read(React.useState(''), 2), observations = _b[0], setObservations = _b[1];
    var handleChange = function (html) {
        setObservations(html);
    };
    React.useEffect(function () {
        setObservations(props.observations);
        setHasSetObservations(true);
    }, [props.observations]);
    return (React.createElement("div", { className: "site-body" },
        React.createElement("div", { className: "container", style: { padding: '4em' } },
            observations !== '' || hasSetObservations ? (React.createElement(react_trix_1.TrixEditor, { onChange: handleChange, value: observations, mergeTags: null })) : null,
            React.createElement("button", { type: "button", className: "red-button button", onClick: function () { return props.submitObservation({ observations: observations }); }, disabled: props.observations === observations ||
                    (observations === '' && props.observations === '') ||
                    props.observations === null }, "Submit"))));
};
exports.default = CompanyObservations;
